.pending-transport-padding {
    padding: 15px !important;
}

.filter-new-entry {
    color: #444cf7 !important;
    border: 1px solid #444cf7 !important;
    font-weight: bold !important;
    position: relative;
}

.button-disabled {
    background: #ccc !important;
    color: #666 !important;
    border: 1px solid #ccc !important;
  }

tr.table-row-cancelled {
    color: red !important;
}

.pagination-box {
    padding: 20px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 99999;
    background-color: #eaebf0 !important;
}

.right-align button.export-button {
    margin-right: 10px;
}

.pending-bill-table {
    padding: 15px !important;
}

.search-button-area {
    text-align: right;

    button {
        margin: 0 5px 0;
    }
}

.submit-button {
    font-weight: bold !important;
    border: 1px solid #444CF7 !important;
    background-color: #444CF7 !important;
}

.ant-table-content>table {
    border-color: #ccc !important;
}

/**
 * Breadcrumb icon css
 */

ul.breadcrumb_buttons {
    list-style-type: none;
}

.breadcrumb_buttons li {
    list-style-type: none;
    float: left;
    margin-right: 5px;
}

.breadcrumb_buttons button {
    position: relative;
    text-transform: capitalize !important;
    background-color: #444cf7 !important;
    color: #ffffff !important;
    min-width: 130px;
    font-size: 16px !important;
}

.breadcrumb_buttons button.button_active {
    color: #fff;
    background-color: #444cf7 !important;
}

.breadcrumb_buttons button.button_inactive {
    color: #a4a4a4 !important;
    background-color: #EAEBF0 !important;
}

.breadcrumb_buttons button img {
    margin-right: 10px;
}

.breadcrumb_buttons button::after {
    content: "";
    position: absolute;
    border-left: 19px solid #444cf7;
    border-bottom: 18px solid transparent;
    border-top: 19px solid transparent;
    height: 0px;
    width: 0px;
    margin-right: -17px;
    right: 0;
    z-index: 2;
}

.breadcrumb_buttons button.button_inactive::after {
    border-left: 19px solid #ECEDFE;
}

.breadcrumb_buttons button.step_button::before {
    content: "";
    position: absolute;
    border-left: 19px solid #fff;
    border-bottom: 18px solid transparent;
    border-top: 19px solid transparent;
    height: 0px;
    width: 0px;
    left: 0;
}

.breadcrumb_buttons button.generate_bills_icon {
    width: 150px;
}

.breadcrumb_buttons button.final_calculation_bills_icon {
    width: 200px;
}

.h6 {
    font-size: 30px !important;
    margin-bottom: 5px !important;
}

.para {
    font-size: 16px !important;
}

/**
 * Breadcrumb icon css ends here
 */

// .breadcrumb li:nth-child(3) a:focus {
//   background: #444CF7 !important;
// }

// .breadcrumb li:nth-child(3) a:focus:after {
//   border-left-color: #444CF7 !important;
// }

.transport-agency-bill-number-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 0 10px;
}

.transport-agency-wrappper {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
}

.select-new-entry {
    width: 300px;
}

.transport-agency-bill-number {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;

    .hr-transport-box {
        padding: 8px 15px;
        background-color: #d2d4fd;
        border-radius: 5px;
    }
}

.middle-boundary-transport {
    background-color: #479036;
    display: flex;
    justify-content: space-between;
    margin: 0 10px !important;
    padding: 0px 15px;
    border-radius: 5px;

    .text-boundary-tranport {
        letter-spacing: 1px;
    }

    .total-amount-transport {
        display: flex;
        justify-content: flex-start;
        gap: 1rem;
    }
}

.bottom-transport-counter {
    display: flex;
    justify-content: space-between;
    margin: 0 10px;

    h6 {
        text-align: center;
        font-size: 30px !important;
    }

    .bottom-transport-first-counter {
        width: 210px;
        height: 130px;
        padding: 22px 0px;
        background-color: #42536c;
        border-radius: 5px;
        text-align: center;
    }

    .bottom-transport-second-counter {
        width: 210px;
        height: 130px;
        padding: 22px 0px;
        background-color: #28bbc3;
        border-radius: 5px;
        text-align: center;
        text-align-last: center;
    }

    .bottom-transport-third-counter {
        width: 210px;
        height: 130px;
        padding: 22px 0px;
        background-color: #43377c;
        border-radius: 5px;
        text-align: center;
        text-align-last: center;
    }

    .bottom-transport-fourth-counter {
        width: 210px;
        height: 130px;
        padding: 22px 0px;
        background-color: #8578e3;
        border-radius: 5px;
        text-align: center;
        text-align-last: center;
    }

    .bottom-transport-fivth-counter {
        width: 210px;
        height: 130px;
        padding: 22px 0px;
        background-color: #e7655f;
        border-radius: 5px;
        text-align: center;
        text-align-last: center;
    }

    .bottom-transport-sixth-counter {
        width: 210px;
        height: 130px;
        padding: 22px 0px;
        background-color: #574c8f;
        border-radius: 5px;
        text-align: center;
        text-align-last: center;
    }

    .bottom-transport-seventh-counter {
        width: 210px;
        height: 130px;
        padding: 22px 0px;
        background-color: #2c7bff;
        border-radius: 5px;
        text-align: center;
        text-align-last: center;
    }
}

.submit-button-area {
    margin: 15px 0;
    text-align: center;

    .submit-outline-button {
        color: #444cf7;
        border-color: #444cf7;
        font-size: 16px !important;
        height: 36px !important;
        font-weight: bold !important;
    }

    .submit-button {
        background-color: #444cf7;
        font-size: 16px !important;
        height: 36px !important;
        font-weight: bold !important;
    }

    button {
        margin: 0 5px 0;
    }
}

@media (max-width: 575px) {
    .breadcrumb_buttons button {
        margin-bottom: 10px !important
    }

    .bottom-transport {

        &-first-counter,
        &-second-counter,
        &-third-counter,
        &-fourth-counter,
        &-fivth-counter,
        &-sixth-counter,
        &-seventh-counter {
            width: 250px !important;
            padding: 22px 10px !important;
        }
    }

    .bottom-transport {

        &-third-counter,
        &-fourth-counter,
        &-fivth-counter,
        &-sixth-counter,
        &-seventh-counter {
            margin-top: 20px !important;
        }
    }

    .transport-agency-bill-number-wrapper {
        .transport-agency-wrappper {
            gap: 1rem !important;
        }

        .select-new-entry {
            width: 200px !important;
        }

        .transport-agency-bill-number {
            margin-top: 10px !important;
        }
    }

    .middle-boundary-transport {
        width: 200px !important;
    }
}

@media (min-width: 576px) and (max-width: 650px) {
    .bottom-transport {

        &-first-counter,
        &-second-counter,
        &-third-counter,
        &-fourth-counter,
        &-fivth-counter,
        &-sixth-counter,
        &-seventh-counter {
            width: 300px !important;
            padding: 22px 10px !important;
        }
    }

    .bottom-transport {

        &-third-counter,
        &-fourth-counter,
        &-fivth-counter,
        &-sixth-counter,
        &-seventh-counter {
            margin-top: 20px !important;
        }
    }

    .transport-agency-bill-number-wrapper {
        .transport-agency-wrappper {
            gap: 1rem !important;
        }

        .select-new-entry {
            width: 200px !important;
        }

        .transport-agency-bill-number {
            margin-top: 10px !important;
        }
    }
}

@media (min-width: 650px) and (max-width: 699px) {
    .bottom-transport {

        &-first-counter,
        &-second-counter,
        &-third-counter,
        &-fourth-counter,
        &-fivth-counter,
        &-sixth-counter,
        &-seventh-counter {
            width: 180px !important;
            padding: 22px 10px !important;
        }
    }

    .bottom-transport {

        &-third-counter,
        &-fourth-counter,
        &-fivth-counter,
        &-sixth-counter,
        &-seventh-counter {
            margin-top: 20px !important;
        }
    }

    .transport-agency-bill-number-wrapper {
        .transport-agency-wrappper {
            gap: 1rem !important;
        }

        .select-new-entry {
            width: 200px !important;
        }

        .transport-agency-bill-number {
            margin-top: 10px !important;
        }
    }
}

@media (min-width: 700px) and (max-width: 750px) {
    .bottom-transport {

        &-first-counter,
        &-second-counter,
        &-third-counter,
        &-fourth-counter,
        &-fivth-counter,
        &-sixth-counter,
        &-seventh-counter {
            width: 210px !important;
            padding: 22px 10px !important;
        }
    }

    .bottom-transport {

        &-fourth-counter,
        &-fivth-counter,
        &-sixth-counter,
        &-seventh-counter {
            margin-top: 20px !important;
        }
    }

    .transport-agency-bill-number-wrapper {
        .transport-agency-wrappper {
            gap: .5rem !important;
        }

        .select-new-entry {
            width: 100px !important;
        }

        .transport-agency-bill-number {
            margin-top: 10px !important;
        }
    }
}

@media (min-width: 750px) and (max-width: 799px) {
    .bottom-transport {

        &-first-counter,
        &-second-counter,
        &-third-counter,
        &-fourth-counter,
        &-fivth-counter,
        &-sixth-counter,
        &-seventh-counter {
            width: 230px !important;
            padding: 22px 10px !important;
        }
    }

    .bottom-transport {

        &-fourth-counter,
        &-fivth-counter,
        &-sixth-counter,
        &-seventh-counter {
            margin-top: 20px !important;
        }
    }

    .transport-agency-bill-number-wrapper {
        .transport-agency-wrappper {
            gap: 1rem !important;
        }

        .select-new-entry {
            width: 130px !important;
        }

        .transport-agency-bill-number {
            margin-top: 10px !important;
        }
    }
}

@media (min-width: 800px) and (max-width: 850px) {
    .bottom-transport {

        &-first-counter,
        &-third-counter,
        &-fourth-counter,
        &-fivth-counter,
        &-sixth-counter,
        &-seventh-counter {
            width: 70px !important;
            height: 125px !important;
            padding: 22px 5px !important;
        }
    }

    .bottom-transport-second-counter {
        width: 70px !important;
        height: 125px !important;
        padding: 22px 0px !important;
    }

    .transport-agency-bill-number-wrapper {
        .select-new-entry {
            width: 150px !important;
        }

        .transport-agency-bill-number {
            margin-top: 10px !important;
        }
    }
}

@media (min-width: 850px) and (max-width: 900px) {
    .bottom-transport {

        &-first-counter,
        &-third-counter,
        &-fourth-counter,
        &-fivth-counter,
        &-sixth-counter,
        &-seventh-counter {
            width: 75px !important;
            height: 125px !important;
            padding: 22px 5px !important;
        }
    }

    .bottom-transport-second-counter {
        width: 75px !important;
        height: 125px !important;
        padding: 22px 0px !important;
    }

    .transport-agency-bill-number-wrapper {
        .select-new-entry {
            width: 180px !important;
        }

        .transport-agency-bill-number {
            margin-top: 10px !important;
        }
    }
}

@media (min-width: 900px) and (max-width: 950px) {
    .filter-new-entry {
        left: -25px !important;
    }

    .bottom-transport {

        &-first-counter,
        &-second-counter,
        &-third-counter,
        &-fourth-counter,
        &-fivth-counter,
        &-sixth-counter,
        &-seventh-counter {
            width: 85px !important;
            height: 125px !important;
            padding: 22px 10px !important;
        }
    }

    .transport-agency-bill-number-wrapper {

        .transport-agency-wrappper {
            gap: .5rem !important;
        }

        .select-new-entry {
            width: 95px !important;
        }
    }
}

@media (min-width: 950px) and (max-width: 1000px) {
    .filter-new-entry {
        left: -15px !important;
    }

    .bottom-transport {

        &-first-counter,
        &-second-counter,
        &-third-counter,
        &-fourth-counter,
        &-fivth-counter,
        &-sixth-counter,
        &-seventh-counter {
            width: 90px !important;
            padding: 22px 10px !important;
        }
    }

    .transport-agency-bill-number-wrapper {

        .transport-agency-wrappper {
            gap: 1rem !important;
        }

        .select-new-entry {
            width: 120px !important;
        }
    }
}

@media (min-width: 1000px) and (max-width: 1050px) {
    .filter-new-entry {
        left: -10px !important;
    }

    .bottom-transport {

        &-first-counter,
        &-second-counter,
        &-third-counter,
        &-fourth-counter,
        &-fivth-counter,
        &-sixth-counter,
        &-seventh-counter {
            width: 95px !important;
            padding: 22px 10px !important;
        }
    }

    .transport-agency-bill-number-wrapper {
        .select-new-entry {
            width: 140px !important;
        }
    }
}

@media (min-width: 1050px) and (max-width: 1100px) {
    .filter-new-entry {
        left: 0px !important;
    }

    .bottom-transport {

        &-first-counter,
        &-second-counter,
        &-third-counter,
        &-fourth-counter,
        &-fivth-counter,
        &-sixth-counter,
        &-seventh-counter {
            width: 105px !important;
            padding: 22px 10px !important;
        }
    }

    .transport-agency-bill-number-wrapper {
        .select-new-entry {
            width: 170px !important;
        }
    }
}

@media (min-width: 1100px) and (max-width: 1150px) {

    .bottom-transport {

        &-first-counter,
        &-second-counter,
        &-third-counter,
        &-fourth-counter,
        &-fivth-counter,
        &-sixth-counter,
        &-seventh-counter {
            width: 110px !important;
            padding: 22px 10px !important;
        }
    }

    .transport-agency-bill-number-wrapper {
        .select-new-entry {
            width: 210px !important;
        }
    }
}

@media (min-width: 1150px) and (max-width: 1200px) {
    .bottom-transport {

        &-first-counter,
        &-second-counter,
        &-third-counter,
        &-fourth-counter,
        &-fivth-counter,
        &-sixth-counter,
        &-seventh-counter {
            width: 115px !important;
            padding: 22px 10px !important;
        }
    }

    .transport-agency-bill-number-wrapper {
        .select-new-entry {
            width: 250px !important;
        }
    }
}

@media (min-width: 1200px) and (max-width: 1250px) {
    .bottom-transport {

        &-first-counter,
        &-second-counter,
        &-third-counter,
        &-fourth-counter,
        &-fivth-counter,
        &-sixth-counter,
        &-seventh-counter {
            width: 125px !important;
            padding: 22px 10px !important;
        }
    }
}

@media (min-width: 1250px) and (max-width: 1300px) {
    .bottom-transport {

        &-first-counter,
        &-second-counter,
        &-third-counter,
        &-fourth-counter,
        &-fivth-counter,
        &-sixth-counter,
        &-seventh-counter {
            width: 130px !important;
            padding: 22px 10px !important;
        }
    }
}

@media (min-width: 1300px) and (max-width: 1350px) {
    .bottom-transport {

        &-first-counter,
        &-second-counter,
        &-third-counter,
        &-fourth-counter,
        &-fivth-counter,
        &-sixth-counter,
        &-seventh-counter {
            width: 135px !important;
            padding: 22px 10px !important;
        }
    }
}

@media (min-width: 1350px) and (max-width: 1400px) {
    .bottom-transport {

        &-first-counter,
        &-second-counter,
        &-third-counter,
        &-fourth-counter,
        &-fivth-counter,
        &-sixth-counter,
        &-seventh-counter {
            width: 140px !important;
        }
    }

    .bottom-transport {

        &-second-counter,
        &-third-counter,
        &-seventh-counter {
            padding: 22px 12px !important;
        }
    }
}

@media (min-width: 1400px) and (max-width: 1450px) {
    .bottom-transport {

        &-first-counter,
        &-second-counter,
        &-third-counter,
        &-fourth-counter,
        &-fivth-counter,
        &-sixth-counter,
        &-seventh-counter {
            width: 145px !important;
        }
    }

    .bottom-transport {

        &-second-counter,
        &-third-counter {
            padding: 22px 12px !important;
        }
    }
}

@media (min-width: 1450px) and (max-width: 1500px) {
    .bottom-transport {

        &-first-counter,
        &-second-counter,
        &-third-counter,
        &-fourth-counter,
        &-fivth-counter,
        &-sixth-counter,
        &-seventh-counter {
            width: 155px !important;
        }
    }

    .bottom-transport {

        &-second-counter,
        &-third-counter {
            padding: 22px 11px !important;
        }
    }
}

@media (min-width: 1500px) and (max-width: 1550px) {
    .bottom-transport {

        &-first-counter,
        &-second-counter,
        &-third-counter,
        &-fourth-counter,
        &-fivth-counter,
        &-sixth-counter,
        &-seventh-counter {
            width: 160px !important;
        }
    }

    .bottom-transport {

        &-second-counter,
        &-third-counter {
            padding: 22px 14px !important;
        }
    }
}

@media (min-width: 1550px) and (max-width: 1599px) {

    .bottom-transport {

        &-first-counter,
        &-second-counter,
        &-third-counter,
        &-fourth-counter,
        &-fivth-counter,
        &-sixth-counter,
        &-seventh-counter {
            width: 170px !important;
        }
    }

    .bottom-transport {

        &-second-counter,
        &-third-counter {
            padding: 22px 15px !important;
        }
    }
}

@media (min-width: 1600px) and (max-width: 1750px) {
    .bottom-transport {

        &-first-counter,
        &-second-counter,
        &-third-counter,
        &-fourth-counter,
        &-fivth-counter,
        &-sixth-counter,
        &-seventh-counter {
            width: 175px !important;
        }
    }
}

@media (min-width: 1650px) and (max-width: 1700px) {
    .bottom-transport {

        &-first-counter,
        &-second-counter,
        &-third-counter,
        &-fourth-counter,
        &-fivth-counter,
        &-sixth-counter,
        &-seventh-counter {
            width: 180px !important;
        }
    }
}

@media (min-width: 1700px) and (max-width: 1750px) {
    .bottom-transport {

        &-first-counter,
        &-second-counter,
        &-third-counter,
        &-fourth-counter,
        &-fivth-counter,
        &-sixth-counter,
        &-seventh-counter {
            width: 185px !important;
        }
    }
}

@media (min-width: 1750px) and (max-width: 1800px) {

    .bottom-transport {

        &-first-counter,
        &-second-counter,
        &-third-counter,
        &-fourth-counter,
        &-fivth-counter,
        &-sixth-counter,
        &-seventh-counter {
            width: 195px !important;
        }
    }
}