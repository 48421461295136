.grid-space {
  display: flex;
  gap: 1.5rem;
  padding: 20px 30px;
}

.submit-button {
  color: #fff;
  text-decoration: none;
  font-weight: bold !important;
  font-size: 16px !important;
  height: 36px !important;
  background-color: #444CF7 !important;
}

.input-outline {
  border-color: blue;
}

.forgot-btn {
  border: none;
  background-color: #fff;
  font-size: 0.8rem;
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 5px;

  &:hover {
    background-color: rgba(128, 128, 128, 0.264);
    text-decoration: underline;
  }
}

.form-input-box {
  width: 100%;
  color: black;
  border-radius: 5px !important;
  padding: 5px 10px;
  margin-bottom: 5px;
  height: 40px;
}

.submit-button-area {
  margin-top: 15px;
  text-align: center;
}

@media (max-width: 576px) {
  .wrapper-section-two {
    height: 47vh;
  }

  .industry-img {
    height: 100%;
    object-fit: cover;
  }

  .typo-heading {
    font-size: 1.3rem !important;
  }

  .typo-para {
    font-size: .85rem !important;
  }
}