.payment-details {
  padding: 20px !important;
}

.summary-table-spin-element {
  padding-top: 10%;
  padding-left: 50%;
  padding-bottom: 10%;
}

.filter-new-entry {
  color: #444cf7 !important;
  font-weight: bold !important;
  border: 1px solid #444cf7 !important;
}

.button-disabled {
  background: #ccc !important;
  color: #666 !important;
  border: 1px solid #ccc !important;
}

.flex-first-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.5rem;

  .select-new-entry {
    width: 300px !important;
  }
}

.submit-button {
    color: #fff;
    text-decoration: none;
    font-weight: bold !important;
    font-size: 16px !important;
    height: 36px !important;
    background-color: #444CF7 !important;
}

.search-button-area {
  text-align: right;

  .add-new-filter-entry {
    background-color: #444cf4 !important;
  }

  button {
    margin: 0 5px 0;
  }
}

.submit-button-area {
    margin-top: 15px;
    text-align: center;

    button {
        margin: 0 5px 0;
    }
}

.error-import-modal-heading {
  font-size: 26px;
  text-transform: capitalize;
  padding-bottom: 10px;
  text-align: center;
}

.error-import-modal {
  height: auto;
  max-height: 70%;
}

.error-import-excel-display-data {
  height: auto;
  max-height: 500px;
  width: 100% !important;
  overflow-y: scroll;
}

.error-import-excel-display-data>table {
  border: 1px solid #ccc;
}

.error-import-excel-display-data>table th {
  background-color: #444CF7;
  color: #fff;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
}

.error-import-excel-display-data>table td {
  border: 1px solid #ccc;
  color: red;
  font-weight: bold;
}

.error-import-excel-display-data>table td table td {
  border: 1px solid transparent;
}

.error-import-excel-display-data ul {
  list-style-type: none;
  margin: 0 25px 0;
}

.error-import-excel-display-data ul li {
  list-style-type: square;
  line-height: 30px;
  color: red;
}

.margintop20 {
  margin-top: -7px;

  .new-entry-filter {
    border-color: #444cf4 !important;
    color: #444cf4 !important;
  }
}

.wrapper-padding {
  padding: 20px 150px;
  text-align: center;

  .total-items {
    padding: 20px;
    border-radius: 5px !important;
    color: snow !important;
    text-align: right !important;
  }

  .header-grid-flex {
    display: flex;
    justify-content: space-between;
  }

  .header-text-fontSize {
    font-size: 2.5rem;
  }

  .total-item-first {
    background-color: #3949ab;
  }

  .total-item-second {
    background-color: #e98a03;
  }

  .entry-details {
    padding: 0 5px 15px 12px;
    background-color: #fafafa;
    border-radius: 5px;
    border: 1px solid #dcdbdb;
    transition: all 0.2s ease-in;
    cursor: pointer;

    &:hover {
      box-shadow: 1px 3px 3px #adadad;
    }
  }

  .bill-number-icons {
    background-color: #fafafa;
    border: 1px solid #adadad;
    padding: 19px;
    border-radius: 5px;
    transition: all 0.2s ease-in;

    &:hover {
      box-shadow: 1px 3px 3px #adadad;
    }
  }
}

.pagination-position {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px !important;
}

.export-submit-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: 30px 0;

  .export-button {
    border-color: #444cf7 !important;
    color: #444cf7 !important;
  }

  .submit-button {
    background-color: #444cf7 !important;
  }
}

@media (max-width: 576px) {
  .total-items {
    width: 350px !important;
  }
}


@media (max-width: 900px) {
  .wrapper-padding {
    padding: 20px !important;
  }
}