.allbill-padding {
  padding: 20px !important;
}

.flex-wrapper-colors-first {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .bill-amount-bg-color,
  .bill-amount-bg-color-opacity {
    width: 50%;
    padding: 5px 10px;
    border-bottom: 1px solid #ffffff;
  }

  .bill-amount-bg-color {
    background-color: #eed3d9;
  }

  .bill-amount-bg-color-opacity {
    text-align: right;
    background-color: #f7e8eb;
  }
}

.flex-wrapper-colors-second {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .bill-amount-bg-color,
  .bill-amount-bg-color-opacity {
    width: 50%;
    padding: 5px 10px;
    border-bottom: 1px solid #ffffff;
  }

  .bill-amount-bg-color {
    background-color: #fffdcb;
  }

  .bill-amount-bg-color-opacity {
    text-align: right;
    background-color: #fffeed;
  }
}

.flex-wrapper-colors-third {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .bill-amount-bg-color,
  .bill-amount-bg-color-opacity {
    width: 50%;
    padding: 5px 10px;
    border-bottom: 1px solid #ffffff;
  }

  .bill-amount-bg-color {
    background-color: #c7dca7;
  }

  .bill-amount-bg-color-opacity {
    text-align: right;
    background-color: rgba(199, 220, 167, 0.3);
  }
}

.flex-wrapper-colors-fourth {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .bill-amount-bg-color,
  .bill-amount-bg-color-opacity {
    width: 50%;
    padding: 5px 10px;
    border-bottom: 1px solid #ffffff;
  }

  .bill-amount-bg-color {
    background-color: #e5e1da;
  }

  .bill-amount-bg-color-opacity {
    text-align: right;
    background-color: #f6f2e9;
  }
}

.flex-wrapper-colors-fivth {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .bill-amount-bg-color,
  .bill-amount-bg-color-opacity {
    width: 50%;
    padding: 5px 10px;
    border-bottom: 1px solid #ffffff;
  }

  .bill-amount-bg-color {
    background-color: #ffd9ad;
  }

  .bill-amount-bg-color-opacity {
    text-align: right;
    background-color: #ffe2c2;
  }
}

.flex-wrapper-colors-sixth {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .bill-amount-bg-color,
  .bill-amount-bg-color-opacity {
    width: 50%;
    padding: 5px 10px;
    border-bottom: 1px solid #ffffff;
  }

  .bill-amount-bg-color {
    background-color: #e0fbe2;
  }

  .bill-amount-bg-color-opacity {
    text-align: right;
    background-color: #f1fff2;
  }
}
