.edit-petrol-pump-padding {
  padding: 15px !important;
}

.grid-space {
  margin-bottom: 20px !important;
}

.remarked-space {
  margin: 20px 0;

  .remark-input {
    border: none !important;
  }
}

.submit-button-area {
  margin-top: 15px;
  text-align: center;

  button {
    margin: 0 5px 0;
  }
}

.marginTop20 {
  margin-top: 20px !important;
}

.action-button {
  justify-content: end;
}

.marginTop10 {
  margin-top: 15px !important;
}

.agency-details-margin {
  padding: 0 15px !important;
}

.form-input-box {
  width: 100%;
  color: black;
  border-radius: 5px !important;
  padding: 5px 10px;
  margin-bottom: 5px;
  height: 40px;
}

.form-select-box {
  color: black;
  border-radius: 5px !important;
  height: 40px;
}

.add-new-entry {
  color: #fff;
  text-decoration: none;
  width: 100%;
  font-weight: bold !important;
  font-size: 16px !important;
  height: 36px !important;
  background-color: #444CF7 !important;
}

.submit-button {
  color: #fff;
  text-decoration: none;
  font-weight: bold !important;
  font-size: 16px !important;
  height: 36px !important;
  background-color: #444CF7 !important;
}


/**
 * CSS for validation error in popup
 */
 .agency-errors-table {
  width: 100%;
  height: auto;
  max-height: 70%;
}

.error-agency-excel-display-data {
  height: auto;
  max-height: 500px;
  width: 100% !important;
  overflow-y: scroll;
}

.agency-errors-table th {
  padding: 10px;
  border: 1px solid #ccc;
  font-size: 16px;
  background-color: #444CF7;
  color: #fff;
}

.agency-errors-table td {
  padding: 10px;
  border: 1px solid #ccc;
  font-size: 15px;
  color: #ff4d4f;
  font-weight: bold;
}

.agency-errors-table td.agency-errors-table-first-td {
  text-align: center;
}

.matching-agencies-div-element .matching-agencies-area-heading {
  background-color: #444CF7 !important;
  padding: 10px;
  color: #fff;
}

.error-agency-modal {
  height: auto;
  max-height: 70%;
}

.error-agency-modal-header {
  text-align: center !important;
  font-size: 20px;
  padding: 0px 0 20px 0;
  text-transform: capitalize;
}

/**
 * CSS for validation error in popup ends
 */


@media (max-width: 576px) {
  .card-box {
    width: 300px;
  }

  .add-new-entry {
    margin-top: 10px !important;
  }
}

@media (min-width: 577px) and (max-width: 699px) {
  .card-box {
    width: 100%;
  }

  .add-new-entry {
    margin-top: 10px !important;
  }
}

@media (min-width: 700px) and (max-width: 899px) {
  .card-box {
    width: 100%;
  }

  .add-new-entry {
    margin-top: 10px !important;
  }
}
@media (min-width: 900px) and (max-width: 950px) {
  .card-box {
    width: 300px;
    height: 160px;
  }
}

@media (min-width: 951px) and (max-width: 1000px) {
  .card-box {
    width: 330px;
    height: 160px;
    margin-right: 10px;
  }
}

@media (min-width: 1001px) and (max-width: 1050px) {
  .card-box {
    width: 350px;
    height: 160px;
    margin-right: 10px;
  }
}

@media (min-width: 1051px) and (max-width: 1100px) {
  .card-box {
    width: 370px;
    height: 160px;
    margin-right: 10px;
  }
}

@media (min-width: 1200px) and (max-width: 1299px) {
  .card-box {
    width: 290px;
    height: 160px;
  }

  .add-new-entry {
    margin-left: -30px !important;
    font-size: 14px !important;
  }
}

@media (min-width: 1300px) and (max-width: 1349px) {
  .card-box {
    width: 330px;
    height: 160px;
  }

  .add-new-entry {
    margin-left: -30px !important;
    font-size: 14px !important;
  }
}

@media (min-width: 1350px) and (max-width: 1399px) {
  .card-box {
    width: 340px;
    height: 160px;
  }

  .add-new-entry {
    margin-left: -30px !important;
  }
}

@media (min-width: 1400px) and (max-width: 1449px) {
  .card-box {
    width: 350px;
  }
}

@media (min-width: 1450px) and (max-width: 1499px) {
  .card-box {
    width: 370px;
  }
}

@media (min-width: 1500px) and (max-width: 1549px) {
  .card-box {
    width: 390px;
  }
}

@media (min-width: 1550px) and (max-width: 1599px) {
  .card-box {
    width: 400px;
  }
}

@media (min-width: 1600px) and (max-width: 1649px) {
  .card-box {
    width: 430px;
  }
}

@media (min-width: 1650px) and (max-width: 1700px) {
  .card-box {
    width: 440px;
  }
}

@media (min-width: 1701px) and (max-width: 1749px) {
  .card-box {
    width: 455px;
  }
}

@media (min-width: 1750px) and (max-width: 1799px) {
  .card-box {
    width: 470px;
  }
}

@media (min-width: 1800px) and (max-width: 1849px) {
  .card-box {
    width: 490px;
  }
}

@media (min-width: 1850px) and (max-width: 1899px) {
  .card-box {
    width: 500px;
  }
}

@media (min-width: 1900px) {
  .card-box {
    width: 520px !important;
  }
}