.generated-bill-client {
  padding: 15px !important;
}

.filter-new-entry {
  color: #444cf7 !important;
  border-color: #444cf7 !important;
  width: max-content;
}


.pagination-box {
  padding: 50px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 99999;
}

.box-label {
  font-size: 18px !important;
}

.search-button-area {
  text-align: right;

  button {
    margin: 0 5px 0;
  }
}

.submit-button {
  background-color: #444cf7 !important;
  font-weight: bold;
}

.total-items {
  width: 400px !important;
  padding: 20px;
  border-radius: 5px !important;
  color: snow !important;
  margin: 10px 0 !important;
  text-align: right !important;
}

.header-grid-flex {
  display: flex;
  justify-content: space-between;
}

.header-text-fontSize {
  font-size: 2.5rem;
}

.total-item-first {
  background-color: #303a81;
}

.total-item-second {
  background-color: #e7576e;
}

.total-item-third {
  background-color: #630661;
}

.total-item-fourth {
  background-color: #485196;
}

.bill-number {
  padding: 15px 0px !important;
}

.bill-number-detail-background {
  background-color: #fafafa;
  border: 1px solid #adadad;
  padding: 15px 0px !important;
  border-radius: 5px;
  transition: all 0.2s ease-in;
  margin-bottom: 20px !important;

  .view-list-flex-link {
    display: contents !important;

    a {
      text-decoration: none;
      color: #555555 !important;
    }
  }

  &:hover {
    box-shadow: 1px 3px 3px #adadad;
  }
}

.bill-number-icons {
  background-color: #fafafa;
  border: 1px solid #adadad;
  padding: 19px;
  border-radius: 5px;
  transition: all 0.2s ease-in;

  &:hover {
    box-shadow: 1px 3px 3px #adadad;
  }
}

@media (max-width: 576px) {
  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 320px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 320px !important;
    }

    &.total-item-third {
      left: 640px !important;
    }

    &.total-item-fourth {
      left: 960px !important;
    }
  }
}

@media (min-width: 577px) and (max-width: 650px) {
  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 320px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 310px !important;
    }

    &.total-item-third {
      left: 620px !important;
    }

    &.total-item-fourth {
      left: 930px !important;
    }
  }
}

@media (min-width: 650px) and (max-width: 700px) {
  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 320px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 300px !important;
    }

    &.total-item-third {
      left: 600px !important;
    }

    &.total-item-fourth {
      left: 900px !important;
    }
  }
}

@media (min-width: 700px) and (max-width: 750px) {
  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 320px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 300px !important;
    }

    &.total-item-third {
      left: 600px !important;
    }

    &.total-item-fourth {
      left: 900px !important;
    }
  }
}

@media (min-width: 750px) and (max-width: 800px) {
  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 320px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 295px !important;
    }

    &.total-item-third {
      left: 590px !important;
    }

    &.total-item-fourth {
      left: 885px !important;
    }
  }
}

@media (min-width: 800px) and (max-width: 850px) {
  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 320px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 290px !important;
    }

    &.total-item-third {
      left: 580px !important;
    }

    &.total-item-fourth {
      left: 870px !important;
    }
  }
}

@media (min-width: 850px) and (max-width: 900px) {
  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 320px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 290px !important;
    }

    &.total-item-third {
      left: 580px !important;
    }

    &.total-item-fourth {
      left: 870px !important;
    }
  }
}

@media (min-width: 900px) and (max-width: 950px) {
  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 320px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 230px !important;
    }

    &.total-item-third {
      left: 460px !important;
    }

    &.total-item-fourth {
      left: 690px !important;
    }
  }
}

@media (min-width: 950px) and (max-width: 1000px) {
  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 320px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 220px !important;
    }

    &.total-item-third {
      left: 440px !important;
    }

    &.total-item-fourth {
      left: 660px !important;
    }
  }
}

@media (min-width: 1000px) and (max-width: 1050px) {
  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 320px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 215px !important;
    }

    &.total-item-third {
      left: 430px !important;
    }

    &.total-item-fourth {
      left: 645px !important;
    }
  }
}

@media (min-width: 1050px) and (max-width: 1100px) {
  .gridmarginBottom20 {
    margin-top: 0 !important;
  }

  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 320px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 210px !important;
    }

    &.total-item-third {
      left: 420px !important;
    }

    &.total-item-fourth {
      left: 630px !important;
    }
  }
}

@media (min-width: 1100px) and (max-width: 1150px) {
  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 320px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 200px !important;
    }

    &.total-item-third {
      left: 400px !important;
    }

    &.total-item-fourth {
      left: 600px !important;
    }
  }
}

@media (min-width: 1150px) and (max-width: 1200px) {
  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 320px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 190px !important;
    }

    &.total-item-third {
      left: 380px !important;
    }

    &.total-item-fourth {
      left: 570px !important;
    }
  }
}

.generated-spin {
  margin: 0 auto;
}

.no-generated-bill-margin-auto {
  margin: 0 auto !important;
}

@media (min-width: 1200px) and (max-width: 1250px) {
  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 320px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 100px !important;
    }

    &.total-item-third {
      left: 200px !important;
    }

    &.total-item-fourth {
      left: 300px !important;
    }
  }
}

@media (min-width: 1250px) and (max-width: 1300px) {
  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 320px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 90px !important;
    }

    &.total-item-third {
      left: 180px !important;
    }

    &.total-item-fourth {
      left: 270px !important;
    }
  }
}

@media (min-width: 1300px) and (max-width: 1350px) {
  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 320px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 80px !important;
    }

    &.total-item-third {
      left: 160px !important;
    }

    &.total-item-fourth {
      left: 240px !important;
    }
  }
}

@media (min-width: 1350px) and (max-width: 1400px) {
  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 320px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 70px !important;
    }

    &.total-item-third {
      left: 140px !important;
    }

    &.total-item-fourth {
      left: 210px !important;
    }
  }
}

@media (min-width: 1400px) and (max-width: 1450px) {

  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 320px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 50px !important;
    }

    &.total-item-third {
      left: 100px !important;
    }

    &.total-item-fourth {
      left: 150px !important;
    }
  }
}

@media (min-width: 1450px) and (max-width: 1500px) {
  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 320px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 40px !important;
    }

    &.total-item-third {
      left: 80px !important;
    }

    &.total-item-fourth {
      left: 120px !important;
    }
  }
}

@media (min-width: 1500px) and (max-width: 1550px) {
  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 330px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 40px !important;
    }

    &.total-item-third {
      left: 80px !important;
    }

    &.total-item-fourth {
      left: 120px !important;
    }
  }
}

@media (min-width: 1550px) and (max-width: 1600px) {
  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 330px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 25px !important;
    }

    &.total-item-third {
      left: 50px !important;
    }

    &.total-item-fourth {
      left: 75px !important;
    }
  }
}

@media (min-width: 1600px) and (max-width: 1650px) {
  .total-items {
    width: 320px !important;
  }
}

@media (min-width: 1650px) and (max-width: 1700px) {
  .total-items {
    width: 330px !important;
  }
}

@media (min-width: 1700px) and (max-width: 1750px) {
  .total-items {
    width: 345px !important;
  }
}

@media (min-width: 1750px) and (max-width: 1800px) {
  .total-items {
    width: 355px !important;
  }
}

@media (min-width: 1800px) and (max-width: 1850px) {
  .total-items {
    width: 370px !important;
  }
}

@media (min-width: 1850px) and (max-width: 1900px) {
  .total-items {
    width: 380px !important;
  }
}

@media (min-width: 1900px) and (max-width: 1950px) {}

@media (min-width: 1950px) and (max-width: 2000px) {}