.generate-petrol-pump-padding {
  padding: 20px !important;
}

.back-button-position {
  text-align: right;

  .back-new-entry {
    background-color: #444cf4 !important;
  }
}

.middle-boundary-transport {
  background-color: #746446;
  display: flex;
  justify-content: space-between;
  margin: 0 10px !important;
  padding: 0px 15px;
  border-radius: 5px;

  .text-boundary-tranport {
    letter-spacing: 1px;
  }

  .total-amount-transport {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
  }
}

.petrol-pump-counter {
  padding: 25px 2px;
  text-align: center;
  border-radius: 5px;
  height: 120px;

  h5 {
    font-size: 1.8rem;
  }

  &.petrol-pump-first-counter {
    background-color: #e39506;
  }

  &.petrol-pump-second-counter {
    background-color: #f28705;
  }

  &.petrol-pump-third-counter {
    background-color: #bda523;
  }

  &.petrol-pump-fourth-counter {
    background-color: #7b6823;
  }

  &.petrol-pump-fivth-counter {
    background-color: #526126;
  }

  &.petrol-pump-sixth-counter {
    background-color: #2d5731;
  }

  &.petrol-pump-seventh-counter {
    background-color: #17514a;
  }
}

.pagination-box {
  padding: 20px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #eaebf0 !important;
}

.export-submit-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;

  .submit-button {
    background-color: #444cf7 !important;
  }
}

@media (max-width: 600px) {
  .back-button-position {
    text-align: left !important;
  }
}
