.create-petrol-pump-padding {
  padding: 15px !important;
}

.grid-space {
  margin-bottom: 20px !important;
}

.button-outline {
  border: none;
  color: rgb(35, 35, 231);
  text-transform: uppercase;
  transition: all 0.1s ease-in;

  &:hover {
    background-color: rgba(21, 21, 235, 0.04);
  }
}

.submit-button {
  color: #fff;
  text-decoration: none;
  font-weight: bold !important;
  font-size: 16px !important;
  height: 36px !important;
  background-color: #444CF7 !important;
}

.ant-card-body {
  padding: 24px !important;
}

.action-button {
  justify-content: end;
}

.agency-details-margin {
  padding: 0 15px !important;
}

.submit-button-area {
  margin-top: 15px;
  text-align: center;

  button {
    margin: 0 5px 0;
  }
}


/**
 * CSS for validation error in popup
 */
.agency-errors-table {
  width: 100%;
  height: auto;
  max-height: 70%;
}

.error-agency-excel-display-data {
  height: auto;
  max-height: 500px;
  width: 100% !important;
  overflow-y: scroll;
}

.agency-errors-table th {
  padding: 10px;
  border: 1px solid #ccc;
  font-size: 16px;
  background-color: #444CF7;
  color: #fff;
}

.agency-errors-table td {
  padding: 10px;
  border: 1px solid #ccc;
  font-size: 15px;
  color: #ff4d4f;
  font-weight: bold;
}

.agency-errors-table td.agency-errors-table-first-td {
  text-align: center;
}

.matching-agencies-div-element .matching-agencies-area-heading {
  background-color: #444CF7 !important;
  padding: 10px;
  color: #fff;
}

.error-agency-modal {
  height: auto;
  max-height: 70%;
}

.error-agency-modal-header {
  text-align: center !important;
  font-size: 20px;
  padding: 0px 0 20px 0;
  text-transform: capitalize;
}

/**
 * CSS for validation error in popup ends
 */

.matching-agencies-div-element h3 {
  font-size: 15px;
  text-align: center;
}

.form-input-box {
  width: 100%;
  color: black;
  border-radius: 5px !important;
  padding: 5px 10px;
  margin-bottom: 5px;
  height: 40px;
}

.form-select-box {
  color: black;
  border-radius: 5px !important;
  height: 40px;
}

.add-new-entry {
  color: #fff;
  text-decoration: none;
  width: max-content;
  font-weight: bold !important;
  font-size: 16px !important;
  height: 36px !important;
  background-color: #444CF7 !important;
}

.marginTop20 {
  margin-top: 20px !important;
}

.marginTop10 {
  margin-top: 15px !important;
}

.remarked-space {
  margin: 20px 0;

  .remark-input {
    border: none !important;
  }
}

@media (min-width: 1400px) and (max-width: 1600px) {
  .add-new-entry {
    margin-left: -50px !important;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .add-new-entry {
    margin-left: -50px !important;
  }
}

@media (min-width: 900px) and (max-width: 1200px) {
  .add-new-entry {
    margin-left: -50px !important;
  }
}