.create-excavator-padding {
    padding: 15px !important;
}

.marginTop20 {
    padding: 0 15px !important;
}

.remark-input {
    border: none !important;
}

.action-button {
    justify-content: end;
}

.add-new-entry {
    color: #fff;
    text-decoration: none;
    width: 100%;
    font-weight: bold !important;
    font-size: 16px !important;
    height: 36px !important;
    background-color: #444CF7 !important;
}

.submit-button {
    color: #fff;
    text-decoration: none;
    font-weight: bold !important;
    font-size: 16px !important;
    height: 36px !important;
    background-color: #444CF7 !important;
}

.form-input-box {
    width: 100%;
    color: black;
    border-radius: 5px !important;
    padding: 5px 10px;
    margin-bottom: 5px;
    height: 40px;
}

.form-select-box {
    color: black;
    border-radius: 5px !important;
    height: 40px;
}

.submit-button-area {
    margin-top: 15px;
    text-align: center;

    button {
        margin: 0 5px 0;
    }
}

.disabled-input {
    color: #666 !important;
}

/* responsive css starts from here */
@media (max-width: 576px) {
    .card-box {
        width: 100% !important;
    }

    .add-new-entry {
        margin-top: 10px !important;
    }
}

@media (min-width: 900px) and (max-width: 1199px) {
    .card-box {
        width: 280px !important;
    }

    .add-new-entry {
        margin-top: 10px !important;
    }
}

@media (min-width: 1200px) and (max-width: 1450px) {
    .card-box {
        width: 280px !important;
    }

    .add-new-entry {
        margin-top: 10px !important;
    }
}

@media (min-width: 1451px) and (max-width: 1500px) {
    .card-box {
        width: 370px !important;
    }

    .add-new-entry {
        margin-top: 10px !important;
    }
}

@media (min-width: 1501px) and (max-width: 1576px) {
    .card-box {
        width: 400px !important;
    }

    .add-new-entry {
        margin-top: 10px !important;
    }
}

@media (min-width: 1577px) and (max-width: 1799px) {
    .card-box {
        width: 430px !important;
    }

    .add-new-entry {
        margin-top: 10px !important;
    }
}

@media (min-width: 1800px) {
    .card-box {
        width: 450px !important;
    }

    .add-new-entry {
        margin-top: 10px !important;
    }
}