.final-calculation-petrol-pump-padding {
  padding: 20px !important;
}

.back-button-position {
  text-align: right;

  .back-new-entry {
    background-color: #444cf4 !important;
  }
}

.data-track-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;

  &.data-first-track {
    background-color: #6b665b;
  }

  &.data-second-track {
    margin-top: 20px;
    background-color: #f7f7f7;
    border: 1px solid #adadad;
  }

  &.data-third-track {
    background-color: #f7f7f7;
    border: 1px solid #adadad;
  }
}

.form-track-section {
  border: 1px solid #adadad;
  border-radius: 5px;

  .form-prefix-padding {
    padding: 20px;
  }

  .input-field {
    background-color: #f7f7f7;
  }

  .total-amount-wrapper {
    background-color: #272b8d;
    padding: 20px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    .rupee_symbol_payable_amount {
      font-weight: lighter;
    }
  }
}

.export-submit-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;

  .submit-button {
    background-color: #444cf7 !important;
  }
}

@media (max-width: 600px) {
  .back-button-position {
    text-align: left !important;
  }
}
