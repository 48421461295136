.upload-file-button {
  background-color: #444CF7 !important;
}

.header-fixed-vehicle {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #fff;
  padding: 0 20px;
  border-bottom: 1px solid #f1f1f1;
}

.add-new-entry {
  color: #fff;
  text-decoration: none;
  width: max-content;
  font-weight: bold !important;
  font-size: 16px !important;
  height: 36px !important;
  background-color: #444cf7 !important;
}

.add-new-filter-entry {
  font-weight: bold !important;
  background-color: #444CF7 !important;
}

.action-button {
  justify-content: end;
  margin-top: 10px !important;
}

.filter-new-entry {
  color: #444cf7 !important;
  border: 1px solid #444cf7 !important;
  font-weight: bold !important;
}

.ant-upload,
.ant-upload-list-item-container {
  width: 100% !important;
}

.search-button-area {
  text-align: right;

  button {
    margin: 0 5px 0;
  }
}

.import-new-entry {
  color: #444CF7 !important;
  border: 1px solid #444CF7 !important;
  font-weight: bold !important;
}

.upload-file-entry {
  color: #444CF7 !important;
  border: 1px solid #444CF7 !important;
  width: max-content !important;
  margin-bottom: 30px !important;
}

.click-here-link {
  color: #444CF7 !important;
}

.model-buttons {
  margin: 0 10px !important;
}


.card-box {
  width: 400px;
  box-shadow: 0 0px 6px 2px rgba(124, 103, 103, 0.34);
  margin: 0 0 30px 0;

  .card-content {
    display: flex;
  }

  .AgencyDetails {
    width: 100%;
    padding: 5px;
  }

  .AgencyDetailsValue {
    margin-left: 5px;
    font-weight: bold;
  }

  .card-button {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    ul.action-items {
      list-style-type: none;

      li {
        list-style-type: none;
        margin: 0;
        padding: 0;
        height: 37px;
      }
    }

    .card-icons {
      border: 1px solid rgba(140, 136, 136, 0.456);
      width: 30px;
      padding: 7px;
      border-radius: 5px;
    }

    .card-icons-images {
      border: 1px solid rgba(140, 136, 136, 0.456);
      width: 30px !important;
      padding: 4px;
      border-radius: 5px;
    }
  }
}

.marginBottom20 {
  margin-bottom: 20px;
}

.marginTop20 {
  padding: 20px !important;
  background-color: #efa3697d !important;
}

.pagination-box {
  padding: 50px 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.no-data-available {
  padding: 20px 20px;
  font-size: 18px;
}

/** excel import errors modal css */
.error-import-modal-heading {
  font-size: 26px;
  text-transform: capitalize;
  padding-bottom: 10px;
  text-align: center;
}

.error-import-modal {
  height: auto;
  max-height: 70%;
}

.error-import-excel-display-data {
  height: auto;
  max-height: 500px;
  width: 100% !important;
  overflow-y: scroll;
}

.error-import-excel-display-data>table {
  border: 1px solid #ccc;
}

.error-import-excel-display-data>table th {
  background-color: #444CF7;
  color: #fff;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
}

.error-import-excel-display-data>table td {
  border: 1px solid #ccc;
  color: red;
  font-weight: bold;
}


.error-import-excel-display-data>table td table td {
  border: 1px solid transparent;
}

.error-import-excel-display-data ul {
  list-style-type: none;
  margin: 0 25px 0;
}

.error-import-excel-display-data ul li {
  list-style-type: square;
  line-height: 30px;
  color: red;
}

.total-items {
  width: 400px !important;
  padding: 20px;
  border-radius: 5px !important;
  color: snow !important;
  margin: 10px 0 !important;
  text-align: right !important;
}

.header-grid-flex {
  display: flex;
  justify-content: space-between;
}

.header-text-fontSize {
  font-size: 2.5rem;
}

.total-item-first {
  background-color: #455785;
}

.select-new-entry {
  width: 200px !important;
}

.form-select-div {
  position: relative;
  left: -150px !important;
}

.content {
  padding: 50px;
}

.verified-vehicle-ribbon-green {
  display: flex;
  gap: 0.3rem;
  align-items: center;
  padding: 5px;
  background-color: darkgreen;
  border-radius: 5px;

  p {
    color: snow;
  }

  .verify-icon {
    width: 20px;
  }
}

.verified-vehicle-ribbon-light-green {
  display: flex;
  gap: 0.3rem;
  align-items: center;
  padding: 5px;
  background-color: lightgreen;
  border-radius: 5px;

  p {
    color: #000;
  }

  .verify-icon {
    width: 20px;
  }
}

.verified-vehicle-ribbon-yellow {
  display: flex;
  gap: 0.3rem;
  align-items: center;
  padding: 5px;
  background-color: #e5c300;
  border-radius: 5px;

  .verify-icon {
    width: 20px;
  }
}

@media (max-width: 275px) {
  .model-buttons {
    margin: 10px 0 !important;
  }
}

@media (min-width: 275px) and (max-width: 290px) {
  .model-buttons {
    margin: 0px 5px !important;
  }
}

@media (max-width: 576px) {
  .header-fixed-vehicle {
    width: 100vw !important;
    margin-top: 10px !important;
  }

  .marginTop20 {
    width: 100vw !important;
  }
  .card-box {
    width: 300px;
  }

  .form-select-div {
    margin-left: 150px !important;
  }

  .select-new-entry {
    width: 150px !important;
  }

  .total-items {
    width: 220px !important;
  }

  .add-new-entry {
    margin-left: 45px !important;
 }

 .filter-new-entry {
   margin-left: 20px !important;
 }
}

@media (min-width: 577px) and (max-width: 699px) {
  .header-fixed-vehicle {
    margin-top: 10px !important;
  }

  .marginTop20 {
    width: 100vw !important;
  }

  .card-box {
    width: 100%;
  }

  .form-select-div {
    margin-left: 150px !important;
  }

  .select-new-entry {
    width: 150px !important;
  }

  .add-new-entry {
     margin-left: 45px !important;
  }

  .filter-new-entry {
    margin-left: 20px !important;
  }

  .total-items {
    width: 230px !important;
  }
}

@media (min-width: 700px) and (max-width: 750px) {
  .header-fixed-vehicle {
    margin-top: 10px !important;
  }
  .card-box {
    width: 100%;
  }

  .form-select-div {
    margin: 0 !important;
    margin-left: 150px !important;
  }

  .add-new-entry {
     margin-left: -15px !important;
  }

  .filter-new-entry {
    margin-left: -10px !important;
  }

  .total-items {
    width: 230px !important;
  }
}

@media (min-width: 750px) and (max-width: 800px) {
  .header-fixed-vehicle {
    margin-top: 10px !important;
  }
  .card-box {
    width: 100%;
  }

  .form-select-div {
    margin: 0 !important;
    margin-left: 150px !important;
  }

  .add-new-entry {
     margin-left: -55px !important;
  }

  .filter-new-entry {
    margin-left: -30px !important;
  }

  .total-items {
    width: 230px !important;
  }
}

@media (min-width: 800px) and (max-width: 850px) {
  .header-fixed-vehicle {
    margin-top: 10px !important;
  }
  .card-box {
    width: 100%;
  }

  .form-select-div {
    margin: 0 !important;
    margin-left: 150px !important;
  }

  .add-new-entry {
     margin-left: -95px !important;
  }

  .filter-new-entry {
    margin-left: -50px !important;
  }

  .total-items {
    width: 230px !important;
  }
}

@media (min-width: 850px) and (max-width: 900px) {
  .header-fixed-vehicle {
    margin-top: 10px !important;
  }
  .card-box {
    width: 100%;
  }

  .form-select-div {
    margin: 0 !important;
    margin-left: 150px !important;
  }

  .add-new-entry {
     margin-left: -115px !important;
  }

  .filter-new-entry {
    margin-left: -60px !important;
  }

  .total-items {
    width: 230px !important;
  }
}

@media (min-width: 900px) and (max-width: 950px) {
  .card-box {
    width: 300px;
    height: 165px;
  }

  .filter-new-entry {
    margin-left: -15px !important;
  }

  .import-new-entry {
    margin-left: -30px !important;
  }

  .form-select-div {
    left: -130px !important;
  }

  .select-new-entry {
    width: 130px !important;
  }

  .total-items {
    width: 225px !important;
  }
}

@media (min-width: 951px) and (max-width: 1000px) {
  .card-box {
    width: 330px;
    height: 165px;
  }

  .total-items {
    width: 235px !important;
  }
}

@media (min-width: 1001px) and (max-width: 1050px) {
  .card-box {
    width: 350px;
    height: 165px;
    margin-right: 10px;
  }

  .form-select-div {
    left: -140px !important;
  }

  .total-items {
    width: 240px !important;
  }
}

@media (min-width: 1051px) and (max-width: 1100px) {
  .card-box {
    width: 370px;
    height: 165px;
    margin-right: 10px;
  }

  .form-select-div {
    left: -140px !important;
  }

  .total-items {
    width: 250px !important;
  }
}

@media (min-width: 1100px) and (max-width: 1200px) {
  
  .total-items {
    width: 250px !important;
  }
}

@media (min-width: 1200px) and (max-width: 1299px) {
  .card-box {
    width: 290px;
    height: 165px;
  }

  .add-new-entry {
    margin-left: -30px !important;
  }

  .filter-new-entry {
    margin-left: -60px !important;
  }

  .import-new-entry {
    margin-left: -90px !important;
  }

  .form-select-div {
    left: -265px !important;
  }

  .total-items {
    width: 220px !important;
  }
}

@media (min-width: 1300px) and (max-width: 1349px) {
  .card-box {
    width: 330px;
    height: 165px;
  }

  .add-new-entry {
    margin-left: -30px !important;
  }

  .filter-new-entry {
    margin-left: -60px !important;
  }

  .import-new-entry {
    margin-left: -90px !important;
  }

  .form-select-div {
    left: -265px !important;
  }

  .total-items {
    width: 240px !important;
  }
}

@media (min-width: 1350px) and (max-width: 1399px) {
  .card-box {
    width: 340px;
    height: 165px;
  }

  .add-new-entry {
    margin-left: -30px !important;
  }

  .filter-new-entry {
    margin-left: -55px !important;
  }

  .import-new-entry {
    margin-left: -75px !important;
  }

  .form-select-div {
    left: -240px !important;
  }

  .total-items {
    width: 255px !important;
  }

}

@media (min-width: 1400px) and (max-width: 1449px) {
  .card-box {
    width: 350px;
  }

  .add-new-entry {
    margin-left: -20px !important;
  }

  .filter-new-entry {
    margin-left: -35px !important;
  }

  .import-new-entry {
    margin-left: -50px !important;
  }

  .form-select-div {
    left: -210px !important;
  }

  .total-items {
    width: 265px !important;
  }
}

@media (min-width: 1450px) and (max-width: 1499px) {
  .card-box {
    width: 370px;
  }

  .add-new-entry {
    margin-left: -20px !important;
  }

  .filter-new-entry {
    margin-left: -30px !important;
  }

  .import-new-entry {
    margin-left: -40px !important;
  }

  .form-select-div {
    left: -200px !important;
  }

  .total-items {
    width: 280px !important;
  }
}

@media (min-width: 1500px) and (max-width: 1549px) {
  .card-box {
    width: 390px;
  }

  .total-items {
    width: 290px !important;
  }
}

@media (min-width: 1550px) and (max-width: 1599px) {
  .card-box {
    width: 400px;
  }

  .total-items {
    width: 300px !important;
  }
}

@media (min-width: 1600px) and (max-width: 1649px) {
  .card-box {
    width: 430px;
  }

  .total-items {
    width: 320px !important;
  }
}

@media (min-width: 1650px) and (max-width: 1700px) {
  .card-box {
    width: 440px;
  }

  .total-items {
    width: 330px !important;
  }
}

@media (min-width: 1701px) and (max-width: 1749px) {
  .card-box {
    width: 455px;
  }

  .total-items {
    width: 380px !important;
  }

  .total-items {
    width: 345px !important;
  }
}

@media (min-width: 1750px) and (max-width: 1799px) {
  .card-box {
    width: 470px;
  }

  .total-items {
    width: 355px !important;
  }
}

@media (min-width: 1800px) and (max-width: 1849px) {
  .card-box {
    width: 490px;
  }

  .total-items {
    width: 370px !important;
  }
}

@media (min-width: 1850px) and (max-width: 1899px) {
  .card-box {
    width: 500px;
  }

  .total-items {
    width: 380px !important;
  }
}

@media (min-width: 1900px) {
  .card-box {
    width: 520px !important;
  }
}