.create-agency-padding {
  padding: 15px !important;
}

.header-fixed-agency {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #fff;
  padding: 0 20px;
  border-bottom: 1px solid #f1f1f1;
}

.error-button {
  background-color: red !important;
}

.tab-content-heading { 
  padding: 15px 0 15px 0;
}

.matching-agencies-div-element {
  position: absolute;
  background-color: #fff;
  min-height: max-content;
  z-index: 1;
  border: 1px solid #ccc;
  margin: -15px 0 0 0;
  border-radius: 5px;
  width: 100%;
  max-width: 536px;
}

.error-agency-modal {
  height: auto;
  max-height: 70%;
}

.error-agency-modal-header {
  text-align: center !important;
  font-size: 20px;
  padding: 0px 0 20px 0;
  text-transform: capitalize;
}

/**
 * CSS for validation error in popup
 */
.agency-errors-table {
  width: 100%;
  height: auto;
  max-height: 70%;
}

.error-agency-excel-display-data {
  height: auto;
  max-height: 500px;
  width: 100% !important;
  overflow-y: scroll;
}

.agency-errors-table th {
  padding: 10px;
  border: 1px solid #ccc;
  font-size: 16px;
  background-color: #444CF7;
  color: #fff;
}

.agency-errors-table td {
  padding: 10px;
  border: 1px solid #ccc;
  font-size: 15px;
  color: #ff4d4f;
  font-weight: bold;
}

.agency-errors-table td.agency-errors-table-first-td {
  text-align: center;
}

.matching-agencies-div-element .matching-agencies-area-heading {
  background-color: #444CF7 !important;
  padding: 10px;
  color: #fff;
}

.matching-agencies-div-element h3 {
  font-size: 15px;
  text-align: center;
}
/**
 * CSS for validation error in popup ends
 */

.matching-agencies-div-element .matching-agencies-area {
  background-color: #fff;
  max-height: 200px;
  overflow-x: scroll;
  min-height: max-content;
  width: 100%;
  overflow-x: hidden;
}

.matching-agencies-div-element .matching-agencies-area ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.matching-agencies-div-element .matching-agencies-area ul li {
  list-style-type: none;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  background-color: #f1f1f1;
  cursor: pointer;
  font-weight: bold;

}

.matching-agencies-div-element .matching-agencies-area ul li:hover {
  background-color: #e7e6e6;
}

.add-new-entry {
  color: #fff;
  text-decoration: none;
  width: max-content;
  font-weight: bold !important;
  font-size: 16px !important;
  height: 36px !important;
  background-color: #444CF7 !important;
}

.filter-new-entry {
  color: #444CF7 !important;
  border: 1px solid #444CF7 !important;
  font-weight: bold !important;
}

.new-filter-apply {
  background-color: #444CF7 !important;
}

.search-button-area {
  text-align: right;

  button {
    margin: 0 5px 0;
  }
}

.agency-tab-content .div:first-child {
  padding: 15px !important;
}

.submit-button {
  color: #fff;
  text-decoration: none;
  font-weight: bold !important;
  font-size: 16px !important;
  height: 36px !important;
  background-color: #444CF7 !important;
}

.action-button {
  margin-top: 10px !important;
}

.card-box {
  width: 400px;
  box-shadow: 0 0px 6px 2px rgba(124, 103, 103, 0.34);
  margin: 0 0 30px 0;

  .card-content {
    display: flex;
  }

  .AgencyDetails {
    width: 100%;
    padding: 5px;
  }

  .AgencyDetailsValue {
    margin-left: 5px;
    font-weight: bold;
  }

  .card-button {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    a {
      color: black;
    }

    ul.action-items {
      list-style-type: none;

      li {
        list-style-type: none;
        margin: 0;
        padding: 0;
        height: 37px;
      }
    }

    .card-icons {
      border: 1px solid rgba(140, 136, 136, 0.456);
      width: 30px;
      padding: 7px;
      border-radius: 5px;
      transition: all 0.2s ease-in-out;

      &:hover {
        box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
      }
    }

    .card-icons-images {
      border: 1px solid rgba(140, 136, 136, 0.456);
      width: 30px !important;
      padding: 4px;
      border-radius: 5px;
    }
  }
}

.agency-details-margin {
  padding: 0px 15px 35px 15px !important;
}

.no-data-available {
  padding: 20px 20px;
  font-size: 18px;
}

.sub-headings {
  font-size: 18px;
  padding: 10px 0;
  font-weight: bold;
  text-decoration: underline;
}

.space-between-rows {
  margin-bottom: 10px;
  background-color: #f1f1f1;
  padding: 25px;
  border-radius: 5px;
}

.ant-form-item .ant-form-item-row .ant-form-item-label {
  min-width: 100%;
  text-align: left;
}

.ant-picker {
  width: 100%;
}

.show-agency-values {
  width: max-content;
  min-width: 100%;
  background-color: #f1f1f1;
  color: black;
  border-radius: 5px !important;
  padding: 5px 10px;
  margin-bottom: 5px;
}

.form-input-box {
  width: 100%;
  color: black;
  border-radius: 5px !important;
  padding: 5px 10px;
  margin-bottom: 5px;
  height: 40px;
}

.form-select-box {
  color: black;
  border-radius: 5px !important;
  height: 40px;
}

.agency-project-rate-display {
  display: flex;
}

.sub-module-create-agency {
  border: 1px solid #d1d1d1;
  padding: 20px;
  border-radius: 5px;
}

.marginBottom15 {
  margin-bottom: 15px;
}

.add-more {
  margin: 15px 0 0;
  text-align: right;
}

.add-more button {
  background-color: #444CF7 !important;
}

.loading-rate-element {
  width: 100%;
  display: flex;
}

.delete-button-add-more {
  min-width: 32px !important;
}

.add-more-whole-grid {
  text-align: center;
}

.submit-button-area {
  margin-top: 15px;
  text-align: center;
  margin-bottom: 25px;
}

.submit-button-area button {
  margin: 0 5px 0;
}

.remove-main-block-element {
  position: relative;
  text-align: right;
}

.remove-main-block-element button {
  position: absolute;
  right: -8px;
  bottom: -22px;
  background-color: #444CF7 !important;
}

.input-error-message {
  color: #ff4d4f;
}

.input-error {
  border-color: #ff4d4f;
  box-shadow: 0 0px 2px #ff4d4f;
}

.pagination-box {
  padding: 50px 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.marginBottom20 {
  margin-bottom: 20px;
}

.marginTop20 {
  padding: 20px !important;
  background-color: #8080805c !important;
}

.agency-type-image {
  padding: 5px;
  position: relative;
  top: 3px;
}

.total-items {
  width: 400px !important;
  padding: 20px;
  border-radius: 5px !important;
  color: snow !important;
  margin: 10px 0 !important;
  text-align: right !important;
}

.header-grid-flex {
  display: flex;
  justify-content: space-between;
}

.header-text-fontSize {
  font-size: 2.5rem;
}

.total-item-first {
  background-color: #455785;
}

.total-item-second {
  background-color: #7A6E49;
}

.total-item-third {
  background-color: #0080FF;
}

.total-item-fourth {
  background-color: #479036;
}

.headerMarginTop {
  //margin-top: 10px !important;
}

.select-new-entry {
  width: 200px !important;
}

.form-select-div-select {
  position: relative;
  left: -150px !important;
}

.content {
  padding: 50px;
}

@media (max-width: 576px) {
  .header-fixed-agency {
    width: 100vw !important;
    margin-top: 10px !important;
  }

  .marginTop20 {
    width: 100vw !important;
  }

  .card-box {
    width: 300px;
  }

  .add-new-entry {
    margin-left: 30px !important;
  }

  .select-new-entry {
    width: 200px !important;
  }

  .form-select-div-select {
    left: 0px !important;
  }

  .headerMarginTop{ 
    overflow-x: scroll !important;
  }

  .total-items {
    width: 220px !important;
    height: 120px !important;
    padding: 15px 20px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 220px !important;
    }

    &.total-item-third {
      left: 440px !important;
    }

    &.total-item-fourth {
      left: 660px !important;
    }
  }
}

@media (min-width: 577px) and (max-width: 699px) {

  .header-fixed-agency {
    margin-top: 10px !important;
  }

  .card-box {
    width: 100%;
  }

  .marginTop20 {
    width: 100vw !important;
  }

  .add-new-entry {
    margin-left: 30px !important;
  }

  .select-new-entry {
    width: 200px !important;
  }

  .form-select-div-select {
    left: 0px !important;
  }

  .headerMarginTop{ 
    overflow-x: scroll !important;
  }

  .total-items {
    width: 230px !important;
    height: 120px !important;
    padding: 15px 20px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 220px !important;
    }

    &.total-item-third {
      left: 440px !important;
    }

    &.total-item-fourth {
      left: 660px !important;
    }
  }
}

@media (min-width: 700px) and (max-width: 750px) {
  .header-fixed-agency {
    margin-top: 10px !important;
  }

  .card-box {
    width: 100%;
  }

  .select-new-entry {
    width: 200px !important;
  }

  .form-select-div-select {
    left: 0px !important;
  }

  .headerMarginTop{ 
    overflow-x: scroll !important;
  }

  .total-items {
    width: 230px !important;
    height: 120px !important;
    padding: 15px 20px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 207px !important;
    }

    &.total-item-third {
      left: 415px !important;
    }

    &.total-item-fourth {
      left: 624px !important;
    }
  }
}

@media (min-width: 750px) and (max-width: 800px) {
  .header-fixed-agency {
    margin-top: 10px !important;
  }

  .card-box {
    width: 100%;
  }

  .select-new-entry {
    width: 200px !important;
  }

  .form-select-div-select {
    left: 0px !important;
  }

  .headerMarginTop{ 
    overflow-x: scroll !important;
  }

  .total-items {
    width: 230px !important;
    height: 120px !important;
    padding: 15px 20px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 207px !important;
    }

    &.total-item-third {
      left: 415px !important;
    }

    &.total-item-fourth {
      left: 624px !important;
    }
  }
}

@media (min-width: 800px) and (max-width: 850px) {
  .header-fixed-agency {
    margin-top: 10px !important;
  }

  .card-box {
    width: 100%;
  }

  .select-new-entry {
    width: 200px !important;
  }

  .form-select-div-select {
    left: 0px !important;
  }

  .headerMarginTop{ 
    overflow-x: scroll !important;
  }

  .total-items {
    width: 230px !important;
    height: 120px !important;
    padding: 15px 20px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 200px !important;
    }

    &.total-item-third {
      left: 400px !important;
    }

    &.total-item-fourth {
      left: 600px !important;
    }
  }
}

@media (min-width: 850px) and (max-width: 900px) {
  .header-fixed-agency {
    margin-top: 10px !important;
  }

  .card-box {
    width: 100%;
  }

  .select-new-entry {
    width: 200px !important;
  }

  .form-select-div-select {
    left: 0px !important;
  }

  .headerMarginTop{ 
    overflow-x: scroll !important;
  }

  .total-items {
    width: 230px !important;
    height: 120px !important;
    padding: 15px 20px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 200px !important;
    }

    &.total-item-third {
      left: 400px !important;
    }

    &.total-item-fourth {
      left: 600px !important;
    }
  }
}

@media (min-width: 900px) and (max-width: 950px) {
  .card-box {
    width: 300px;
    height: 160px;
  }

  .add-new-entry {
    margin-left: -60px !important;
  }

  .filter-new-entry {
    margin-left: -110px !important;
  }

  .select-new-entry {
    width: 150px !important;
  }

  .form-select-div-select {
    left: -260px !important;
  }

  .headerMarginTop{ 
    overflow-x: scroll !important;
  }

  .total-items {
    width: 225px !important;
    height: 120px !important;
    padding: 15px 20px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 140px !important;
    }

    &.total-item-third {
      left: 280px !important;
    }

    &.total-item-fourth {
      left: 420px !important;
    }
  }
}

@media (min-width: 951px) and (max-width: 1000px) {
  .card-box {
    width: 330px;
    height: 160px;
    margin-right: 10px;
  }

  .add-new-entry {
    margin-left: -60px !important;
  }

  .filter-new-entry {
    margin-left: -110px !important;
  }

  .form-select-div-select {
    left: -310px !important;
  }

  .headerMarginTop{ 
    overflow-x: scroll !important;
  }

  .total-items {
    width: 235px !important;
    height: 120px !important;
    padding: 15px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 140px !important;
    }

    &.total-item-third {
      left: 280px !important;
    }

    &.total-item-fourth {
      left: 420px !important;
    }
  }
}

@media (min-width: 1001px) and (max-width: 1050px) {
  .card-box {
    width: 350px;
    height: 160px;
    margin-right: 10px;
  }

  .add-new-entry {
    margin-left: -50px !important;
  }

  .filter-new-entry {
    margin-left: -95px !important;
  }

  .form-select-div-select {
    left: -290px !important;
  }

  .headerMarginTop{ 
    overflow-x: scroll !important;
  }

  .total-items {
    width: 240px !important;
    height: 120px !important;
    padding: 15px 20px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 140px !important;
    }

    &.total-item-third {
      left: 280px !important;
    }

    &.total-item-fourth {
      left: 420px !important;
    }
  }
}

@media (min-width: 1051px) and (max-width: 1100px) {
  .card-box {
    width: 370px;
    height: 160px;
    margin-right: 10px;
  }

  .add-new-entry {
    margin-left: -50px !important;
  }

  .filter-new-entry {
    margin-left: -95px !important;
  }

  .form-select-div-select {
    left: -290px !important;
  }

  .headerMarginTop{ 
    overflow-x: scroll !important;
  }

  .total-items {
    width: 250px !important;
    height: 120px !important;
    padding: 15px 20px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 140px !important;
    }

    &.total-item-third {
      left: 280px !important;
    }

    &.total-item-fourth {
      left: 420px !important;
    }
  }
}

@media (min-width: 1100px) and (max-width: 1200px) {
  .add-new-entry {
    margin-left: -30px !important;
  }

  .filter-new-entry {
    margin-left: -60px !important;
  }

  .form-select-div-select {
    left: -240px !important;
  }

  .headerMarginTop{ 
    overflow-x: scroll !important;
  }

  .total-items {
    width: 250px !important;
    height: 120px !important;
    padding: 15px 20px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 130px !important;
    }

    &.total-item-third {
      left: 260px !important;
    }

    &.total-item-fourth {
      left: 390px !important;
    }
  }
}

@media (min-width: 1200px) and (max-width: 1299px) {
  .card-box {
    width: 290px;
    height: 160px;
  }

  .add-new-entry {
    margin-left: -60px !important;
  }

  .filter-new-entry {
    margin-left: -110px !important;
  }

  .form-select-div-select {
    left: -310px !important;
  }

  .total-items {
    height: 120px !important;
    padding: 15px 20px !important;
    width: 220px !important;
  }
}

@media (min-width: 1300px) and (max-width: 1349px) {
  .card-box {
    width: 330px;
    height: 160px;
  }

  .add-new-entry {
    margin-left: -30px !important;
  }

  .filter-new-entry {
    margin-left: -70px !important;
  }

  .form-select-div-select {
    left: -260px !important;
  }

  .total-items {
    width: 250px !important;
    height: 120px !important;
    padding: 15px 20px !important;
  }
}

@media (min-width: 1350px) and (max-width: 1399px) {
  .card-box {
    width: 340px;
    height: 160px;
  }

  .add-new-entry {
    margin-left: -30px !important;
  }

  .filter-new-entry {
    margin-left: -70px !important;
  }

  .form-select-div-select {
    left: -260px !important;
  }

  .total-items {
    width: 255px !important;
  }
}

@media (min-width: 1400px) and (max-width: 1449px) {
  .card-box {
    width: 350px;
  }

  .add-new-entry {
    margin-left: -30px !important;
  }

  .filter-new-entry {
    margin-left: -70px !important;
  }

  .form-select-div-select {
    left: -250px !important;
  }

  .total-items {
    width: 265px !important;
  }
}

@media (min-width: 1450px) and (max-width: 1499px) {
  .card-box {
    width: 370px;
  }

  .add-new-entry {
    margin-left: -30px !important;
  }

  .filter-new-entry {
    margin-left: -63px !important;
  }

  .form-select-div-select {
    left: -250px !important;
  }

  .total-items {
    width: 280px !important;
  }
}

@media (min-width: 1500px) and (max-width: 1549px) {
  .card-box {
    width: 390px;
  }

  .add-new-entry {
    margin-left: -20px !important;
  }

  .filter-new-entry {
    margin-left: -50px !important;
  }

  .form-select-div-select {
    left: -220px !important;
  }

  .total-items {
    width: 290px !important;
  }
}

@media (min-width: 1550px) and (max-width: 1599px) {
  .card-box {
    width: 400px;
  }

  .add-new-entry {
    margin-left: -20px !important;
  }

  .filter-new-entry {
    margin-left: -40px !important;
  }

  .form-select-div-select {
    left: -210px !important;
  }

  .total-items {
    width: 300px !important;
  }
}

@media (min-width: 1600px) and (max-width: 1649px) {
  .card-box {
    width: 430px;
  }

  .filter-new-entry {
    margin-left: -20px !important;
  }

  .form-select-div-select {
    left: -190px !important;
  }

  .total-items {
    width: 320px !important;
  }
}

@media (min-width: 1650px) and (max-width: 1700px) {
  .card-box {
    width: 440px;
  }

  .filter-new-entry {
    margin-left: -20px !important;
  }

  .form-select-div-select {
    left: -180px !important;
  }

  .total-items {
    width: 330px !important;
  }
}

@media (min-width: 1701px) and (max-width: 1749px) {
  .card-box {
    width: 455px;
  }

  .total-items {
    width: 345px !important;
  }
}

@media (min-width: 1750px) and (max-width: 1799px) {
  .card-box {
    width: 470px;
  }

  .total-items {
    width: 355px !important;
  }
}

@media (min-width: 1800px) and (max-width: 1849px) {
  .card-box {
    width: 490px;
  }

  .total-items {
    width: 370px !important;
  }
}

@media (min-width: 1850px) and (max-width: 1899px) {
  .card-box {
    width: 500px;
  }

  .total-items {
    width: 380px !important;
  }
}

@media (min-width: 1900px) {
  .card-box {
    width: 520px !important;
  }
}