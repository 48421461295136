.view-loading-padding {
  padding: 15px !important;
}

.grid-space {
  margin-top: 15px !important;
}

.grid-input-space {
  margin-top: 8px !important;
}

.remark-input {
  border: none !important;
}

.margintop20 {
  margin-top: 20px !important;
}

.back-button {
  color: #fff;
  text-decoration: none;
  width: 100%;
  font-weight: bold !important;
  font-size: 16px !important;
  height: 36px !important;
  background-color: #444cf7 !important;
}

.agency-details-margin {
  padding: 0 15px !important;
}

.action-button {
  justify-content: end;
}

.submit-button {
  color: #fff;
  text-decoration: none;
  font-weight: bold !important;
  font-size: 16px !important;
  height: 36px !important;
  background-color: #444cf7 !important;
}

.form-input-box {
  width: 100%;
  color: black;
  border-radius: 5px !important;
  padding: 5px 10px;
  margin-bottom: 5px;
  height: 40px;
}

.cancelled-loading-challan-no {
  color: red;
}

/* responsive css starts from here */
@media (max-width: 576px) {
  .card-box {
    width: 100% !important;
  }

  .back-button {
    margin-top: 10px !important;
  }
}

@media (min-width: 900px) and (max-width: 1199px) {
  .card-box {
    width: 280px !important;
  }

  .back-button {
    margin-top: 10px !important;
  }
}

@media (min-width: 1200px) and (max-width: 1450px) {
  .card-box {
    width: 280px !important;
  }

  .back-button {
    margin-top: 10px !important;
  }
}

@media (min-width: 1451px) and (max-width: 1500px) {
  .card-box {
    width: 370px !important;
  }

  .back-button {
    margin-top: 10px !important;
  }
}

@media (min-width: 1501px) and (max-width: 1576px) {
  .card-box {
    width: 400px !important;
  }

  .back-button {
    margin-top: 10px !important;
  }
}

@media (min-width: 1577px) and (max-width: 1799px) {
  .card-box {
    width: 430px !important;
  }

  .back-button {
    margin-top: 10px !important;
  }
}

@media (min-width: 1800px) {
  .card-box {
    width: 450px !important;
  }

  .back-button {
    margin-top: 10px !important;
  }
}