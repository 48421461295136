.create-unloading-padding {
  padding: 15px !important;
}

.grid-space {
  margin-bottom: 15px !important;
}

.form-hidden-field {
  border: none !important;
  font-size: 2rem;
  font-weight: bold;
}

.remark-input {
  border: none !important;
}

.disabled-input {
  color: #666 !important;
}

.disabled-input span {
  color: #666 !important;
}

.add-new-entry {
  color: #fff;
  text-decoration: none;
  width: 100%;
  font-weight: bold !important;
  font-size: 16px !important;
  height: 36px !important;
  background-color: #444cf7 !important;
}

.action-button {
  justify-content: end;
}

.submit-button {
  color: #fff;
  text-decoration: none;
  font-weight: bold !important;
  font-size: 16px !important;
  height: 36px !important;
  background-color: #444cf7 !important;
}

.form-input-box {
  width: 100%;
  color: black;
  border-radius: 5px !important;
  padding: 5px 10px;
  margin-bottom: 5px;
  height: 40px;
}

.form-select-box {
  color: black;
  border-radius: 5px !important;
  height: 40px;
}

.submit-button-area {
  margin: 15px 0;
  text-align: center;

  button {
    margin: 0 5px 0;
  }
}

.agency-details-margin {
  padding: 0 15px !important;
}

.pdf-container{
  width: 100%;
  height: 500px;
  background-color: #e4e4e4;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-msg{
  width: 100%;
  color: red;
  font-size: 14px;
  font-weight: 600;
}

@media (max-width: 900px) {
  .grid-space {
    margin-top: 0px !important;
  }
}

@media (min-width: 1400px) and (max-width: 1600px) {
  .add-new-entry {
    margin-left: -50px !important;
  }
}

@media (min-width: 1200px) and (max-width: 1450px) {
  .add-new-entry {
    margin-left: -50px !important;
  }
}