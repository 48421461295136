.industry-img {
  height: 100vh;
  width: 100%;
  background-image: url("../../../assets/images/industry.jpg");
  background-position: center;
  background-size: cover;
}

@media (max-width: 576px) {
  .wrapper-section-one {
    height: 47vh;
  }

  .industry-img {
    height: 100%;
    object-fit: cover;
  }
}
