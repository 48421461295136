.generated-petrol-pump-bill-padding {
  padding: 20px !important;
}

.petrol-pump-bill-number-wrapper {
  display: flex;
  gap: 1.5rem;

  .select-new-entry {
    width: 350px;
  }
}

.petrol-pump-bill-select-filter-wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 1.5rem;

  .select-new-entry {
    width: 350px;
  }

  .filter-new-entry {
    border-color: #444cf4 !important;
    color: #444cf4 !important;
  }
}

.search-button-area {
  text-align: right;

  button {
    margin: 0 5px 0;
  }

  .submit-button {
    background-color: #444cf4 !important;
  }
}

.middle-boundary-transport {
  background-color: #746446;
  display: flex;
  justify-content: space-between;
  margin: 0 10px !important;
  padding: 0px 15px;
  border-radius: 5px;

  .text-boundary-tranport {
    letter-spacing: 1px;
  }

  .total-amount-transport {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
  }
}

.petrol-pump-counter {
  padding: 25px 2px;
  text-align: center;
  border-radius: 5px;
  height: 120px;

  h5 {
    font-size: 1.8rem;
  }

  &.petrol-pump-first-counter {
    background-color: #744446;
  }
}

.bill-detail-padding {
  padding: 10px !important;

  .petrol-pump-view-detail {
    background-color: #fafafa;
    border: 1px solid #adadad;
    padding: 15px 0px;
    border-radius: 5px;
    transition: all 0.2s ease-in;
    text-align: center;

    .view-list-flex-link {
      display: flex;
      width: 100%;
    }

    &:hover {
      box-shadow: 1px 3px 3px #adadad;
    }
  }

  .bill-numbers-icons {
    background-color: #fafafa;
    border: 1px solid #adadad;
    padding: 19px;
    border-radius: 5px;
    transition: all 0.2s ease-in;

    &:hover {
      box-shadow: 1px 3px 3px #adadad;
    }
  }
}

@media (max-width: 900px) {
  .petrol-pump-bill-select-filter-wrapper {
    justify-content: flex-start !important;
  }
}

@media (max-width: 600px) {
  .petrol-pump-bill-number-wrapper {
    .select-new-entry {
      width: 90px !important;
    }
  }

  .petrol-pump-bill-select-filter-wrapper {
    .select-new-entry {
      width: 90px !important;
      margin-top: -25px;
    }

    .filter-new-entry {
      margin-top: 12px !important;
    }
  }
}

@media (min-width: 600px) and (max-width: 700px) {
  .petrol-pump-bill-number-wrapper {
    .select-new-entry {
      width: 200px !important;
    }
  }

  .petrol-pump-bill-select-filter-wrapper {
    .select-new-entry {
      width: 200px !important;
    }
  }
}

@media (min-width: 700px) and (max-width: 800px) {
  .petrol-pump-bill-number-wrapper {
    .select-new-entry {
      width: 210px !important;
    }
  }

  .petrol-pump-bill-select-filter-wrapper {
    .select-new-entry {
      width: 210px !important;
    }
  }
}

@media (min-width: 900px) and (max-width: 1000px) {
  .petrol-pump-bill-number-wrapper {
    .select-new-entry {
      width: 150px !important;
    }
  }

  .petrol-pump-bill-select-filter-wrapper {
    .select-new-entry {
      width: 150px !important;
    }
  }
}

@media (min-width: 1000px) and (max-width: 1100px) {
  .petrol-pump-bill-number-wrapper {
    .select-new-entry {
      width: 200px !important;
    }
  }

  .petrol-pump-bill-select-filter-wrapper {
    .select-new-entry {
      width: 200px !important;
    }
  }
}

@media (min-width: 1100px) and (max-width: 1200px) {
  .petrol-pump-bill-number-wrapper {
    .select-new-entry {
      width: 250px !important;
    }
  }

  .petrol-pump-bill-select-filter-wrapper {
    .select-new-entry {
      width: 250px !important;
    }
  }
}

@media (min-width: 1200px) and (max-width: 1300px) {
  .petrol-pump-bill-number-wrapper {
    .select-new-entry {
      width: 300px !important;
    }
  }

  .petrol-pump-bill-select-filter-wrapper {
    .select-new-entry {
      width: 300px !important;
    }
  }
}
