.loading-pending-padding {
    padding: 15px !important;
}

.search-new-entry {
    width: 50%;
}

.filter-new-entry {
    color: #444cf7 !important;
    font-weight: bold !important;
    border: 1px solid #444cf7 !important;
}

.button-disabled {
    background: #ccc !important;
    color: #666 !important;
    border: 1px solid #ccc !important;
}

.pagination-box {
    padding: 20px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 99999;
    background-color: #eaebf0 !important;
}

.generate-bill-new-entry {
    width: max-content;
    color: #ffffff !important;
    background-color: #444cf7 !important;
}

.right-align {
    text-align: right !important;
}

.right-align button.export-button {
    margin-right: 10px;
}

.search-button-area {
    text-align: right;

    button {
        margin: 0 5px 0;
    }
}

.submit-button {
    background-color: #444CF7 !important;
}

.pending-bill-table {
    padding: 15px !important;
}


/**
  * Agency selection area css
  */

.loading_agency_selection_area {
    display: flex;
    justify-content: space-between;
}

.loading_agency_options {
    margin-bottom: 0 !important
}

.bill_number_heading {
    margin: 5px 10px 0 0;
    text-align: right !important;
}

.bill_no_input,
.bill_no_input:hover {
    background-color: #D2D4FD;
    border-radius: 0px;
    outline: none;
    border: 1px solid transparent !important;
}

.loading-pending-padding-loading-agency-dropdown {
    padding: 15px 15px 0px 15px !important;
}

.loading-pending-padding-agency-title {
    padding: 0px 15px 0px 15px !important;
}

.loading_agency_title_row {
    background-color: #479036 !important;
    color: #fff;
    padding: 3px 10px 3px 11px;
    border-radius: 5px;
}

.loading_agency_title,
.loading_agency_title_payable_amount {
    font-size: 18px;
}

.loading_agency_title_payable_amount {
    text-align: right !important;
}

/**
 * Agency selection area css ends
 */

/**
  * COunt items box css starts
  */
.loading_agency_bill_count_items {
    min-width: 130px;
    height: 110px;
    margin-right: 25px !important;
    border-radius: 5px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: #fff !important;
}

.loading_agency_bill_trip_count_last_item {
    margin: 0px !important;
}

.loading_agency_bill_trip_count_block {
    background-color: #42536C !important;
}

.loading_agency_bill_total_unloading_count_block {
    background-color: #28BBC3 !important;
}

.loading_agency_bill_work_effective_block {
    background-color: #43377C !important;
}

.loading_agency_bill_work_amount_block {
    background-color: #8578E3 !important;
}

.loading_agency_bill_updated_hsd_block {
    background-color: #E7655F !important;
}

.loading_agency_bill_hsd_amount_block {
    background-color: #574C8F !important;
}

.loading_agency_bill_diesel_ratio_block {
    background-color: #2C7BFF !important;
}

.loading_agency_bill_count_main {
    font-size: 28px;
    font-weight: bold;
}

.loading_agency_bill_count_label {
    font-size: 1rem;
}

.add-new-entry {
    color: #fff;
    text-decoration: none;
    width: max-content;
    font-weight: bold !important;
    font-size: 16px !important;
    height: 36px !important;
    background-color: #444CF7 !important;
}

/**
   * Count item box css ends
   * 
   */

@media (max-width: 499px) {

    .breadcrumb_buttons button {
        margin-bottom: 10px !important
    }

    .loading-pending-padding .right-align {
        text-align: left !important;
        margin-top: 5px !important
    }

    .loading_agency_title,
    .loading_agency_title_payable_amount {
        font-size: 18px;
    }

    .loading_agency_title_payable_amount {
        text-align: left !important;
    }

    .loading_agency_bill_count_items {
        width: 100% !important;
        margin-bottom: 10px !important;
    }

    .right-align button.export-button {
        margin: 0px 0 10px 0 !important;
    }

    .bill_number_heading {
        text-align: left !important;
    }

    .search-new-entry {
        width: 100%;
    }

    .generate-bill-new-entry {
        margin-top: 10px !important;
    }

    .pending-bill-table {
        margin-top: 20px !important;
    }
}

@media (min-width: 500px) and (max-width: 549px) {

    .breadcrumb_buttons button {
        margin-bottom: 10px !important
    }

    .loading-pending-padding .right-align {
        text-align: left !important;
        margin-top: 5px !important
    }

    .loading_agency_title,
    .loading_agency_title_payable_amount {
        font-size: 18px;
    }

    .loading_agency_title_payable_amount {
        text-align: left !important;
    }

    .loading_agency_bill_count_items {
        width: 100% !important;
        margin-bottom: 10px !important;
    }

    .right-align button.export-button {
        margin: 0px 0 10px 0 !important;
    }

    .bill_number_heading {
        text-align: left !important;
    }

    .search-new-entry {
        width: 100%;
    }

    .generate-bill-new-entry {
        margin-top: 10px !important;
    }

    .pending-bill-table {
        margin-top: 20px !important;
    }
}

@media (min-width: 550px) and (max-width: 600px) {

    .breadcrumb_buttons button {
        margin-bottom: 10px !important
    }

    .loading-pending-padding .right-align {
        text-align: left !important;
        margin-top: 5px !important
    }

    .loading_agency_title,
    .loading_agency_title_payable_amount {
        font-size: 18px;
    }

    .loading_agency_title_payable_amount {
        text-align: left !important;
    }

    .loading_agency_bill_count_items {
        width: 100% !important;
        margin-bottom: 10px !important;
    }

    .right-align button.export-button {
        margin: 0px 0 10px 0 !important;
    }

    .bill_number_heading {
        text-align: left !important;
    }

    .search-new-entry {
        width: 100%;
    }

    .generate-bill-new-entry {
        margin-top: 10px !important;
    }

    .pending-bill-table {
        margin-top: 20px !important;
    }
}


@media (min-width: 601px) and (max-width: 649px) {

    .breadcrumb_buttons button {
        margin-bottom: 10px !important
    }

    .loading-pending-padding .right-align {
        text-align: right !important;
        padding-right: 10px !important
    }

    .loading_agency_title,
    .loading_agency_title_payable_amount {
        font-size: 18px;
    }

    .loading_agency_title_payable_amount {
        text-align: left !important;
    }

    .loading_agency_bill_count_items {
        width: 95% !important;
        margin-bottom: 10px !important;
    }

    .right-align button.export-button {
        margin: 0px 0 10px 0 !important;
    }

    .bill_number_heading {
        text-align: left !important;
    }

    .search-new-entry {
        width: 100%;
    }

    .generate-bill-new-entry {
        margin-top: 10px !important;
    }

    .pending-bill-table {
        margin-top: 20px !important;
    }
}

@media (min-width: 650px) and (max-width: 699px) {

    .breadcrumb_buttons button {
        margin-bottom: 10px !important
    }

    .loading-pending-padding .right-align {
        text-align: right !important;
        padding-right: 10px !important
    }

    .loading_agency_title,
    .loading_agency_title_payable_amount {
        font-size: 18px;
    }

    .loading_agency_title_payable_amount {
        text-align: left !important;
    }

    .loading_agency_bill_count_items {
        width: 95% !important;
        margin-bottom: 10px !important;
    }

    .right-align button.export-button {
        margin: 0px 0 10px 0 !important;
    }

    .bill_number_heading {
        text-align: left !important;
    }

    .generate-bill-new-entry {
        margin-top: 10px !important;
    }

    .pending-bill-table {
        margin-top: 20px !important;
    }
}

@media (min-width: 700px) and (max-width: 749px) {

    .breadcrumb_buttons button {
        margin-bottom: 10px !important
    }

    .loading-pending-padding .right-align {
        text-align: right !important;
        padding-right: 10px !important
    }

    .loading_agency_title,
    .loading_agency_title_payable_amount {
        font-size: 18px;
    }

    .loading_agency_title_payable_amount {
        text-align: left !important;
    }

    .loading_agency_bill_count_items {
        width: 95% !important;
        margin-bottom: 10px !important;
    }

    .right-align button.export-button {
        margin: 0px 0 10px 0 !important;
    }

    .bill_number_heading {
        text-align: left !important;
    }

    .generate-bill-new-entry {
        margin-top: 10px !important;
    }

    .pending-bill-table {
        margin-top: 20px !important;
    }
}


@media (min-width: 750px) and (max-width: 799px) {

    .breadcrumb_buttons button {
        margin-bottom: 10px !important
    }

    .loading-pending-padding .right-align {
        text-align: right !important;
        padding-right: 10px !important;
    }

    .loading_agency_title,
    .loading_agency_title_payable_amount {
        font-size: 18px;
    }

    .loading_agency_title_payable_amount {
        text-align: right !important;
    }

    .loading_agency_bill_count_items {
        width: 95% !important;
        margin-bottom: 10px !important;
    }

    .right-align button.export-button {
        margin: 0px 0 10px 0 !important;
    }

    .bill_number_heading {
        text-align: left !important;
    }

    .generate-bill-new-entry {
        margin-top: 10px !important;
    }

    .pending-bill-table {
        margin-top: 20px !important;
    }
}

@media (min-width: 800px) and (max-width: 849px) {

    .breadcrumb_buttons button {
        margin-bottom: 10px !important
    }

    .loading-pending-padding .right-align {
        text-align: right !important;
        padding-right: 10px !important;
    }

    .loading_agency_title,
    .loading_agency_title_payable_amount {
        font-size: 18px;
    }

    .loading_agency_title_payable_amount {
        text-align: right !important;
    }

    .loading_agency_bill_count_items {
        width: 95% !important;
        margin-bottom: 10px !important;
    }

    .right-align button.export-button {
        margin: 0px 0 10px 0 !important;
    }

    .bill_number_heading {
        text-align: left !important;
    }

    .generate-bill-new-entry {
        margin-top: 10px !important;
    }

    .pending-bill-table {
        margin-top: 20px !important;
    }
}

@media (min-width: 850px) and (max-width: 899px) {

    .breadcrumb_buttons button {
        margin-bottom: 10px !important
    }

    .loading-pending-padding .right-align {
        text-align: right !important;
        padding-right: 10px !important;
    }

    .loading_agency_title,
    .loading_agency_title_payable_amount {
        font-size: 18px;
    }

    .loading_agency_title_payable_amount {
        text-align: right !important;
    }

    .loading_agency_bill_count_items {
        width: 95% !important;
        margin-bottom: 10px !important;
    }

    .right-align button.export-button {
        margin: 0px 0 10px 0 !important;
    }

    .bill_number_heading {
        text-align: left !important;
    }

    .generate-bill-new-entry {
        margin-top: 10px !important;
    }

    .pending-bill-table {
        margin-top: 20px !important;
    }
}

@media (min-width: 900px) and (max-width: 949px) {

    .breadcrumb_buttons button {
        margin-bottom: 10px !important
    }

    .loading-pending-padding .right-align {
        text-align: right !important;
        padding-right: 10px !important;
    }

    .loading_agency_title,
    .loading_agency_title_payable_amount {
        font-size: 18px;
    }

    .loading_agency_title_payable_amount {
        text-align: right !important;
    }

    .loading_agency_bill_count_items {
        width: 95% !important;
        margin-bottom: 10px !important;
    }

    .right-align button.export-button {
        margin: 0px 0 10px 0 !important;
    }

    .bill_number_heading {
        text-align: right !important;
    }

    .generate-bill-new-entry {
        margin-top: 10px !important;
    }

    .pending-bill-table {
        margin-top: 20px !important;
    }
}

@media (min-width: 950px) and (max-width: 999px) {

    .breadcrumb_buttons button {
        margin-bottom: 10px !important
    }

    .loading-pending-padding .right-align {
        text-align: right !important;
        padding-right: 10px !important;
    }

    .loading_agency_title,
    .loading_agency_title_payable_amount {
        font-size: 18px;
    }

    .loading_agency_title_payable_amount {
        text-align: right !important;
    }

    .loading_agency_bill_count_items {
        width: 95% !important;
        margin-bottom: 10px !important;
    }

    .right-align button.export-button {
        margin: 0px 0 10px 0 !important;
    }

    .bill_number_heading {
        text-align: right !important;
    }

    .generate-bill-new-entry {
        margin-top: 10px !important;
    }

    .pending-bill-table {
        margin-top: 20px !important;
    }
}

@media (min-width: 1000px) and (max-width: 1099px) {

    .breadcrumb_buttons button {
        margin-bottom: 10px !important
    }

    .loading-pending-padding .right-align {
        text-align: right !important;
        padding-right: 10px !important;
    }

    .loading_agency_title,
    .loading_agency_title_payable_amount {
        font-size: 18px;
    }

    .loading_agency_title_payable_amount {
        text-align: right !important;
    }

    .loading_agency_bill_count_items {
        width: 95% !important;
        margin-bottom: 10px !important;
    }

    .right-align button.export-button {
        margin: 0px 0 10px 0 !important;
    }

    .bill_number_heading {
        text-align: right !important;
    }

    .generate-bill-new-entry {
        margin-top: 10px !important;
    }

    .pending-bill-table {
        margin-top: 20px !important;
    }
}

@media (min-width: 1100px) and (max-width: 1199px) {

    .breadcrumb_buttons button {
        margin-bottom: 10px !important
    }

    .loading-pending-padding .right-align {
        text-align: right !important;
        padding-right: 10px !important;
    }

    .loading_agency_title,
    .loading_agency_title_payable_amount {
        font-size: 18px;
    }

    .loading_agency_title_payable_amount {
        text-align: right !important;
    }

    .loading_agency_bill_count_items {
        width: 95% !important;
        margin-bottom: 10px !important;
    }

    .right-align button.export-button {
        margin: 0px 0 10px 0 !important;
    }

    .bill_number_heading {
        text-align: right !important;
    }

    .generate-bill-new-entry {
        margin-top: 10px !important;
    }

    .pending-bill-table {
        margin-top: 20px !important;
    }
}

@media (min-width: 1200px) and (max-width: 1299px) {
    .loading_agency_title_heading {
        margin: 0 5px 0 5px !important;
    }

    .filter-new-entry {
        margin-left: -90px !important;
    }

    .generate-bill-new-entry {
        margin-left: -70px !important;
    }
}

@media (min-width: 1300px) and (max-width: 1399px) {
    .filter-new-entry {
        margin-left: -90px !important;
    }

    .generate-bill-new-entry {
        margin-left: -70px !important;
    }
}

@media (min-width: 1400px) and (max-width: 1499px) {
    .filter-new-entry {
        margin-left: -50px !important;
    }

    .generate-bill-new-entry {
        margin-left: -50px !important;
    }
}

@media (min-width: 1500px) and (max-width: 1599px) {
    .filter-new-entry {
        margin-left: -50px !important;
    }

    .generate-bill-new-entry {
        margin-left: -50px !important;
    }
}

@media (min-width: 1600px) and (max-width: 1699px) {
    .filter-new-entry {
        margin-left: -50px !important;
    }

    .generate-bill-new-entry {
        margin-left: -50px !important;
    }
}

@media (min-width: 1700px) and (max-width: 1799px) {
    .filter-new-entry {
        margin-left: -30px !important;
    }

    .generate-bill-new-entry {
        margin-left: -50px !important;
    }
}