.view-vehicle-padding {
  padding: 15px !important;
}

.grid-space {
  padding: 0 15px !important;
}

.grid-input-space {
  margin-top: 8px !important;
}

.remark-input {
  border: none !important;
}

.action-button {
  justify-content: end;
}

.download_vehicle_details_pdf {
  border: none;
  background: transparent;
  cursor: pointer;
  transition: 0.3s;
}

.download_vehicle_details_pdf:hover {
  opacity: 0.7;
}

.back-button {
  color: #fff;
  text-decoration: none;
  width: 100%;
  font-weight: bold !important;
  font-size: 16px !important;
  height: 36px !important;
  background-color: #444CF7 !important;
}

.submit-button {
  color: #fff;
  text-decoration: none;
  font-weight: bold !important;
  font-size: 16px !important;
  height: 36px !important;
  background-color: #444CF7 !important;
}

.form-input-box {
  width: 100%;
  color: black;
  border-radius: 5px !important;
  padding: 5px 10px;
  margin-bottom: 5px;
  height: 40px;
}

@media (max-width: 576px) {
  .back-button {
    margin-top: 10px !important;
  }
}