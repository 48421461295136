.summary-padding {
    padding: 20px !important;
}

.summary-padding-left-right {
    padding: 0 20px !important;
}

.ant-tabs-tab {
    font-size: 20px !important;
}