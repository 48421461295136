.header-change-password-fixed {
  padding: 20px !important;
}

.grid-space {
  margin-bottom: 15px !important;
}

.form-input-box {
  width: 100%;
  color: black;
  border-radius: 5px !important;
  padding: 5px 10px;
  margin-bottom: 5px;
  height: 40px;
}

.space-between-rows {
  margin-bottom: 10px;
}

.submit-button-area {
  margin-top: 15px;
  text-align: center;

  button {
    margin: 0 5px 0;
  }
}