.menu-active:hover {
    background-color: rgba(116, 113, 113, 0.374) !important;
}

.menu-active {
    background-color: rgba(116, 113, 113, 0.374) !important;
}

.menu-icons {
    height: 30px;
    width: 30px;
    position: relative !important;
    top: 5px !important;
    left: 4.5px !important;
}

.header-items {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    padding: 0;
    background-color: #444CF7;
}

.user-drop-down-element {
    background-color: #444CF7 !important;
}

.profile-drop-down-menus, .profile-drop-down-menus-collapsed {
    width: 100% !important;
    position: absolute;
    bottom: 0;
}

.profile-drop-down-menus button {
    background-color: #444CF7 !important;
    color: #fff;
    font-weight: bold;
    border-top: 1px solid #999;
    border-bottom: 1px solid #999;
    border-radius: 0;
    margin: 0;
    outline: 0;
}

.profile-drop-down-menus button:first-child {
    width: 100% !important;
}

.profile-drop-down-menus button {
    background-color: #444CF7 !important;
    color: #fff;
    font-weight: bold;
    border-top: 1px solid #999;
    border-bottom: 1px solid #999;
    border-radius: 0;
    margin: 0;
    outline: 0;
}

.profile-drop-down-menus-collapsed button {
    width: 100% !important;
    background-color: #444CF7 !important;
    color: #fff;
    font-weight: bold;
    border-top: 1px solid #999;
    border-bottom: 1px solid #999;
    border-radius: 0;
    margin: 0;
    outline: 0;
}

.profile-drop-down-menus-collapsed button:first-child {
    width: 100% !important;
    display: none;
}

.globalHeaderDropDown {
    width: 400px;
    max-width: 500px;
}

.globalTpProjectModal {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    .globalModalInputFields {
        background-color: #fff;
        padding: 40px;
        border: 1px solid #f1f1f1;
        border-radius: 5px;
        max-width: 100%;

        h2 {
            font-size: 18px;
            text-transform: capitalize;
            text-align: center;
            width: 100%;
        }

        .globalModalInputStarts {
            width: 100%;
            padding: 35px 20px 15px 20px;
        }


        .form-input-box {
            width: 100%;
            color: black;
            border-radius: 5px !important;
            padding: 5px 10px;
            margin-bottom: 5px;
            height: 40px;
        }

        .form-select-box {
            color: black;
            border-radius: 5px !important;
            height: 40px;
        }

        .submit-button-area {
            text-align: center;
            width: 100%;

            button {
                margin: 0 5px 0;
            }
        }
    }
}

.submit-button {
    color: #fff;
    text-decoration: none;
    font-weight: bold !important;
    font-size: 16px !important;
    height: 36px !important;
    background-color: #444CF7 !important;
}

@media (min-width: 120px) and (max-width: 399px) {
    .globalTpProjectModal {
        top: 25%;
        left: 0;
    }

    .globalHeaderDropDown {
        width: 50px;
        max-width: 200px;
    }

    .globalHeaderDropDown .globalInput {
        width: 75px !important;
        border-radius: 0 !important;
    }
}

@media (max-width: 576px) {

    .globalTpProjectModal {
        top: 25%;
        left: 0;
    }

    .globalHeaderDropDown {
        width: 100px;
        max-width: 200px;
    }

    .globalHeaderDropDown .globalInput {
        width: 200px !important;
        border-radius: 0 !important;
    }

    .header-items {
        height: 125px !important;
    }
}

@media (min-width: 577px) and (max-width: 699px) {
    .globalTpProjectModal {
        top: 25%;
        left: 0;
    }

    .globalHeaderDropDown {
        width: 100px;
        max-width: 200px;
    }

    .globalHeaderDropDown .globalInput {
        width: 200px !important;
        border-radius: 0 !important;
    }

    .header-items {
        height: 125px !important;
    }
}

@media (min-width: 700px) and (max-width: 899px) {
    .card-box {
        width: 100%;
    }

    .add-new-entry {
        margin-top: 10px !important;
    }

    .globalHeaderDropDown .globalInput {
        width: 200px !important;
        border-radius: 0 !important;
    }

    .header-items {
        height: 125px !important;
    }
}

@media (min-width: 900px) and (max-width: 950px) {
    .card-box {
        width: 300px;
        height: 160px;
    }
}

@media (min-width: 951px) and (max-width: 1000px) {
    .card-box {
        width: 330px;
        height: 160px;
        margin-right: 10px;
    }
}

@media (min-width: 1001px) and (max-width: 1050px) {
    .card-box {
        width: 350px;
        height: 160px;
        margin-right: 10px;
    }
}

@media (min-width: 1051px) and (max-width: 1100px) {
    .card-box {
        width: 370px;
        height: 160px;
        margin-right: 10px;
    }
}

@media (min-width: 1200px) and (max-width: 1299px) {
    .card-box {
        width: 290px;
        height: 160px;
    }

    .add-new-entry {
        margin-left: -30px !important;
    }
}

@media (min-width: 1300px) and (max-width: 1349px) {
    .card-box {
        width: 330px;
        height: 160px;
    }

    .add-new-entry {
        margin-left: -30px !important;
    }
}

@media (min-width: 1350px) and (max-width: 1399px) {
    .card-box {
        width: 340px;
        height: 160px;
    }

    .add-new-entry {
        margin-left: -30px !important;
    }
}

@media (min-width: 1400px) and (max-width: 1449px) {
    .card-box {
        width: 350px;
    }
}

@media (min-width: 1450px) and (max-width: 1499px) {
    .card-box {
        width: 370px;
    }
}

@media (min-width: 1500px) and (max-width: 1549px) {
    .card-box {
        width: 390px;
    }
}

@media (min-width: 1550px) and (max-width: 1599px) {
    .card-box {
        width: 400px;
    }
}

@media (min-width: 1600px) and (max-width: 1649px) {
    .card-box {
        width: 430px;
    }
}

@media (min-width: 1650px) and (max-width: 1700px) {
    .card-box {
        width: 440px;
    }
}

@media (min-width: 1701px) and (max-width: 1749px) {
    .card-box {
        width: 455px;
    }
}

@media (min-width: 1750px) and (max-width: 1799px) {
    .card-box {
        width: 470px;
    }
}

@media (min-width: 1800px) and (max-width: 1849px) {
    .card-box {
        width: 490px;
    }
}

@media (min-width: 1850px) and (max-width: 1899px) {
    .card-box {
        width: 500px;
    }
}

@media (min-width: 1900px) {
    .card-box {
        width: 520px !important;
    }
}