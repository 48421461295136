.fixed-header-sticky {
  .loading-flex {
    display: flex;
    gap: 2rem;

    .select-new-entry {
      width: 250px;
    }
  }

  .total-items {
    padding: 20px;
    border-radius: 5px !important;
    color: snow !important;
    text-align: right !important;
  }

  .header-grid-flex {
    display: flex;
    justify-content: space-between;
  }

  .header-text-fontSize {
    font-size: 2.5rem;
  }

  .total-item-first {
    background-color: #455785;
  }

  .total-item-second {
    background-color: #025e73;
  }

  .total-item-third {
    background-color: #4caba6;
  }

  .total-item-fourth {
    background-color: #a1a28e;
  }
}

.align-center {
  text-align: center !important;
  padding: 25px 0;
}

.button-disabled {
  background: #ccc !important;
  color: #666 !important;
  border: 1px solid #ccc !important;
}

.add-new-entry {
  color: #fff !important;
  text-decoration: none;
  width: max-content;
  font-weight: bold !important;
  font-size: 16px !important;
  height: 36px !important;
  background-color: #444CF7 !important;
}

.summary-table-spin-element {
  width: 100%;
  text-align: center;
  padding: 35px 0;
}

.top-spacer {
    margin-top: 10px;
}

@media (max-width: 576px) {
  .header-scroll-horizontal {
    overflow-x: scroll !important;
  }
  .total-items {
    width: 310px !important;
    position: relative;

    &.total-item-second {
      left: 260px !important;
    }

    &.total-item-third {
      left: 520px !important;
    }

    &.total-item-fourth {
      left: 780px !important;
    }
  }
}

@media (min-width: 577px) and (max-width: 600px) {
  .header-scroll-horizontal {
    overflow-x: scroll !important;
  }
  .total-items {
    width: 310px !important;
    position: relative;

    &.total-item-second {
      left: 260px !important;
    }

    &.total-item-third {
      left: 520px !important;
    }

    &.total-item-fourth {
      left: 780px !important;
    }
  }
}

@media (min-width: 600px) and (max-width: 650px) {
  .header-scroll-horizontal {
    overflow-x: scroll !important;
  }
  .total-items {
    width: 310px !important;
    position: relative;

    &.total-item-second {
      left: 240px !important;
    }

    &.total-item-third {
      left: 480px !important;
    }

    &.total-item-fourth {
      left: 720px !important;
    }
  }
}

@media (min-width: 650px) and (max-width: 700px) {
  .header-scroll-horizontal {
    overflow-x: scroll !important;
  }
  .total-items {
    width: 310px !important;
    position: relative;

    &.total-item-second {
      left: 225px !important;
    }

    &.total-item-third {
      left: 450px !important;
    }

    &.total-item-fourth {
      left: 675px !important;
    }
  }
}

@media (min-width: 700px) and (max-width: 750px) {
  .header-scroll-horizontal {
    overflow-x: scroll !important;
  }
  .total-items {
    width: 310px !important;
    position: relative;

    &.total-item-second {
      left: 210px !important;
    }

    &.total-item-third {
      left: 420px !important;
    }

    &.total-item-fourth {
      left: 630px !important;
    }
  }
}

@media (min-width: 750px) and (max-width: 800px) {
  .header-scroll-horizontal {
    overflow-x: scroll !important;
  }
  .total-items {
    width: 310px !important;
    position: relative;

    &.total-item-second {
      left: 200px !important;
    }

    &.total-item-third {
      left: 400px !important;
    }

    &.total-item-fourth {
      left: 600px !important;
    }
  }
}

@media (min-width: 800px) and (max-width: 850px) {
  .header-scroll-horizontal {
    overflow-x: scroll !important;
  }
  .total-items {
    width: 310px !important;
    position: relative;

    &.total-item-second {
      left: 190px !important;
    }

    &.total-item-third {
      left: 380px !important;
    }

    &.total-item-fourth {
      left: 570px !important;
    }
  }
}

@media (min-width: 850px) and (max-width: 900px) {
  .header-scroll-horizontal {
    overflow-x: scroll !important;
  }
  .total-items {
    width: 310px !important;
    position: relative;

    &.total-item-second {
      left: 175px !important;
    }

    &.total-item-third {
      left: 350px !important;
    }

    &.total-item-fourth {
      left: 525px !important;
    }
  }
}

@media (min-width: 900px) and (max-width: 950px) {
  .header-scroll-horizontal {
    overflow-x: scroll !important;
  }
  .total-items {
    width: 310px !important;
    position: relative;

    &.total-item-second {
      left: 160px !important;
    }

    &.total-item-third {
      left: 320px !important;
    }

    &.total-item-fourth {
      left: 480px !important;
    }
  }
}

@media (min-width: 950px) and (max-width: 1000px) {
  .header-scroll-horizontal {
    overflow-x: scroll !important;
  }
  .total-items {
    width: 310px !important;
    position: relative;

    &.total-item-second {
      left: 150px !important;
    }

    &.total-item-third {
      left: 300px !important;
    }

    &.total-item-fourth {
      left: 450px !important;
    }
  }
}

@media (min-width: 1000px) and (max-width: 1050px) {
  .header-scroll-horizontal {
    overflow-x: scroll !important;
  }
  .total-items {
    width: 310px !important;
    position: relative;

    &.total-item-second {
      left: 140px !important;
    }

    &.total-item-third {
      left: 280px !important;
    }

    &.total-item-fourth {
      left: 420px !important;
    }
  }
}

@media (min-width: 1050px) and (max-width: 1100px) {
  .header-scroll-horizontal {
    overflow-x: scroll !important;
  }
  .total-items {
    width: 310px !important;
    position: relative;

    &.total-item-second {
      left: 125px !important;
    }

    &.total-item-third {
      left: 250px !important;
    }

    &.total-item-fourth {
      left: 375px !important;
    }
  }
}

@media (min-width: 1100px) and (max-width: 1150px) {
  .header-scroll-horizontal {
    overflow-x: scroll !important;
  }
  .total-items {
    width: 310px !important;
    position: relative;

    &.total-item-second {
      left: 110px !important;
    }

    &.total-item-third {
      left: 220px !important;
    }

    &.total-item-fourth {
      left: 330px !important;
    }
  }
}

@media (min-width: 1150px) and (max-width: 1200px) {
  .header-scroll-horizontal {
    overflow-x: scroll !important;
  }
  .total-items {
    width: 310px !important;
    position: relative;

    &.total-item-second {
      left: 100px !important;
    }

    &.total-item-third {
      left: 200px !important;
    }

    &.total-item-fourth {
      left: 300px !important;
    }
  }
}

@media (min-width: 1200px) and (max-width: 1250px) {
  .header-scroll-horizontal {
    overflow-x: scroll !important;
  }
  .total-items {
    width: 310px !important;
    position: relative;

    &.total-item-second {
      left: 90px !important;
    }

    &.total-item-third {
      left: 180px !important;
    }

    &.total-item-fourth {
      left: 270px !important;
    }
  }
}

@media (min-width: 1250px) and (max-width: 1300px) {
  .header-scroll-horizontal {
    overflow-x: scroll !important;
  }
  .total-items {
    width: 310px !important;
    position: relative;

    &.total-item-second {
      left: 75px !important;
    }

    &.total-item-third {
      left: 150px !important;
    }

    &.total-item-fourth {
      left: 225px !important;
    }
  }
}

@media (min-width: 1300px) and (max-width: 1350px) {
  .header-scroll-horizontal {
    overflow-x: scroll !important;
  }
  .total-items {
    width: 310px !important;
    position: relative;

    &.total-item-second {
      left: 65px !important;
    }

    &.total-item-third {
      left: 130px !important;
    }

    &.total-item-fourth {
      left: 195px !important;
    }
  }
}

@media (min-width: 1350px) and (max-width: 1400px) {
  .header-scroll-horizontal {
    overflow-x: scroll !important;
  }
  .total-items {
    width: 310px !important;
    position: relative;

    &.total-item-second {
      left: 50px !important;
    }

    &.total-item-third {
      left: 100px !important;
    }

    &.total-item-fourth {
      left: 150px !important;
    }
  }
}

@media (min-width: 1400px) and (max-width: 1450px) {
  .header-scroll-horizontal {
    overflow-x: scroll !important;
  }
  .total-items {
    width: 310px !important;
    position: relative;

    &.total-item-second {
      left: 40px !important;
    }

    &.total-item-third {
      left: 80px !important;
    }

    &.total-item-fourth {
      left: 120px !important;
    }
  }
}

@media (min-width: 1450px) and (max-width: 1500px) {
  .header-scroll-horizontal {
    overflow-x: scroll !important;
  }
  .total-items {
    width: 310px !important;
    position: relative;

    &.total-item-second {
      left: 30px !important;
    }

    &.total-item-third {
      left: 60px !important;
    }

    &.total-item-fourth {
      left: 90px !important;
    }
  }
}
