.summary-wrapper {
  display: contents !important;

  .summary-text {
    color: #353535;
    font-size: 1.5rem;
  }

  .line1 {
    height: 2px;
    width: 100%;
    background: #cbcaca;
    position: relative;
  }

  .line1:before {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    background: #cbcaca;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
  }

  .line1:after {
    right: 0;
  }
}
