.pending-client-bill {
  padding: 15px !important;
}

.select-new-entry {
  position: relative;
}

.select-button-height {
  width: 200px !important;
}

.right-align {
  text-align: right !important;
}

.pagination-box {
  padding: 20px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 99999;
  background-color: #eaebf0 !important;
}

.back-new-entry {
  color: #fff !important;
  width: max-content !important;
  border: 1px solid #444cf7 !important;
  background-color: #444cf7 !important;
  font-weight: bold !important;
  position: relative;
}

.search-button-area {
  text-align: right;

  button {
    margin: 0 5px 0;
  }
}

.filter-new-entry {
  color: #444cf7 !important;
  border: 1px solid #444cf7 !important;
  font-weight: bold !important;
  position: relative;
}

.gridmargintop20 {
  margin-top: 0px !important;
}

.gridmarginBottom20 {
  margin-top: -16px !important;
}

.client-bill-number-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 0 10px;
}

.client-bill-wrappper {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
}

.client-bill-number {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;

  .hr-client-box {
    padding: 8px 15px;
    background-color: #d2d4fd;
    border-radius: 5px;
  }
}

.total-items {
  width: 400px !important;
  padding: 20px;
  border-radius: 5px !important;
  color: snow !important;
  margin: 10px 0 !important;
  text-align: right !important;
}

.header-grid-flex {
  display: flex;
  justify-content: space-between;
}

.header-text-fontSize {
  font-size: 2.5rem;
}

.total-item-first {
  background-color: #303a81;
}

.total-item-second {
  background-color: #7174ab;
}

.total-item-third {
  background-color: #7b4857;
}

.total-item-fourth {
  background-color: #90305a;
}

.submit-button-area {
  margin: 15px 0;
  text-align: center;

  .submit-outline-button {
    color: #444cf7;
    border-color: #444cf7;
    font-size: 16px !important;
    height: 36px !important;
    font-weight: bold !important;
  }

  .submit-button {
    background-color: #444cf7;
    font-size: 16px !important;
    height: 36px !important;
    font-weight: bold !important;
  }

  .submit-button:hover {
    background-color: #444cf7;
    font-size: 16px !important;
    height: 36px !important;
    font-weight: bold !important;
  }

  button {
    margin: 0 5px 0;
  }
}

@media (max-width: 576px) {
  .right-align {
    text-align: left !important;
  }

  .back-new-entry {
    margin-top: 20px !important;
  }

  .select-new-entry {
    margin-bottom: 0 !important;
  }

  .select-button-height {
    width: 100% !important;
  }

  .gridmarginBottom20 {
    margin: 0 !important;
  }

  .client-bill-number {
    margin-top: 10px !important;
  }

  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 230px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 230px !important;
    }

    &.total-item-third {
      left: 460px !important;
    }

    &.total-item-fourth {
      left: 690px !important;
    }
  }

  .header-text-fontSize {
    font-size: 22px;
  }
}

@media (min-width: 577px) and (max-width: 650px) {
  .right-align {
    text-align: left !important;
  }

  .back-new-entry {
    margin-top: 20px !important;
  }

  .select-new-entry {
    margin-bottom: 0 !important;
  }

  .select-button-height {
    width: 80% !important;
  }

  .gridmarginBottom20 {
    margin: 0 !important;
  }

  .client-bill-number {
    margin-top: 10px !important;
  }

  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 230px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 220px !important;
    }

    &.total-item-third {
      left: 440px !important;
    }

    &.total-item-fourth {
      left: 660px !important;
    }
  }

  .header-text-fontSize {
    font-size: 22px;
  }
}

@media (min-width: 650px) and (max-width: 700px) {
  .right-align {
    text-align: left !important;
  }

  .back-new-entry {
    margin-top: 20px !important;
  }

  .select-button-height {
    width: 80% !important;
  }

  .select-new-entry {
    margin-bottom: 0 !important;
  }

  .gridmarginBottom20 {
    margin: 0 !important;
  }

  .client-bill-number {
    margin-top: 10px !important;
  }

  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 230px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 210px !important;
    }

    &.total-item-third {
      left: 420px !important;
    }

    &.total-item-fourth {
      left: 630px !important;
    }
  }

  .header-text-fontSize {
    font-size: 22px;
  }
}

@media (min-width: 700px) and (max-width: 750px) {
  .right-align {
    text-align: left !important;
  }

  .back-new-entry {
    margin-top: 20px !important;
  }


  .header-text-fontSize {
    font-size: 22px;
  }


  .select-button-height {
    width: 80% !important;
  }

  .select-new-entry {
    margin-bottom: 0 !important;
  }

  .gridmarginBottom20 {
    margin: 0 !important;
  }

  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 230px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 210px !important;
    }

    &.total-item-third {
      left: 420px !important;
    }

    &.total-item-fourth {
      left: 630px !important;
    }
  }

  .header-text-fontSize {
    font-size: 22px;
  }
}

@media (min-width: 750px) and (max-width: 800px) {
  .right-align {
    text-align: left !important;
  }

  .back-new-entry {
    margin-top: 20px !important;
  }

  .select-new-entry {
    margin-bottom: 0 !important;
  }

  .select-button-height {
    width: 80% !important;
  }


  .gridmarginBottom20 {
    margin: 0 !important;
  }

  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 230px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 205px !important;
    }

    &.total-item-third {
      left: 410px !important;
    }

    &.total-item-fourth {
      left: 615px !important;
    }
  }

  .header-text-fontSize {
    font-size: 22px;
  }
}

@media (min-width: 800px) and (max-width: 850px) {
  .right-align {
    text-align: left !important;
  }

  .back-new-entry {
    margin-top: 20px !important;
  }

  .select-button-height {
    width: 80% !important;
  }

  .select-new-entry {
    margin-bottom: 0 !important;
  }

  .gridmarginBottom20 {
    margin: 0 !important;
  }

  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 230px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 205px !important;
    }

    &.total-item-third {
      left: 410px !important;
    }

    &.total-item-fourth {
      left: 615px !important;
    }
  }

  .header-text-fontSize {
    font-size: 22px;
  }
}

@media (min-width: 850px) and (max-width: 900px) {
  .right-align {
    text-align: left !important;
  }

  .select-button-height {
    width: 80% !important;
  }

  .select-new-entry {
    margin-bottom: 0 !important;
  }

  .gridmarginBottom20 {
    margin: 0 !important;
  }

  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 230px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 200px !important;
    }

    &.total-item-third {
      left: 400px !important;
    }

    &.total-item-fourth {
      left: 600px !important;
    }
  }

  .header-text-fontSize {
    font-size: 22px;
  }
}

@media (min-width: 900px) and (max-width: 950px) {
  .select-new-entry {
    left: -75px !important;
  }

  .filter-new-entry {
    left: 80px !important;
  }

  .select-button-height {
    width: 80% !important;
  }

  .gridmarginBottom20 {
    margin: 0 !important;
  }

  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 230px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 140px !important;
    }

    &.total-item-third {
      left: 280px !important;
    }

    &.total-item-fourth {
      left: 420px !important;
    }
  }

  .header-text-fontSize {
    font-size: 22px;
  }
}

@media (min-width: 950px) and (max-width: 1000px) {
  .select-new-entry {
    left: -75px !important;
  }

  .filter-new-entry {
    left: 85px !important;
  }

  .select-button-height {
    width: 150px !important;
  }

  .gridmarginBottom20 {
    margin: 0 !important;
  }

  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 230px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 130px !important;
    }

    &.total-item-third {
      left: 260px !important;
    }

    &.total-item-fourth {
      left: 390px !important;
    }
  }

  .header-text-fontSize {
    font-size: 22px;
  }
}

@media (min-width: 1000px) and (max-width: 1050px) {
  .select-new-entry {
    left: -50px !important;
  }

  .filter-new-entry {
    left: 100px !important;
  }

  .select-button-height {
    width: 150px !important;
  }

  .gridmarginBottom20 {
    margin: 0 !important;
  }

  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 240px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 130px !important;
    }

    &.total-item-third {
      left: 260px !important;
    }

    &.total-item-fourth {
      left: 390px !important;
    }
  }

  .header-text-fontSize {
    font-size: 22px;
  }
}

@media (min-width: 1050px) and (max-width: 1100px) {
  .select-new-entry {
    left: -40px !important;
  }

  .filter-new-entry {
    left: 110px !important;
  }

  .select-button-height {
    width: 150px !important;
  }

  .gridmarginBottom20 {
    margin-top: 0 !important;
  }

  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 245px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 130px !important;
    }

    &.total-item-third {
      left: 260px !important;
    }

    &.total-item-fourth {
      left: 390px !important;
    }
  }

  .header-text-fontSize {
    font-size: 22px;
  }
}

@media (min-width: 1100px) and (max-width: 1150px) {
  .select-new-entry {
    left: 15px !important;
  }

  .filter-new-entry {
    left: 135px !important;
  }

  .select-button-height {
    width: 130px !important;
  }

  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 250px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 130px !important;
    }

    &.total-item-third {
      left: 260px !important;
    }

    &.total-item-fourth {
      left: 390px !important;
    }
  }

  .header-text-fontSize {
    font-size: 22px;
  }
}

@media (min-width: 1150px) and (max-width: 1200px) {
  .select-new-entry {
    left: -10px !important;
  }

  .filter-new-entry {
    left: 145px !important;
  }

  .select-button-height {
    width: 170px !important;
  }

  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 260px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 130px !important;
    }

    &.total-item-third {
      left: 260px !important;
    }

    &.total-item-fourth {
      left: 390px !important;
    }
  }

  .header-text-fontSize {
    font-size: 22px;
  }
}

@media (min-width: 1200px) and (max-width: 1250px) {
  .select-new-entry {
    left: -100px !important;
  }

  .total-items {
    width: 220px !important;
  }

  .header-text-fontSize {
    font-size: 22px;
  }
}

@media (min-width: 1250px) and (max-width: 1300px) {
  .select-new-entry {
    left: -80px !important;
  }

  .total-items {
    width: 220px !important;
  }

  .header-text-fontSize {
    font-size: 20px;
  }
}

@media (min-width: 1300px) and (max-width: 1350px) {
  .select-new-entry {
    left: -70px !important;
  }

  .total-items {
    width: 240px !important;
  }

  .header-text-fontSize {
    font-size: 22px;
  }
}

@media (min-width: 1350px) and (max-width: 1400px) {
  .select-new-entry {
    left: -50px !important;
  }

  .total-items {
    width: 255px !important;
  }

  .header-text-fontSize {
    font-size: 22px;
  }
}

@media (min-width: 1400px) and (max-width: 1450px) {
  .select-new-entry {
    left: -30px !important;
  }

  .total-items {
    width: 265px !important;
  }

  .header-text-fontSize {
    font-size: 22px;
  }
}

@media (min-width: 1450px) and (max-width: 1500px) {
  .select-new-entry {
    left: -15px !important;
  }

  .total-items {
    width: 280px !important;
  }

  .header-text-fontSize {
    font-size: 22px;
  }
}

@media (min-width: 1500px) and (max-width: 1550px) {
  .select-new-entry {
    left: 0px !important;
  }

  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 330px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 30px !important;
    }

    &.total-item-third {
      left: 60px !important;
    }

    &.total-item-fourth {
      left: 90px !important;
    }
  }
}

@media (min-width: 1550px) and (max-width: 1600px) {
  .select-new-entry {
    left: 15px !important;
  }

  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 330px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 20px !important;
    }

    &.total-item-third {
      left: 40px !important;
    }

    &.total-item-fourth {
      left: 60px !important;
    }
  }
}

@media (min-width: 1600px) and (max-width: 1650px) {
  .select-new-entry {
    left: 35px !important;
  }

  .headerMarginTop {
    overflow-x: scroll !important;
  }
}

@media (min-width: 1650px) and (max-width: 1700px) {
  .select-new-entry {
    left: 50px !important;
  }

  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 330px !important;
  }
}

@media (min-width: 1700px) and (max-width: 1750px) {
  .select-new-entry {
    left: 70px !important;
  }

  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 345px !important;
  }
}

@media (min-width: 1750px) and (max-width: 1800px) {
  .select-new-entry {
    left: 85px !important;
  }

  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 355px !important;
  }
}

@media (min-width: 1800px) and (max-width: 1850px) {
  .select-new-entry {
    left: 100px !important;
  }

  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 370px !important;
  }
}

@media (min-width: 1850px) and (max-width: 1900px) {
  .select-new-entry {
    left: 110px !important;
  }

  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 380px !important;
  }
}

@media (min-width: 1900px) and (max-width: 1950px) {
  .select-new-entry {
    left: 130px !important;
  }
}

@media (min-width: 1950px) and (max-width: 2000px) {
  .select-new-entry {
    left: 150px !important;
  }
}