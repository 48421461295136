.edit-unloading-padding {
  padding: 15px !important;
}

.grid-space {
  margin-bottom: 15px !important;
}

.action-button {
  justify-content: end;
}

.remark-input {
  border: none !important;
}

.grid-input-space {
  margin-top: 8px !important;
}

.agency-details-margin {
  padding: 0 15px !important;
}

.disabled-input {
  color: #666 !important;

  span {
    color: #666 !important;
  }
  
  input {
    color: #666 !important;
  }
}


.add-new-entry {
  color: #fff;
  text-decoration: none;
  width: 100%;
  font-weight: bold !important;
  font-size: 16px !important;
  height: 36px !important;
  background-color: #444CF7 !important;
}

.submit-button {
  color: #fff;
  text-decoration: none;
  font-weight: bold !important;
  font-size: 16px !important;
  height: 36px !important;
  background-color: #444CF7 !important;
}

.form-input-box {
  width: 100%;
  color: black;
  border-radius: 5px !important;
  padding: 5px 10px;
  margin-bottom: 5px;
  height: 40px;
}

.form-select-box {
  color: black;
  border-radius: 5px !important;
  height: 40px;
}


.submit-button-area {
  margin: 15px 0;
  text-align: center;

  button {
    margin: 0 5px 0;
  }
}

@media (min-width: 1400px) and (max-width: 1600px) {
  .add-new-entry {
    margin-left: -50px !important;
  }
}

@media (min-width: 1200px) and (max-width: 1450px) {
  .add-new-entry {
    margin-left: -50px !important;
  }
}