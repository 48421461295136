* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

* {
  &::-webkit-scrollbar {
    width: 0.2rem;
    &-thumb {
      background-color: #0000007c;
      border-radius: 1rem;
    }
  }

  &::-webkit-scrollbar:horizontal {
    height: 0.2rem;
    width: auto;
    &-thumb:horizontal {
      background-color: #0000007c;
      border-radius: 1rem;
    }
  }
}


.ant-card-body {
  display: flex;
  padding: 5px !important;
}

.ant-menu-item {
  padding-left: 20px !important;
}

.ant-menu-vertical {
  padding-left: 0px !important;
}

.ant-menu-vertical .ant-menu-item {
  width: 100% !important;
  margin-inline: 0 !important;
  margin-block: 0 !important;
  margin-block-end: 6px !important;
}

.ant-menu .ant-menu-item {
  border-radius: 0 !important;
}

.ant-menu-vertical li .ant-menu-submenu-title{
  padding-inline: 20px !important;
}

.ant-menu-vertical .ant-menu-submenu-title {
  width: 100% !important;
  margin-inline: 0 !important;
  margin-block: 0 !important;
  margin-block-end: 6px !important;
}

.ant-menu .ant-menu-submenu-title {
  border-radius: 0 !important;
}

.ant-menu-sub {
    .ant-menu-title-content {
      position: relative !important;
    }
}

.ant-menu-item {
  .ant-menu-title-content {
    margin-left: 15px !important;
  }
}

.ant-menu-submenu-title .ant-menu-title-content {
  position: absolute !important;
  margin-left: 15px !important;
}

.ant-menu-light .ant-menu-submenu-selected >.ant-menu-submenu-title {
  background-color: rgba(116, 113, 113, 0.374) !important;
  color: #000 !important;
}

.ant-menu-vertical.ant-menu-root li:first-child .ant-menu-submenu-title span{
  display: block;
  margin: -50px 45px !important;
}

.ant-menu-submenu {
  background-color: #fff !important;
}

.ant-menu .ant-menu-sub .ant-menu-inline {
  background-color: #fff !important;
}

.ant-menu-item-selected {
  background-color: #fff !important;
  color: #000 !important;
}

.ant-upload-list-item {
  margin-bottom: 10px !important;
}

a.backButton {
  color: #000;
}

.ant-upload-list-item-actions > a {
  pointer-events: visible !important;
  opacity: 1 !important;
}

.ant-spin-nested-loading {
  margin: 0 auto !important;
}

.ant-spin-nested-loading .ant-spin-container::after {
  background: transparent !important;
}

.ant-spin {
  color: #000 !important;
}

.ant-spin .ant-spin-dot-item {
  background-color: #000 !important;
}

.ant-tooltip .ant-tooltip-inner {
  font-size: 25px !important;
  font-weight: bold !important;
  background-color: #000 !important;
}

.highcharts-credits {
  display: none !important;
}
