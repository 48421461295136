.edit-vehicle-padding {
    padding: 15px !important;
}

.grid-space {
    padding: 0 15px !important;
}

.grid-input-space {
    margin-top: 8px !important;
}

.remark-input {
    border: none !important;
}

.position-relative {
    position: relative;
}

.width88 {
    width: 88% !important;
}

.error-message-popup {font-size: 15px;}

.download_vehicle_details_pdf {
  border: none;
  background: transparent;
  cursor: pointer;
  transition: 0.3s;
}

.download_vehicle_details_pdf:hover {
  opacity: 0.7;
}

.back-button {
    color: #fff;
    text-decoration: none;
    width: 100%;
    font-weight: bold !important;
    font-size: 16px !important;
    height: 36px !important;
    background-color: #444CF7 !important;
}

.action-button {
    justify-content: end;
}

.submit-button {
    color: #fff;
    text-decoration: none;
    font-weight: bold !important;
    font-size: 16px !important;
    height: 36px !important;
    background-color: #444CF7 !important;
}

.form-input-box {
    width: 100%;
    color: black;
    border-radius: 5px !important;
    padding: 5px 10px;
    margin-bottom: 5px;
    height: 40px;
}

.submit-button-area {
    margin-top: 15px;
    text-align: center;

    button {
        margin: 0 5px 0;
    }
}

.model-missing-challan {
    .missing-challan-heading {
        font-weight: bold;
        border-bottom: 1px solid #adadad;
        padding-bottom: 10px;
    }

    .missing-tags {
        padding: 10px 0 50px 0;

        .tag-challan {
            font-size: 20px;
            padding: 4px 10px !important;
            margin-top: 5px;
            cursor: pointer;

            &.selected {
                border: 1px solid #444CF7 !important;
                color: #444CF7 !important;
                box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.7);
            }
        }
    }

    .flex-items-missing {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #adadad;
        padding-top: 10px;

        .use-challan-no-btn {
            background-color: #444CF7 !important;
        }
    }
}

.vehicle-info-row {
    font-size: 16px;
    float: left;
    padding: 12px 0px;
    border-bottom: 1px solid #ccc;
}

.vehicle-info-row .label {
    font-weight: bold;
    color: #444CF7 !important;
    padding: 8px !important;
    margin-right: 10px !important;
}

.clear-both {
    clear: both;
}

.input-field-challan-button {
    display: flex;
    gap: 8px !important;
    position: absolute;
    top: 38px !important;
    right: 0 !important;

    .stop-missing-icon {
        background-color: #444CF7 !important;
        border: 1px solid #444CF7;
        padding: 3px 14px;
        border-radius: 5px;
        cursor: pointer;
        color: #fff !important;
        transition: 0.3s;
    }

    .stop-missing-icon:hover {
        opacity: 0.8;
    }
}

@media (max-width: 576px) {
    .card-box {
        width: 300px;
    }

    .back-button {
        margin-top: 10px !important;
    }
}

@media (min-width: 577px) and (max-width: 699px) {
    .card-box {
        width: 100%;
    }

    .back-button {
        margin-top: 10px !important;
    }
}

@media (min-width: 700px) and (max-width: 899px) {
    .card-box {
        width: 100%;
    }

    .back-button {
        margin-top: 10px !important;
    }
}

@media (min-width: 900px) and (max-width: 950px) {
    .card-box {
        width: 300px;
        height: 160px;
    }
}

@media (min-width: 951px) and (max-width: 1000px) {
    .card-box {
        width: 330px;
        height: 160px;
        margin-right: 10px;
    }
}

@media (min-width: 1001px) and (max-width: 1050px) {
    .card-box {
        width: 350px;
        height: 160px;
        margin-right: 10px;
    }
}

@media (min-width: 1051px) and (max-width: 1100px) {
    .card-box {
        width: 370px;
        height: 160px;
        margin-right: 10px;
    }
}

@media (min-width: 1200px) and (max-width: 1299px) {
    .card-box {
        width: 290px;
        height: 160px;
    }

    .back-button {
        margin-left: -30px !important;
    }
}

@media (min-width: 1300px) and (max-width: 1349px) {
    .card-box {
        width: 330px;
        height: 160px;
    }

    .back-button {
        margin-left: -30px !important;
    }
}

@media (min-width: 1350px) and (max-width: 1399px) {
    .card-box {
        width: 340px;
        height: 160px;
    }

    .back-button {
        margin-left: -30px !important;
    }
}

@media (min-width: 1400px) and (max-width: 1449px) {
    .card-box {
        width: 350px;
    }
}

@media (min-width: 1450px) and (max-width: 1499px) {
    .card-box {
        width: 370px;
    }
}

@media (min-width: 1500px) and (max-width: 1549px) {
    .card-box {
        width: 390px;
    }
}

@media (min-width: 1550px) and (max-width: 1599px) {
    .card-box {
        width: 400px;
    }
}

@media (min-width: 1600px) and (max-width: 1649px) {
    .card-box {
        width: 430px;
    }
}

@media (min-width: 1650px) and (max-width: 1700px) {
    .card-box {
        width: 440px;
    }
}

@media (min-width: 1701px) and (max-width: 1749px) {
    .card-box {
        width: 455px;
    }
}

@media (min-width: 1750px) and (max-width: 1799px) {
    .card-box {
        width: 470px;
    }
}

@media (min-width: 1800px) and (max-width: 1849px) {
    .card-box {
        width: 490px;
    }
}

@media (min-width: 1850px) and (max-width: 1899px) {
    .card-box {
        width: 500px;
    }
}

@media (min-width: 1900px) {
    .card-box {
        width: 520px !important;
    }
}