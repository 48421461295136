.copy-icon {
    cursor: pointer;
}

.summary-table-spin-element {
  margin-top: 5%;
  margin-left: 50%;
  margin-bottom: 5%;
}

.export-submit-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 30px 0;
  
    .export-button {
      border-color: #444cf7 !important;
      color: #444cf7 !important;
    }
  
    .submit-button {
      background-color: #444cf7 !important;
    }
  }
  
  .pagination-position {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px !important;
  }
  