.view-excavator-padding {
  padding: 15px !important;
}

.upload-file-button {
  background-color: #444CF7 !important;
}

.header-fixed-excavator {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #fff;
  padding: 0 20px;
  border-bottom: 1px solid #f1f1f1;
}

.headerMarginTop {
  margin-top: 6px !important;
}

.gridmargintop10 {
  margin-top: 10px !important;
}

.add-new-entry {
  color: #fff;
  text-decoration: none;
  width: max-content !important;
  font-weight: bold !important;
  font-size: 16px !important;
  height: 36px !important;
  background-color: #444CF7 !important;
}

.alert-bottom-border {
  border-bottom: 1px solid #dcdbdb;

  .alert-text {
    text-align: center;
    color: #00072B;
    font-weight: bold;
  }
}

.alert-description {
  text-align: center;
  padding: 25px 0;
}

.generated-bill-ribbon {
  cursor: default;

  p {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #e06b00;
    padding: 2px 10px;
    border-top-left-radius: 47px;
    border-bottom-left-radius: 47px;
    margin: 18px -1px;
    color: #fff;
    font-weight: bold;

    a {
      color: snow;
    }
  }
}

.generated-entries-modal-table {
  border: 1px solid #dcdbdb;
  border-radius: 5px;

  .generated-entries-model-heading {
    border-bottom: 1px solid #dcdbdb;
    background-color: #fafafa;
    display: flex;
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 10px 0;
  }

  .generated-entries-model-description {
    padding: 10px 0;
  }
}

.filter-new-entry {
  color: #444CF7 !important;
  border: 1px solid #444CF7 !important;
  font-weight: bold !important;
}

.search-button-area {
  text-align: right;

  button {
    margin: 0 5px 0;
  }
}

.import-new-entry {
  color: #444CF7 !important;
  border: 1px solid #444CF7 !important;
  font-weight: bold !important;
}

.add-new-filter-entry {
  font-weight: bold !important;
  background-color: #444CF7 !important;
}

.upload-file-entry {
  color: #444CF7 !important;
  border: 1px solid #444CF7 !important;
  width: max-content !important;
  margin-bottom: 30px !important;
}

.click-here-link {
  color: #444CF7 !important;
}

.model-buttons {
  margin: 0 10px !important;
}


.action-button {
  justify-content: end;
}

.card-box {
  width: 400px;
  min-height: 150px;
  box-shadow: 0 0px 6px 2px rgba(124, 103, 103, 0.34);
  margin: 0 0 30px 0;

  .card-content {
    display: flex;
  }

  .AgencyDetails {
    width: 100%;
    padding: 5px;
  }

  .AgencyDetailsValue {
    margin-left: 5px;
    font-weight: bold;
  }

  .card-button {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    a {
      color: black;
    }

    ul.action-items {
      list-style-type: none;

      li {
        list-style-type: none;
        margin: 0;
        padding: 0;
        height: 32px;
      }
    }

    .card-icons {
      border: 1px solid rgba(140, 136, 136, 0.456);
      width: 30px;
      padding: 7px;
      border-radius: 5px;
      transition: all 0.2s ease-in-out;

      &:hover {
        box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
      }
    }

    .card-icons-delete {
      color: #ff4d4f;
    }

    .list-item-icon {
      min-width: max-content !important;
      padding: 0;
    }

    .card-icons-images {
      border: 1px solid rgba(140, 136, 136, 0.456);
      width: 35px !important;
      padding: 4px;
      border-radius: 5px;
    }
  }
}

.agency-details-margin {
  padding: 0 15px !important;
}

.no-data-available {
  padding: 20px 20px;
  font-size: 18px;
}

.sub-headings {
  font-size: 18px;
  padding: 10px 0;
  font-weight: bold;
  text-decoration: underline;
}

.space-between-rows {
  margin-bottom: 10px;
  background-color: #f1f1f1;
  padding: 25px;
  border-radius: 5px;
}

.ant-form-item .ant-form-item-row .ant-form-item-label {
  min-width: 100%;
  text-align: left;
}

.show-agency-values {
  width: max-content;
  min-width: 100%;
  background-color: #f1f1f1;
  color: black;
  border-radius: 5px !important;
  padding: 5px 10px;
  margin-bottom: 5px;
}

.form-input-box {
  width: 100%;
  color: black;
  border-radius: 5px !important;
  padding: 5px 10px;
  margin-bottom: 5px;
  height: 40px;
}

.form-select-box {
  color: black;
  border-radius: 5px !important;
  height: 40px;
}

.agency-project-rate-display {
  display: flex;
}

.sub-module-create-agency {
  border: 1px solid #d1d1d1;
  padding: 20px;
  border-radius: 5px;
}

.marginBottom15 {
  margin-bottom: 15px;
}

.add-more {
  margin: 15px 0 0;
  text-align: right;
}

.loading-rate-element {
  width: 100%;
  display: flex;
}

.delete-button-add-more {
  min-width: 32px !important;
}

.add-more-whole-grid {
  text-align: center;
}

.submit-button-area {
  margin-top: 15px;
  text-align: center;
}

.submit-button-area button {
  margin: 0 5px 0;
}

.submit-button-area button {
  margin: 0 5px 0;
}

.submit-button-area button {
  margin: 0 5px 0;
}

.remove-main-block-element {
  position: relative;
  text-align: right;
}

.remove-main-block-element button {
  position: absolute;
  right: -8px;
  bottom: -22px;
}

.input-error-message {
  color: #ff4d4f;
}

.input-error {
  border-color: #ff4d4f;
  box-shadow: 0 0px 2px #ff4d4f;
}

.pagination-box {
  padding: 50px 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.marginBottom20 {
  margin-bottom: 20px;
}

.marginBottom20 {
  margin-bottom: 20px;
}

.marginBottom20 {
  margin-bottom: 20px;
}

.marginTop20 {
  padding: 20px !important;
  background-color: #fbe2998e !important;
}

/** excel import errors modal css */
.error-import-modal-heading {
  font-size: 26px;
  text-transform: capitalize;
  padding-bottom: 10px;
  text-align: center;
}

.error-import-modal {
  height: auto;
  max-height: 70%;
}

.error-import-excel-display-data {
  height: auto;
  max-height: 500px;
  width: 100% !important;
  overflow-y: scroll;
}

.error-import-excel-display-data>table {
  border: 1px solid #ccc;
}

.error-import-excel-display-data>table th {
  background-color: #444CF7;
  color: #fff;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
}

.error-import-excel-display-data>table td {
  border: 1px solid #ccc;
  color: red;
  font-weight: bold;
}

.error-import-excel-display-data>table td table td {
  border: 1px solid transparent;
}

.error-import-excel-display-data ul {
  list-style-type: none;
  margin: 0 25px 0;
}

.error-import-excel-display-data ul li {
  list-style-type: square;
  line-height: 30px;
  color: red;
}

.total-items {
  width: 400px !important;
  padding: 20px;
  border-radius: 5px !important;
  color: snow !important;
  margin: 10px 0 !important;
  text-align: right !important;
}

.header-grid-flex {
  display: flex;
  justify-content: space-between;
}

.header-text-fontSize {
  font-size: 2.5rem;
}

.total-item-first {
  background-color: #455785;
}

.total-item-second {
  background-color: #4E6C54;
}

.total-item-third {
  background-color: #E0694E;
}

.total-item-fourth {
  background-color: #E0694E;
}

.search-new-entry,
.select-new-entry {
  width: 200px !important;
}

.form-select-div-select {
  position: relative;
  left: -90px !important;
}

.form-select-div-search {
  position: relative;
  left: -100px !important;
}

.content {
  padding: 50px;
}

@media (max-width: 275px) {
  .model-buttons {
    margin: 10px 0 !important;
  }
}

@media (min-width: 275px) and (max-width: 290px) {
  .model-buttons {
    margin: 0px 5px !important;
  }
}


@media (max-width: 576px) {
  .header-fixed-excavator {
    width: 100vw !important;
    margin-top: 10px !important;
  }

  .marginTop20 {
    width: 100vw !important;
  }

  .card-box {
    width: 300px;
  }

  .add-new-entry {
    margin-left: 80px !important;
  }

  .filter-new-entry {
    margin-left: 38px !important;
  }


  .select-new-entry,
  .search-new-entry {
    width: 200px !important;
  }

  .form-select-div-select {
    left: -170px !important;
    margin-top: -30px !important;
  }

  .form-select-div-search {
    left: 0px !important;
  }

  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 280px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 270px !important;
    }

    &.total-item-third {
      left: 540px !important;
    }
  }
}

@media (min-width: 577px) and (max-width: 699px) {
  .card-box {
    width: 100%;
  }

  .marginTop20 {
    width: 100vw !important;
  }

  .header-fixed-excavator {
    margin-top: 10px !important;
  }

  .add-new-entry {
    margin-left: 100px !important;
  }

  .filter-new-entry {
    margin-left: 48px !important;
  }

  .select-new-entry,
  .search-new-entry {
    width: 200px !important;
  }

  .form-select-div-select {
    left: -160px !important;
  }

  .form-select-div-search {
    left: 0px !important;
  }

  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 280px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 270px !important;
    }

    &.total-item-third {
      left: 540px !important;
    }
  }
}

@media (min-width: 700px) and (max-width: 750px) {
  .card-box {
    width: 100%;
  }

  .header-fixed-excavator {
    margin-top: 10px !important;
  }

  .add-new-entry {
    margin-left: 45px !important;
  }

  .filter-new-entry {
    margin-left: 20px !important;
  }

  .select-new-entry,
  .search-new-entry {
    width: 200px !important;
  }

  .form-select-div-select {
    left: -220px !important;
  }

  .form-select-div-search {
    left: 0px !important;
  }

  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 280px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 260px !important;
    }

    &.total-item-third {
      left: 520px !important;
    }
  }
}

@media (min-width: 750px) and (max-width: 800px) {
  .card-box {
    width: 100%;
  }

  .header-fixed-excavator {
    margin-top: 10px !important;
  }

  .select-new-entry,
  .search-new-entry {
    width: 200px !important;
  }

  .form-select-div-select {
    left: -250px !important;
  }

  .form-select-div-search {
    left: 0px !important;
  }

  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 280px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 255px !important;
    }

    &.total-item-third {
      left: 510px !important;
    }
  }
}

@media (min-width: 800px) and (max-width: 850px) {
  .card-box {
    width: 100%;
  }

  .header-fixed-excavator {
    margin-top: 10px !important;
  }

  .select-new-entry,
  .search-new-entry {
    width: 200px !important;
  }

  .form-select-div-select {
    left: -270px !important;
  }

  .form-select-div-search {
    left: 0px !important;
  }

  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 280px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 250px !important;
    }

    &.total-item-third {
      left: 500px !important;
    }
  }
}

@media (min-width: 850px) and (max-width: 900px) {
  .card-box {
    width: 100%;
  }

  .header-fixed-excavator {
    margin-top: 10px !important;
  }

  .select-new-entry,
  .search-new-entry {
    width: 200px !important;
  }

  .form-select-div-select {
    left: -300px !important;
  }

  .form-select-div-search {
    left: 0px !important;
  }

  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 280px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 250px !important;
    }

    &.total-item-third {
      left: 500px !important;
    }
  }
}

@media (min-width: 900px) and (max-width: 950px) {
  .card-box {
    width: 300px;
    height: 185px;
  }

  .add-new-entry {
    margin-left: -30px !important;
  }

  .filter-new-entry {
    margin-left: -61px !important;
  }

  .import-new-entry {
    margin-left: -90px !important;
  }

  .select-new-entry,
  .search-new-entry {
    width: 90px !important;
  }

  .form-select-div-select {
    left: -110px !important;
  }

  .form-select-div-search {
    left: 20px !important;
  }

  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 280px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 190px !important;
    }

    &.total-item-third {
      left: 380px !important;
    }
  }
}

@media (min-width: 951px) and (max-width: 1000px) {
  .card-box {
    width: 330px;
    height: 170px;
    margin-right: 10px;
  }

  .add-new-entry {
    margin-left: -20px !important;
  }

  .filter-new-entry {
    margin-left: -50px !important;
  }

  .import-new-entry {
    margin-left: -75px !important;
  }

  .select-new-entry,
  .search-new-entry {
    width: 120px !important;
  }

  .form-select-div-select {
    left: -120px !important;
  }

  .form-select-div-search {
    left: 20px !important;
  }

  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 280px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 180px !important;
    }

    &.total-item-third {
      left: 360px !important;
    }
  }
}

@media (min-width: 1001px) and (max-width: 1050px) {
  .card-box {
    width: 350px;
    height: 170px;
    margin-right: 10px;
  }

  .add-new-entry {
    margin-left: -10px !important;
  }

  .filter-new-entry {
    margin-left: -30px !important;
  }

  .import-new-entry {
    margin-left: -50px !important;
  }

  .select-new-entry,
  .search-new-entry {
    width: 150px !important;
  }

  .form-select-div-select {
    left: -115px !important;
  }

  .form-select-div-search {
    left: 10px !important;
  }

  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 280px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 170px !important;
    }

    &.total-item-third {
      left: 340px !important;
    }
  }
}

@media (min-width: 1051px) and (max-width: 1100px) {
  .card-box {
    width: 370px;
    height: 170px;
    margin-right: 10px;
  }

  .add-new-entry {
    margin-left: -10px !important;
  }

  .filter-new-entry {
    margin-left: -30px !important;
  }

  .import-new-entry {
    margin-left: -50px !important;
  }

  .select-new-entry,
  .search-new-entry {
    width: 150px !important;
  }

  .form-select-div-select {
    left: -110px !important;
  }

  .form-select-div-search {
    left: 10px !important;
  }

  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 280px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 165px !important;
    }

    &.total-item-third {
      left: 330px !important;
    }
  }
}

@media (min-width: 1100px) and (max-width: 1200px) {

  .filter-new-entry {
    margin-left: -15px !important;
  }

  .import-new-entry {
    margin-left: -25px !important;
  }

  .form-select-div-select {
    left: -125px !important;
  }

  .form-select-div-search {
    left: 20px !important;
  }

  .headerMarginTop {
    overflow-x: scroll !important;
  }

  .total-items {
    width: 280px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 160px !important;
    }

    &.total-item-third {
      left: 320px !important;
    }
  }
}

@media (min-width: 1200px) and (max-width: 1299px) {
  .card-box {
    width: 290px;
    height: 190px;
  }

  .add-new-entry {
    margin-left: -30px !important;
  }

  .filter-new-entry {
    margin-left: -90px !important;
  }

  .import-new-entry {
    margin-left: -150px !important;
  }

  .total-items {
    width: 280px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 60px !important;
    }

    &.total-item-third {
      left: 120px !important;
    }
  }

  .form-select-div-select {
    left: -320px !important;
  }

  .form-select-div-search {
    left: -290px !important;
  }
}

@media (min-width: 1300px) and (max-width: 1349px) {
  .card-box {
    width: 330px;
    height: 170px;
  }

  .add-new-entry {
    margin-left: -30px !important;
  }

  .filter-new-entry {
    margin-left: -80px !important;
  }

  .import-new-entry {
    margin-left: -130px !important;
  }

  .total-items {
    width: 280px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 40px !important;
    }

    &.total-item-third {
      left: 80px !important;
    }
  }

  .form-select-div-select {
    left: -280px !important;
  }

  .form-select-div-search {
    left: -350px !important;
  }
}

@media (min-width: 1350px) and (max-width: 1399px) {
  .card-box {
    width: 340px;
    height: 170px;
  }

  .add-new-entry {
    margin-left: -20px !important;
  }

  .filter-new-entry {
    margin-left: -60px !important;
  }

  .import-new-entry {
    margin-left: -100px !important;
  }

  .total-items {
    width: 280px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 25px !important;
    }

    &.total-item-third {
      left: 50px !important;
    }
  }

  .form-select-div-select {
    left: -250px !important;
  }

  .form-select-div-search {
    left: -310px !important;
  }
}

@media (min-width: 1400px) and (max-width: 1449px) {
  .card-box {
    width: 350px;
  }

  .add-new-entry {
    margin-left: -20px !important;
  }

  .filter-new-entry {
    margin-left: -60px !important;
  }

  .import-new-entry {
    margin-left: -100px !important;
  }

  .total-items {
    width: 280px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 15px !important;
    }

    &.total-item-third {
      left: 30px !important;
    }
  }

  .form-select-div-select {
    left: -240px !important;
  }

  .form-select-div-search {
    left: -300px !important;
  }
}

@media (min-width: 1450px) and (max-width: 1499px) {
  .card-box {
    width: 370px;
  }

  .add-new-entry {
    margin-left: -20px !important;
  }

  .filter-new-entry {
    margin-left: -60px !important;
  }

  .import-new-entry {
    margin-left: -100px !important;
  }

  .total-items {
    width: 280px !important;
  }

  .form-select-div-select {
    left: -230px !important;
  }

  .form-select-div-search {
    left: -280px !important;
  }
}

@media (min-width: 1500px) and (max-width: 1549px) {
  .card-box {
    width: 390px;
  }

  .add-new-entry {
    margin-left: -10px !important;
  }

  .filter-new-entry {
    margin-left: -40px !important;
  }

  .import-new-entry {
    margin-left: -70px !important;
  }

  .total-items {
    width: 290px !important;
  }

  .form-select-div-select {
    left: -200px !important;
  }

  .form-select-div-search {
    left: -240px !important;
  }
}

@media (min-width: 1550px) and (max-width: 1599px) {
  .card-box {
    width: 400px;
  }

  .filter-new-entry {
    margin-left: -30px !important;
  }

  .import-new-entry {
    margin-left: -60px !important;
  }

  .form-select-div-select {
    left: -180px !important;
  }

  .form-select-div-search {
    left: -220px !important;
  }

  .total-items {
    width: 300px !important;
  }
}

@media (min-width: 1600px) and (max-width: 1649px) {
  .card-box {
    width: 430px;
  }

  .filter-new-entry {
    margin-left: -30px !important;
  }

  .import-new-entry {
    margin-left: -60px !important;
  }

  .form-select-div-select {
    left: -170px !important;
  }

  .form-select-div-search {
    left: -200px !important;
  }

  .total-items {
    width: 320px !important;
  }
}

@media (min-width: 1650px) and (max-width: 1700px) {
  .card-box {
    width: 440px;
  }

  .filter-new-entry {
    margin-left: -30px !important;
  }

  .import-new-entry {
    margin-left: -60px !important;
  }

  .form-select-div-select {
    left: -170px !important;
  }

  .form-select-div-search {
    left: -200px !important;
  }

  .total-items {
    width: 330px !important;
  }
}

@media (min-width: 1701px) and (max-width: 1749px) {
  .card-box {
    width: 455px;
  }

  .total-items {
    width: 345px !important;
  }
}

@media (min-width: 1750px) and (max-width: 1799px) {
  .card-box {
    width: 470px;
  }

  .total-items {
    width: 355px !important;
  }
}

@media (min-width: 1800px) and (max-width: 1849px) {
  .card-box {
    width: 490px;
  }

  .total-items {
    width: 370px !important;
  }
}

@media (min-width: 1850px) and (max-width: 1899px) {
  .card-box {
    width: 500px;
  }

  .total-items {
    width: 380px !important;
  }
}


@media (min-width: 1900px) {
  .card-box {
    width: 520px !important;
  }
}