@mixin dashboard-border {
    border: 1px solid #cfcfcf;
    border-radius: 10px;
    width: 100%;
}

@mixin dashboard-heading {
    padding: 8px 15px;
    border-bottom: 1px solid #cfcfcf;
}

.lastUnloadingDate {
    font-weight: bold;
    font-style: italic;
    font-size: 15px;
    color: #385ca6;
}

.projectCompletedBeforeTime {
    font-weight: bold;
    color: #008d38;
    font-size: 18px;
    margin-left: 15px;
}

a.transportAgencyModelLink {
    color: #682a01;
    text-decoration: underline;
}

a.loadingAgencyModelLink {
    color: #4ca6ff;
    text-decoration: underline;
}

a.unloadingAgencyModelLink {
    color: #479036;
    text-decoration: underline;
}

a.petrolpumpAgencyModelLink {
    color: #ce9a02;
    text-decoration: underline;
}

.graphArea {
    min-height: 400px;
    vertical-align: middle;
}

.projectCompletedMessage {
    padding: 15px 25px;
    background-color: #008d38;
    color: #fff !important;
    font-size: 18px !important;
    margin: 0px 0px 10px 0;
    font-weight: bold;
}

.graphArea .spinStyle {
    margin: 10% 0;
    width: 100%;
}

.petrolPumpArea {
    min-height: 400px;
    vertical-align: middle;
}

.petrolPumpArea .spinStyle {
    margin: 150px 380px 150px 420px;
}

.dashboard-padding {
    padding: 15px !important;
}

/**  active / inactive agencies popup css starts   **/

.agency_heading {
    padding: 20px 0;
    font-size: 16px;
    text-transform: uppercase;
    text-decoration: underline;
}

.agency_display_ul {
    list-style-type:circle;
}

.agency_display_ul_li_inactive {
    background-color: rgb(173, 0, 0);
    min-width: 200px;
    width: auto;
    display: inline-block;
    margin: 0px 15px 15px 0;
    font-size: 15px;
    font-weight: bold;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px 0
}

.agency_display_ul_li_active {
    background-color: rgb(2, 158, 28);
    min-width: 200px;
    width: auto;
    display: inline-block;
    margin: 0px 15px 15px 0;
    font-size: 15px;
    font-weight: bold;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px 0
}

.box-border-dashboard {
    @include dashboard-border();

    .bill-wise-flex-item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .btn-selected {
            border-radius: 50px;
            padding: 0 12px;
            background-color: #444cf7;
            color: #fff;
        }

        .btn-selected-border {
            border-radius: 50px;
            padding: 0 12px;
            border: 1px solid #444cf7;
            color: #444cf7;
        }
    }

    .dashboard-top-box-heading {
        @include dashboard-heading();
    }
}

.flex-items {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
}

.flex-item-graph-li {
    height: 100%;
    display: flex;

    ul {
        margin: auto;
    }
}

.grand-total-drilldown {
    background-color: #ecedfe;
    text-align: right;
    padding: 10px;
    margin-bottom: 10px;
}

.total-items {
    width: 395px !important;
    padding: 20px;
    border-radius: 5px !important;
    color: snow !important;
    margin: 10px 0 !important;
    text-align: right !important;
    transition: all 0.3s ease-in-out;

    &.total-item-first {
        background-color: #385ca6;
    }

    &.total-item-second {
        background-color: #4e8da5;
    }

    &.total-item-third {
        background-color: #6d7a8b;
    }

    &.total-item-fourth {
        background-color: #5a90bf;
    }

    .header-grid-flex {
        display: flex;
        justify-content: space-between;

        .header-text-fontSize {
            font-size: 2.5rem;
        }
    }
}

.blank-day-text {
    background-color: #555555;
    padding: 0px 12px;
    border-radius: 50px;
}

.weight-wise-box-padding {
    padding: 20px 30px;

    p {
        padding: 10px 0;
    }

    .bottom-border-line {
        border-bottom: 1px solid #cfcfcf;
    }

    .avg-trip-box {
        border-radius: 10px;
        padding: 15px;

        h6 {
            background-color: #008d38;
            border-radius: 50px;
            width: max-content;
            margin: 0px auto;
            padding: 0px 15px;
        }

        p {
            text-align: center;
        }
    }

    .avg-trip-box-red {
        border-radius: 10px;
        padding: 15px;

        h6 {
            background-color: red;
            border-radius: 50px;
            width: max-content;
            margin: 0px auto;
            padding: 0px 15px;
        }

        p {
            text-align: center;
        }
    }
}

.ratio-box-padding {
    padding: 20px 30px;

    p {
        padding: 5.3px 0;
    }

    .bottom-border-line {
        border-bottom: 1px solid #cfcfcf;
    }
}

.fieldset-box {
    width: 100%;
    border: 1px solid #cfcfcf;
    border-radius: 10px;
    padding: 0 5px 0 15px;
}

.total-items-second {
    width: 390px !important;
    padding: 20px;
    border-radius: 5px !important;
    color: snow !important;
    margin: 10px 0 !important;
    text-align: right !important;
    transition: all 0.3s ease-in-out;
    border: 1px solid #cfcfcf;

    &.total-item-first {
        background-color: rgba(104, 42, 1, 0.12);
    }

    &.total-item-second {
        background-color: rgba(76, 166, 255, 0.1);
    }

    &.total-item-third {
        background-color: rgba(71, 144, 54, 0.1);
    }

    &.total-item-fourth {
        background-color: rgba(206, 154, 2, 0.1);
    }

    .header-grid-flex {
        display: flex;
        justify-content: space-between;

        .header-text-fontSize {
            font-size: 2.5rem;
        }
    }
}

.trip-detail-padding {
    padding: 20px 30px;

    .trip-highlight {
        display: flex;
        justify-content: space-between;
        background-color: #ecedfe;
        padding-left: 15px;
        border-radius: 50px;

        .marginauto-text {
            margin: auto 0;
        }
    }

    .amount-highlight {
        display: flex;
        gap: 0.5rem;
        background-color: #444cf7;
        border-radius: 50px;
        padding: 5px 10px;
    }

    .avg-trip-box {
        border-radius: 10px;
        padding: 10px;

        h5 {
            background-color: #008d38;
            border-radius: 50px;
            width: max-content;
            margin: 10px auto;
            padding: 0px 15px;
        }
    }
}

.total-expenses-box {
    background-color: #ecedfe;
    padding: 20px;
    border-bottom: 1px solid #cfcfcf;
}

.expense-internal-box {
    padding: 20px 30px;

    .expenses-border-box {
        border: 1px solid #cfcfcf;
        border-radius: 10px;

        &.expenses-border-box-height {
            height: 220px;
        }

        .flex-items-under-box {
            display: flex;
            justify-content: space-between;

            &.item-background {

                &-first,
                &-second,
                &-third {
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                    padding: 20px 15px 0 15px;
                }
            }

            &.item-background-first {
                background-color: #e3dcd7;
            }

            &.item-background-second {
                background-color: #e5eef6;
            }

            &.item-background-third {
                background-color: #e4ebe2;
            }

            &.item-padding {
                padding: 10px 15px 5px 15px;
            }
        }
    }
}

.petrol-pump-table-padding {
    padding: 0px 10px;

    p {
        padding: 10px 0;
    }

    .bottom-border-line {
        border-bottom: 1px solid #cfcfcf;
    }
}

.petrol-box-padding {
    padding: 20px 10px 0px;

    p {
        padding: 10px 0;
    }

    .bottom-border-line {
        border-bottom: 1px solid #cfcfcf;
    }
}

.total-items-third {
    width: 500px !important;
    padding: 5px 20px;
    border-radius: 10px !important;
    color: snow !important;
    margin: 10px 0 !important;
    text-align: right !important;
    transition: all 0.3s ease-in-out;
    border: 1px solid #cfcfcf !important;

    .header-grid-image {
        height: 65px;
        width: 65px;
        border-radius: 50%;
        margin-top: 8px;

        &.amount-image {
            background-color: #0067b6;
        }

        &.ltr-image {
            background-color: #dfa803;
        }

        &.diff-image {
            background-color: #555555;
        }
    }

    .header-grid-flex {
        display: flex;
        justify-content: space-between;

        .header-text-fontSize {
            font-size: 2.5rem;
        }
    }
}

.total-items-fourth {
    width: 505px !important;
    padding: 5px 15px;
    border-radius: 10px !important;
    color: snow !important;
    margin: 10px 0 !important;
    text-align: right !important;
    transition: all 0.3s ease-in-out;
    border: 1px solid #cfcfcf;

    &.total-item-first {
        background-color: rgba(104, 42, 1, 0.12);
    }

    &.total-item-second {
        background-color: rgba(76, 166, 255, 0.1);
    }

    &.total-item-third {
        background-color: rgba(71, 144, 54, 0.1);
    }

    &.total-item-fourth {
        background-color: rgba(206, 154, 2, 0.1);
    }

    &.total-item-fivth {
        background-color: rgba(64, 107, 149, 1);
    }

    &.total-item-sixth {
        background-color: rgba(239, 143, 55, 1);
    }

    .header-grid-flex {
        display: flex;
        justify-content: space-between;

        &.top-box-bottom {
            margin-top: 15px;
            border-top: 1px solid #cfcfcf;

            p {
                margin-top: 5px;
            }
        }

        .header-grid-image {}

        .header-text-fontSize {
            font-size: 2rem;
        }
    }
}

.trip-aggregation-box-padding {
    padding: 5.5px 100px;

    p {
        padding: 10px 0;
    }

    .bottom-border-line {
        border-bottom: 1px solid #cfcfcf;
    }

    .avg-trip-box {
        border-radius: 10px;
        padding: 5px;

        h6 {
            background-color: #008d38;
            border-radius: 50px;
            width: max-content;
            margin: 0px auto;
            padding: 0px 15px;
        }

        p {
            text-align: center;
        }
    }
}

.diesel-wrapper-box-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-radius: 10px;

    .span-line::before {
        content: "";
        border: 1px solid #adadad;
        padding: 10px 0;
    }

    &.first-box-bg-color {
        background-color: rgba(104, 42, 1, 0.1);
    }

    &.second-box-bg-color {
        background-color: rgba(76, 166, 255, 0.1);
    }

    &.third-box-bg-color {
        background-color: rgba(71, 144, 54, 0.1);
    }
}

.vender-other-box-border {
    border: 1px solid #cfcfcf;
    padding: 15px 0px;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;

    img {
        height: 80px;
        width: 100%;
    }
}

.header-row-table-bg-color-first {
    background-color: #fed5d5;
}

.header-row-table-bg-color-second {
    background-color: #fadbac;
}

.header-row-table-bg-color-third {
    background-color: #d1f7c8;
}

@media (max-width: 576px) {
    .headerMarginTop {
        overflow-x: scroll !important;
    }

    .total-items {
        width: 390px !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;

        &.total-item-second {
            left: 340px !important;
        }

        &.total-item-third {
            left: 680px !important;
        }

        &.total-item-fourth {
            left: 1020px !important;
        }
    }

    .headerMarginTopScroll {
        overflow-x: scroll !important;
    }

    .total-items-second {
        width: 380px !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;

        &.total-item-second {
            left: 345px !important;
        }

        &.total-item-third {
            left: 690px !important;
        }

        &.total-item-fourth {
            left: 1035px !important;
        }
    }

    .total-items-third {
        width: 200px !important;
        padding: 0px 10px;

        .header-grid-flex {
            padding: 10px 0;
        }

        .header-text-fontSize {
            font-size: 1.1rem !important;
        }
    }

    .expenses-border-box {
        &.expenses-border-box-height {
            height: 400px !important;
        }
    }

    .trip-detail-padding {
        padding: 20px 0px 0px 5px !important;
    }

    .headerMarginTopScroll-total {
        overflow-x: scroll !important;
    }

    .total-items-fourth {
        width: 185px !important;

        .header-grid-image {
            height: 50px;
            width: 50px;
        }

        .header-text-fontSize {
            font-size: 1.3rem !important;
        }
    }
}

@media (min-width: 577px) and (max-width: 650px) {
    .headerMarginTop {
        overflow-x: scroll !important;
    }

    .total-items {
        width: 390px !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;

        &.total-item-second {
            left: 330px !important;
        }

        &.total-item-third {
            left: 660px !important;
        }

        &.total-item-fourth {
            left: 990px !important;
        }
    }

    .headerMarginTopScroll {
        overflow-x: scroll !important;
    }

    .total-items-second {
        width: 380px !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;

        &.total-item-second {
            left: 320px !important;
        }

        &.total-item-third {
            left: 640px !important;
        }

        &.total-item-fourth {
            left: 960px !important;
        }
    }

    .total-items-third {
        width: 280px !important;

        .header-text-fontSize {
            font-size: 1.6rem !important;
        }
    }

    .total-items-fourth {
        width: 275px !important;
    }
}

@media (min-width: 650px) and (max-width: 700px) {
    .headerMarginTop {
        overflow-x: scroll !important;
    }

    .total-items {
        width: 390px !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;

        &.total-item-second {
            left: 305px !important;
        }

        &.total-item-third {
            left: 610px !important;
        }

        &.total-item-fourth {
            left: 915px !important;
        }
    }

    .headerMarginTopScroll {
        overflow-x: scroll !important;
    }

    .total-items-second {
        width: 380px !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;

        &.total-item-second {
            left: 300px !important;
        }

        &.total-item-third {
            left: 600px !important;
        }

        &.total-item-fourth {
            left: 900px !important;
        }
    }

    .total-items-third {
        width: 360px !important;
    }

    .total-items-fourth {
        width: 345px !important;
    }
}

@media (min-width: 700px) and (max-width: 750px) {
    .headerMarginTop {
        overflow-x: scroll !important;
    }

    .total-items {
        width: 390px !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;

        &.total-item-second {
            left: 295px !important;
        }

        &.total-item-third {
            left: 590px !important;
        }

        &.total-item-fourth {
            left: 885px !important;
        }
    }

    .headerMarginTopScroll {
        overflow-x: scroll !important;
    }

    .total-items-second {
        width: 380px !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;

        &.total-item-second {
            left: 290px !important;
        }

        &.total-item-third {
            left: 580px !important;
        }

        &.total-item-fourth {
            left: 870px !important;
        }
    }

    .total-items-third {
        width: 405px !important;
    }

    .total-items-fourth {
        width: 395px !important;
    }
}

@media (min-width: 750px) and (max-width: 800px) {
    .headerMarginTop {
        overflow-x: scroll !important;
    }

    .total-items {
        width: 390px !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;

        &.total-item-second {
            left: 280px !important;
        }

        &.total-item-third {
            left: 560px !important;
        }

        &.total-item-fourth {
            left: 840px !important;
        }
    }

    .headerMarginTopScroll {
        overflow-x: scroll !important;
    }

    .total-items-second {
        width: 380px !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;

        &.total-item-second {
            left: 275px !important;
        }

        &.total-item-third {
            left: 550px !important;
        }

        &.total-item-fourth {
            left: 825px !important;
        }
    }

    .total-items-third {
        width: 460px !important;
    }

    .total-items-fourth {
        width: 445px !important;
    }
}

@media (min-width: 800px) and (max-width: 850px) {
    .headerMarginTop {
        overflow-x: scroll !important;
    }

    .total-items {
        width: 390px !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;

        &.total-item-second {
            left: 270px !important;
        }

        &.total-item-third {
            left: 540px !important;
        }

        &.total-item-fourth {
            left: 810px !important;
        }
    }

    .headerMarginTopScroll {
        overflow-x: scroll !important;
    }

    .total-items-second {
        width: 380px !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;

        &.total-item-second {
            left: 260px !important;
        }

        &.total-item-third {
            left: 520px !important;
        }

        &.total-item-fourth {
            left: 780px !important;
        }
    }

    .total-items-fourth {
        width: 495px !important;
    }
}

@media (min-width: 850px) and (max-width: 900px) {
    .headerMarginTop {
        overflow-x: scroll !important;
    }

    .total-items {
        width: 390px !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;

        &.total-item-second {
            left: 260px !important;
        }

        &.total-item-third {
            left: 520px !important;
        }

        &.total-item-fourth {
            left: 780px !important;
        }
    }

    .headerMarginTopScroll {
        overflow-x: scroll !important;
    }

    .total-items-second {
        width: 380px !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;

        &.total-item-second {
            left: 250px !important;
        }

        &.total-item-third {
            left: 500px !important;
        }

        &.total-item-fourth {
            left: 750px !important;
        }
    }

    .total-items-third {
        width: 560px !important;
    }

    .total-items-fourth {
        width: 545px !important;
    }
}

@media (min-width: 900px) and (max-width: 950px) {
    .headerMarginTop {
        overflow-x: scroll !important;
    }

    .total-items {
        width: 390px !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;

        &.total-item-second {
            left: 240px !important;
        }

        &.total-item-third {
            left: 480px !important;
        }

        &.total-item-fourth {
            left: 720px !important;
        }
    }

    .headerMarginTopScroll {
        overflow-x: scroll !important;
    }

    .total-items-second {
        width: 380px !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;

        &.total-item-second {
            left: 240px !important;
        }

        &.total-item-third {
            left: 480px !important;
        }

        &.total-item-fourth {
            left: 720px !important;
        }
    }

    .total-items-third {
        width: 610px !important;
    }

    .total-items-fourth {
        width: 290px !important;
    }
}

@media (min-width: 950px) and (max-width: 1000px) {
    .headerMarginTop {
        overflow-x: scroll !important;
    }

    .total-items {
        width: 390px !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;

        &.total-item-second {
            left: 230px !important;
        }

        &.total-item-third {
            left: 460px !important;
        }

        &.total-item-fourth {
            left: 690px !important;
        }
    }

    .headerMarginTopScroll {
        overflow-x: scroll !important;
    }

    .total-items-second {
        width: 380px !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;

        &.total-item-second {
            left: 225px !important;
        }

        &.total-item-third {
            left: 450px !important;
        }

        &.total-item-fourth {
            left: 675px !important;
        }
    }

    .total-items-third {
        width: 660px !important;
    }

    .total-items-fourth {
        width: 315px !important;
    }
}

@media (min-width: 1000px) and (max-width: 1050px) {
    .headerMarginTop {
        overflow-x: scroll !important;
    }

    .total-items {
        width: 390px !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;

        &.total-item-second {
            left: 220px !important;
        }

        &.total-item-third {
            left: 440px !important;
        }

        &.total-item-fourth {
            left: 660px !important;
        }
    }

    .headerMarginTopScroll {
        overflow-x: scroll !important;
    }

    .total-items-second {
        width: 380px !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;

        &.total-item-second {
            left: 215px !important;
        }

        &.total-item-third {
            left: 430px !important;
        }

        &.total-item-fourth {
            left: 645px !important;
        }
    }

    .total-items-third {
        width: 705px !important;
    }

    .headerMarginTopScroll-total {
        overflow-x: scroll !important;
    }

    .total-items-fourth {
        width: 340px !important;
    }
}

@media (min-width: 1050px) and (max-width: 1100px) {
    .headerMarginTop {
        overflow-x: scroll !important;
    }

    .total-items {
        width: 390px !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;

        &.total-item-second {
            left: 205px !important;
        }

        &.total-item-third {
            left: 410px !important;
        }

        &.total-item-fourth {
            left: 615px !important;
        }
    }

    .headerMarginTopScroll {
        overflow-x: scroll !important;
    }

    .total-items-second {
        width: 380px !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;

        &.total-item-second {
            left: 200px !important;
        }

        &.total-item-third {
            left: 400px !important;
        }

        &.total-item-fourth {
            left: 600px !important;
        }
    }

    .total-items-third {
        width: 755px !important;
    }

    .total-items-fourth {
        width: 365px !important;
    }
}

@media (min-width: 1100px) and (max-width: 1150px) {
    .headerMarginTop {
        overflow-x: scroll !important;
    }

    .total-items {
        width: 390px !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;

        &.total-item-second {
            left: 195px !important;
        }

        &.total-item-third {
            left: 390px !important;
        }

        &.total-item-fourth {
            left: 585px !important;
        }
    }

    .headerMarginTopScroll {
        overflow-x: scroll !important;
    }

    .total-items-second {
        width: 380px !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;

        &.total-item-second {
            left: 190px !important;
        }

        &.total-item-third {
            left: 380px !important;
        }

        &.total-item-fourth {
            left: 570px !important;
        }
    }

    .total-items-third {
        width: 810px !important;
    }

    .total-items-fourth {
        width: 390px !important;
    }
}

@media (min-width: 1150px) and (max-width: 1200px) {
    .headerMarginTop {
        overflow-x: scroll !important;
    }

    .total-items {
        width: 390px !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;

        &.total-item-second {
            left: 180px !important;
        }

        &.total-item-third {
            left: 360px !important;
        }

        &.total-item-fourth {
            left: 540px !important;
        }
    }

    .headerMarginTopScroll {
        overflow-x: scroll !important;
    }

    .total-items-second {
        width: 380px !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;

        &.total-item-second {
            left: 175px !important;
        }

        &.total-item-third {
            left: 350px !important;
        }

        &.total-item-fourth {
            left: 525px !important;
        }
    }

    .total-items-third {
        width: 850px !important;
    }

    .total-items-fourth {
        width: 410px !important;
    }
}

@media (min-width: 1200px) and (max-width: 1250px) {
    .headerMarginTop {
        overflow-x: scroll !important;
    }

    .total-items {
        width: 390px !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;

        &.total-item-second {
            left: 170px !important;
        }

        &.total-item-third {
            left: 340px !important;
        }

        &.total-item-fourth {
            left: 510px !important;
        }
    }

    .headerMarginTopScroll {
        overflow-x: scroll !important;
    }

    .total-items-second {
        width: 380px !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;

        &.total-item-second {
            left: 165px !important;
        }

        &.total-item-third {
            left: 330px !important;
        }

        &.total-item-fourth {
            left: 495px !important;
        }
    }

    .total-items-third {
        width: 280px !important;

        .header-text-fontSize {
            font-size: 1.7rem !important;
        }
    }

    .total-items-fourth {
        width: 270px !important;
    }
}

@media (min-width: 1250px) and (max-width: 1300px) {
    .headerMarginTop {
        overflow-x: scroll !important;
    }

    .total-items {
        width: 390px !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;

        &.total-item-second {
            left: 155px !important;
        }

        &.total-item-third {
            left: 310px !important;
        }

        &.total-item-fourth {
            left: 465px !important;
        }
    }

    .headerMarginTopScroll {
        overflow-x: scroll !important;
    }

    .total-items-second {
        width: 380px !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;

        &.total-item-second {
            left: 155px !important;
        }

        &.total-item-third {
            left: 310px !important;
        }

        &.total-item-fourth {
            left: 465px !important;
        }
    }

    .total-items-third {
        width: 290px !important;

        .header-text-fontSize {
            font-size: 1.8rem !important;
        }
    }

    .total-items-fourth {
        width: 285px !important;
    }
}

@media (min-width: 1300px) and (max-width: 1350px) {
    .headerMarginTop {
        overflow-x: scroll !important;
    }

    .total-items {
        width: 390px !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;

        &.total-item-second {
            left: 145px !important;
        }

        &.total-item-third {
            left: 290px !important;
        }

        &.total-item-fourth {
            left: 435px !important;
        }
    }

    .headerMarginTopScroll {
        overflow-x: scroll !important;
    }

    .total-items-second {
        width: 380px !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;

        &.total-item-second {
            left: 140px !important;
        }

        &.total-item-third {
            left: 280px !important;
        }

        &.total-item-fourth {
            left: 420px !important;
        }
    }

    .total-items-third {
        width: 310px !important;

        .header-text-fontSize {
            font-size: 1.9rem !important;
        }
    }

    .total-items-fourth {
        width: 300px !important;
    }
}

@media (min-width: 1350px) and (max-width: 1400px) {
    .headerMarginTop {
        overflow-x: scroll !important;
    }

    .total-items {
        width: 390px !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;

        &.total-item-second {
            left: 135px !important;
        }

        &.total-item-third {
            left: 270px !important;
        }

        &.total-item-fourth {
            left: 405px !important;
        }
    }

    .headerMarginTopScroll {
        overflow-x: scroll !important;
    }

    .total-items-second {
        width: 380px !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;

        &.total-item-second {
            left: 125px !important;
        }

        &.total-item-third {
            left: 250px !important;
        }

        &.total-item-fourth {
            left: 375px !important;
        }
    }

    .total-items-third {
        width: 320px !important;

        .header-text-fontSize {
            font-size: 2rem !important;
        }
    }

    .total-items-fourth {
        width: 320px !important;
    }
}

@media (min-width: 1400px) and (max-width: 1450px) {
    .headerMarginTop {
        overflow-x: scroll !important;
    }

    .total-items {
        width: 390px !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;

        &.total-item-second {
            left: 120px !important;
        }

        &.total-item-third {
            left: 240px !important;
        }

        &.total-item-fourth {
            left: 360px !important;
        }
    }

    .headerMarginTopScroll {
        overflow-x: scroll !important;
    }

    .total-items-second {
        width: 380px !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;

        &.total-item-second {
            left: 115px !important;
        }

        &.total-item-third {
            left: 230px !important;
        }

        &.total-item-fourth {
            left: 345px !important;
        }
    }

    .total-items-third {
        width: 340px !important;

        .header-text-fontSize {
            font-size: 2.2rem !important;
        }
    }

    .total-items-fourth {
        width: 335px !important;
    }
}

@media (min-width: 1450px) and (max-width: 1500px) {
    .headerMarginTop {
        overflow-x: scroll !important;
    }

    .total-items {
        width: 390px !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;

        &.total-item-second {
            left: 110px !important;
        }

        &.total-item-third {
            left: 220px !important;
        }

        &.total-item-fourth {
            left: 330px !important;
        }
    }

    .headerMarginTopScroll {
        overflow-x: scroll !important;
    }

    .total-items-second {
        width: 380px !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;

        &.total-item-second {
            left: 100px !important;
        }

        &.total-item-third {
            left: 200px !important;
        }

        &.total-item-fourth {
            left: 300px !important;
        }
    }

    .total-items-third {
        width: 360px !important;
    }

    .total-items-fourth {
        width: 350px !important;
    }
}

@media (min-width: 1500px) and (max-width: 1550px) {
    .headerMarginTop {
        overflow-x: scroll !important;
    }

    .total-items {
        width: 390px !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;

        &.total-item-second {
            left: 95px !important;
        }

        &.total-item-third {
            left: 190px !important;
        }

        &.total-item-fourth {
            left: 285px !important;
        }
    }

    .headerMarginTopScroll {
        overflow-x: scroll !important;
    }

    .total-items-second {
        width: 380px !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;

        &.total-item-second {
            left: 90px !important;
        }

        &.total-item-third {
            left: 180px !important;
        }

        &.total-item-fourth {
            left: 270px !important;
        }
    }

    .total-items-third {
        width: 380px !important;
    }

    .total-items-fourth {
        width: 365px !important;
    }
}

@media (min-width: 1550px) and (max-width: 1600px) {
    .headerMarginTop {
        overflow-x: scroll !important;
    }

    .total-items {
        width: 390px !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;

        &.total-item-second {
            left: 80px !important;
        }

        &.total-item-third {
            left: 160px !important;
        }

        &.total-item-fourth {
            left: 240px !important;
        }
    }

    .headerMarginTopScroll {
        overflow-x: scroll !important;
    }

    .total-items-second {
        width: 380px !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;

        &.total-item-second {
            left: 80px !important;
        }

        &.total-item-third {
            left: 160px !important;
        }

        &.total-item-fourth {
            left: 240px !important;
        }
    }

    .total-items-third {
        width: 390px !important;
    }

    .total-items-fourth {
        width: 385px !important;
    }
}

@media (min-width: 1600px) and (max-width: 1650px) {
    .headerMarginTop {
        overflow-x: scroll !important;
    }

    .total-items {
        width: 390px !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;

        &.total-item-second {
            left: 70px !important;
        }

        &.total-item-third {
            left: 140px !important;
        }

        &.total-item-fourth {
            left: 210px !important;
        }
    }

    .headerMarginTopScroll {
        overflow-x: scroll !important;
    }

    .total-items-second {
        width: 380px !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;

        &.total-item-second {
            left: 60px !important;
        }

        &.total-item-third {
            left: 120px !important;
        }

        &.total-item-fourth {
            left: 180px !important;
        }
    }

    .total-items-third {
        width: 410px !important;
    }

    .total-items-fourth {
        width: 400px !important;
    }
}

@media (min-width: 1650px) and (max-width: 1700px) {
    .headerMarginTop {
        overflow-x: scroll !important;
    }

    .total-items {
        width: 390px !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;

        &.total-item-second {
            left: 55px !important;
        }

        &.total-item-third {
            left: 110px !important;
        }

        &.total-item-fourth {
            left: 165px !important;
        }
    }

    .total-items-second {
        width: 325px !important;
    }

    .total-items-third {
        width: 420px !important;
    }

    .total-items-fourth {
        width: 415px !important;
    }
}

@media (min-width: 1700px) and (max-width: 1750px) {
    .headerMarginTop {
        overflow-x: scroll !important;
    }

    .total-items {
        width: 390px !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;

        &.total-item-second {
            left: 45px !important;
        }

        &.total-item-third {
            left: 90px !important;
        }

        &.total-item-fourth {
            left: 135px !important;
        }
    }

    .total-items-second {
        width: 340px !important;
    }

    .total-items-third {
        width: 440px !important;
    }

    .total-items-fourth {
        width: 435px !important;
    }
}

@media (min-width: 1750px) and (max-width: 1800px) {
    .headerMarginTop {
        overflow-x: scroll !important;
    }

    .total-items {
        width: 390px !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;

        &.total-item-second {
            left: 30px !important;
        }

        &.total-item-third {
            left: 60px !important;
        }

        &.total-item-fourth {
            left: 90px !important;
        }
    }

    .total-items-second {
        width: 355px !important;
    }

    .total-items-third {
        width: 460px !important;
    }

    .total-items-fourth {
        width: 450px !important;
    }
}

@media (min-width: 1800px) and (max-width: 1850px) {
    .headerMarginTop {
        overflow-x: scroll !important;
    }

    .total-items {
        width: 390px !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;

        &.total-item-second {
            left: 20px !important;
        }

        &.total-item-third {
            left: 40px !important;
        }

        &.total-item-fourth {
            left: 60px !important;
        }
    }

    .total-items-second {
        width: 370px !important;
    }

    .total-items-third {
        width: 470px !important;
    }

    .total-items-fourth {
        width: 465px !important;
    }
}

@media (min-width: 1850px) and (max-width: 1900px) {
    .headerMarginTop {
        overflow-x: scroll !important;
    }

    .total-items {
        width: 390px !important;
        margin: 0 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;

        &.total-item-second {
            left: 10px !important;
        }

        &.total-item-third {
            left: 20px !important;
        }

        &.total-item-fourth {
            left: 30px !important;
        }
    }

    .total-items-second {
        width: 380px !important;
    }

    .total-items-fourth {
        width: 480px !important;
    }
}