.transport-final-calculation {
  padding: 15px !important;
}

.transport-final-calculation-padding {
  padding: 15px !important;
  margin-bottom: 20px;
}

.rupee_symbol_payable_amount {
  font-weight: lighter !important;
}

.back-new-entry {
  width: max-content;
  color: #ffffff !important;
  font-size: 16px !important;
  background-color: #444cf7 !important;
  font-weight: bold !important;
  position: relative;
}


.work-type-object-foremost {
  background-color: #fdfdfd;
  border: 1px solid #adadad;
  padding: 10px 0;
  margin-bottom: 20px;

  .normal-para {
    color: #555555;
    padding: 0 10px;
  }

  .bold-para {
    color: #555555;
    font-weight: bold;
    text-align: center;
  }
}

.final-track-first {
  height: 732px;
  width: 750px;

  p {
    font-size: 1rem;
  }

  .work-type-object-first {
    background-color: #479036;
    padding: 10px 0;

    .normal-para {
      color: #fff;
      padding: 0 10px;
      font-size: 24px;
    }

    .bold-para {
      color: #fff;
      font-weight: bold;
      text-align: center;
      font-size: 24px;
    }
  }

  .work-type-object-second {
    background-color: #fdfdfd;
    border: 1px solid #adadad;
    padding: 10px 0;
    margin-top: 20px;

    .normal-para {
      color: #555555;
      padding: 0 10px;
    }

    .bold-para {
      color: #555555;
      font-weight: bold;
      text-align: center;
    }
  }

  .work-type-object-third {
    background-color: #fdfdfd;
    border: 1px solid #adadad;
    padding: 10px 0;
    margin-top: 20px;

    .normal-para {
      color: #555555;
      padding: 0 10px;
    }

    .bold-para {
      color: #555555;
      font-weight: bold;
      text-align: center;
    }
  }

  .work-type-object-fourth {
    background-color: #fdfdfd;
    border: 1px solid #adadad;
    padding: 10px 0;

    .normal-para {
      color: #555555;
      padding: 0 10px;
    }

    .bold-para {
      color: #555555;
      font-weight: bold;
      text-align: center;
    }
  }

  .work-type-object-fivth {
    background-color: #fdfdfd;
    border: 1px solid #adadad;
    padding: 10px 0;
    margin-top: 20px;

    .normal-para {
      color: #555555;
      padding: 0 10px;
    }

    .bold-para {
      color: #555555;
      font-weight: bold;
      text-align: center;
    }
  }

  .work-type-object-sixth {
    background-color: #fdfdfd;
    border: 1px solid #adadad;
    padding: 10px 0;

    .normal-para {
      color: #555555;
      padding: 0 10px;
    }

    .bold-para {
      color: #555555;
      font-weight: bold;
      text-align: center;
    }
  }
}

.final-track-second {
  width: 700px;
  height: 732px;
  border: 1px solid #adadad;
  border-radius: 5px;
  position: relative;

  .billing-prefix-form {
    padding: 20px;

    .input-field {
      background-color: #F7F7F7;

      &::placeholder {
        color: #6c6c6c;
      }
    }
  }

  .total-amount-wrapper {
    width: 100%;
    background-color: #272B8D;
    padding: 20px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    position: absolute;
    bottom: 0;

    .amount-bold {
      float: right;
    }
  }
}


.submit-button-area {
  margin-top: 80px;
  margin-bottom: 15px;
  text-align: center;

  .submit-button {
    background-color: #444cf7;
    font-size: 16px !important;
    height: 36px !important;
    font-weight: bold !important;
  }

  .submit-button:hover {
    background-color: #444cf7 !important;
  }
}

@media (max-width: 576px) {
  .final-track {

    &-first,
    &-second {
      width: 400px !important;
    }
  }

  .final-track-second {
    height: 850px !important;
    margin-top: 80px !important;
  }
}

@media (min-width: 577px) and (max-width: 650px) {
  .final-track {

    &-first,
    &-second {
      width: 400px !important;
    }
  }

  .final-track-second {
    height: 850px !important;
    margin-top: 80px !important;
  }
}

@media (min-width: 650px) and (max-width: 700px) {
  .final-track {

    &-first,
    &-second {
      width: 400px !important;
    }
  }

  .final-track-second {
    height: 850px !important;
    margin-top: 80px !important;
  }
}

@media (min-width: 700px) and (max-width: 750px) {
  .final-track {

    &-first,
    &-second {
      width: 430px !important;
    }
  }

  .final-track-second {
    height: 850px !important;
    margin-top: 50px !important;
  }
}

@media (min-width: 750px) and (max-width: 800px) {
  .final-track {

    &-first,
    &-second {
      width: 450px !important;
    }
  }

  .final-track-second {
    height: 850px !important;
    margin-top: 50px !important;
  }
}

@media (min-width: 800px) and (max-width: 850px) {
  .final-track {

    &-first,
    &-second {
      width: 500px !important;
    }
  }

  .final-track-second {
    height: 850px !important;
    margin-top: 40px !important;
  }
}

@media (min-width: 850px) and (max-width: 900px) {
  .final-track {

    &-first,
    &-second {
      width: 550px !important;
    }
  }

  .final-track-second {
    height: 820px !important;
    margin-top: 50px !important;
  }
}

@media (min-width: 900px) and (max-width: 950px) {
  .final-track {

    &-first,
    &-second {
      width: 640px !important;
    }
  }

  .final-track-second {
    margin-top: 20px !important;
  }
}

@media (min-width: 950px) and (max-width: 1000px) {
  .final-track {

    &-first,
    &-second {
      width: 680px !important;
    }
  }

  .final-track-second {
    margin-top: 20px !important;
  }
}

@media (min-width: 1000px) and (max-width: 1050px) {
  .final-track {

    &-first,
    &-second {
      width: 740px !important;
    }
  }

  .final-track-second {
    margin-top: 20px !important;
  }
}

@media (min-width: 1050px) and (max-width: 1100px) {
  .final-track {

    &-first,
    &-second {
      width: 780px !important;
    }
  }

  .final-track-second {
    margin-top: 20px !important;
  }
}

@media (min-width: 1100px) and (max-width: 1150px) {
  .final-track {

    &-first,
    &-second {
      width: 830px !important;
    }
  }

  .final-track-second {
    margin-top: 20px !important;
  }
}

@media (min-width: 1150px) and (max-width: 1200px) {
  .final-track {

    &-first,
    &-second {
      width: 900px !important;
    }
  }

  .final-track-second {
    margin-top: 20px !important;
  }
}

@media (min-width: 1200px) and (max-width: 1250px) {
  .final-track {

    &-first,
    &-second {
      width: 430px !important;
      height: 756px !important;
    }
  }

  .back-new-entry {
    left: -70px !important;
  }
}

@media (min-width: 1250px) and (max-width: 1300px) {
  .final-track {

    &-first,
    &-second {
      width: 450px !important;
      height: 756px !important;
    }
  }

  .back-new-entry {
    left: -60px !important;
  }
}

@media (min-width: 1300px) and (max-width: 1350px) {
  .final-track {

    &-first,
    &-second {
      width: 480px !important;
      height: 756px !important;
    }
  }

  .back-new-entry {
    left: -50px !important;
  }
}

@media (min-width: 1350px) and (max-width: 1400px) {
  .final-track {

    &-first,
    &-second {
      width: 500px !important;
      height: 735px !important;
    }
  }

  .back-new-entry {
    left: -50px !important;
  }
}

@media (min-width: 1400px) and (max-width: 1450px) {
  .final-track {

    &-first,
    &-second {
      width: 520px !important;
      height: 735px !important;
    }
  }

  .back-new-entry {
    left: -40px !important;
  }
}

@media (min-width: 1450px) and (max-width: 1500px) {
  .final-track {

    &-first,
    &-second {
      width: 550px !important;
      height: 756px !important;
    }
  }

  .back-new-entry {
    left: -30px !important;
  }
}

@media (min-width: 1500px) and (max-width: 1550px) {
  .final-track {

    &-first,
    &-second {
      width: 570px !important;
    }
  }
}

@media (min-width: 1550px) and (max-width: 1600px) {
  .final-track {

    &-first,
    &-second {
      width: 600px !important;
    }
  }
}

@media (min-width: 1600px) and (max-width: 1650px) {
  .final-track {

    &-first,
    &-second {
      width: 620px !important;
    }
  }
}

@media (min-width: 1650px) and (max-width: 1700px) {
  .final-track {

    &-first,
    &-second {
      width: 650px !important;
    }
  }
}

@media (min-width: 1700px) and (max-width: 1750px) {
  .final-track {

    &-first,
    &-second {
      width: 670px !important;
    }
  }
}

@media (min-width: 1750px) and (max-width: 1800px) {
  .final-track {

    &-first,
    &-second {
      width: 700px !important;
    }
  }
}

@media (min-width: 1800px) and (max-width: 1850px) {
  .final-track {

    &-first,
    &-second {
      width: 720px !important;
    }
  }
}