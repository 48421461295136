.billed-detail-padding {
  padding: 20px !important;
}

.summary-table-spin-element {
  margin-top: 5%;
  margin-left: 50%;
  margin-bottom: 5%;
}


.button-disabled {
  background: #ccc !important;
  color: #666 !important;
  border: 1px solid #ccc !important;
}

.flex-item-bill-unbilled {
  display: flex;
  gap: 1rem;

  .img-color-active {
    filter: invert(41%) sepia(85%) saturate(2422%) hue-rotate(213deg) brightness(96%) contrast(92%);
  }

  .img-color-inactive {
    filter: invert(13%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(97%) contrast(97%);
  }
}

.allbill-text {
  .allbill-span-text {
    color: #ffffff;
    border-radius: 50px;
    padding: 4px 7px;

    &.all-bill-span-bg-color {
      background-color: #444cf7;
    }

    &.inactive-tab-bg-color {
      background-color: #555555;
    }
  }
}

.tab-bar-extra-content {
  background-color: #ecedfe;
  padding: 5px 10px;
  border-radius: 5px;

  .btn-bg-color {
    background-color: #444cf7 !important;
    color: #ffffff !important;
  }

  .btn-text-color {
    color: #444cf7 !important;
  }
}

.agency-flex-select-bar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.5rem;

  .select-new-entry {
    width: 250px !important;
  }
}

.tab-change-summary {
  width: 100% !important;
}