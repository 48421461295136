.card-box {
  width: 400px;
  min-height: 150px;
  box-shadow: 0 0px 6px 2px rgba(124, 103, 103, 0.34);
  margin: 0 0 30px 0;

  .card-content {
    display: flex;
  }

  .AgencyDetails {
    width: 100%;
    padding: 5px;
  }

  .AgencyDetailsValue {
    margin-left: 5px;
    font-weight: bold;
  }

  .card-button {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    a {
      color: black;
    }

    .card-button {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      a {
        color: black;
      }

      ul.action-items {
        list-style-type: none;

        li {
          list-style-type: none;
          margin: 0;
          padding: 0;
          height: 32px;
        }
      }

      .card-icons {
        border: 1px solid rgba(140, 136, 136, 0.456);
        width: 30px;
        padding: 6px;
        border-radius: 5px;
      }

      .card-icons-delete {
        color: #ff4d4f;
      }

      .list-item-icon {
        min-width: max-content !important;
        padding: 0;
      }

      .card-icons-images {
        border: 1px solid rgba(140, 136, 136, 0.456);
        width: 35px !important;
        padding: 4px;
        border-radius: 5px;
      }

    }
  }
}


.cancelled-loading-challan-no {
  color: red;
}

.disabled-input {
  color: #666 !important;
  
  span {
    color: #666 !important;
  }

  input {
    color: #666 !important;
  }
}

.action-button {
  justify-content: end;
}

.agency-details-margin {
  padding: 0 15px !important;
}

.no-data-available {
  padding: 25px 0;
  font-size: 16px;
}

.sub-headings {
  font-size: 18px;
  padding: 10px 0;
  font-weight: bold;
  text-decoration: underline;
}

.space-between-rows {
  margin-bottom: 10px;
  background-color: #f1f1f1;
  padding: 25px;
  border-radius: 5px;
}

.ant-form-item .ant-form-item-row .ant-form-item-label {
  min-width: 100%;
  text-align: left;
}

.add-new-entry {
  color: #fff;
  text-decoration: none;
  width: 100%;
  font-weight: bold !important;
  font-size: 16px !important;
  height: 36px !important;
  background-color: #444CF7 !important;
}

.submit-button {
  color: #fff;
  text-decoration: none;
  font-weight: bold !important;
  font-size: 16px !important;
  height: 36px !important;
  background-color: #444CF7 !important;
}

.show-agency-values {
  width: max-content;
  min-width: 100%;
  background-color: #f1f1f1;
  color: black;
  border-radius: 5px !important;
  padding: 5px 10px;
  margin-bottom: 5px;
}

.form-input-box {
  width: 100%;
  color: black;
  border-radius: 5px !important;
  padding: 5px 10px;
  margin-bottom: 5px;
  height: 40px;
}

.form-select-box {
  color: black;
  border-radius: 5px !important;
  height: 40px;
}

.agency-project-rate-display {
  display: flex;
}

.sub-module-create-agency {
  border: 1px solid #d1d1d1;
  padding: 20px;
  border-radius: 5px;
}

.marginBottom15 {
  margin-bottom: 15px;
}

.add-more {
  margin: 15px 0 0;
  text-align: right;
}

.loading-rate-element {
  width: 100%;
  display: flex;
}

.delete-button-add-more {
  min-width: 32px !important;
}

.add-more-whole-grid {
  text-align: center;
}

.submit-button-area {
  margin-top: 15px;
  text-align: center;
}

.submit-button-area button {
  margin: 0 5px 0;
}

.submit-button-area button {
  margin: 0 5px 0;
}

.remove-main-block-element {
  position: relative;
  text-align: right;
}

.remove-main-block-element button {
  position: absolute;
  right: -8px;
  bottom: -22px;
}

.input-error-message {
  color: #ff4d4f;
}

.input-error {
  border-color: #ff4d4f;
  box-shadow: 0 0px 2px #ff4d4f;
}

.pagination-box {
  padding: 50px 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.marginBottom20 {
  margin-bottom: 20px;
}

.grid-space {
  margin-top: -85px !important;
}

.marginBottom20 {
  margin-bottom: 20px;
}

.hsd-details-grid-padding {
  padding: 15px !important;
}

@media (max-width: 576px) {
  .card-box {
    width: 300px;
  }

  .add-new-entry {
    margin-top: 10px !important;
  }
}

@media (min-width: 577px) and (max-width: 699px) {
  .card-box {
    width: 100%;
  }

  .add-new-entry {
    margin-top: 10px !important;
  }
}

@media (min-width: 700px) and (max-width: 899px) {
  .card-box {
    width: 100%;
  }

  .add-new-entry {
    margin-top: 10px !important;
  }
}

@media (min-width: 900px) and (max-width: 950px) {
  .card-box {
    width: 300px;
    height: 160px;
  }
}

@media (min-width: 951px) and (max-width: 1000px) {
  .card-box {
    width: 330px;
    height: 160px;
    margin-right: 10px;
  }
}

@media (min-width: 1001px) and (max-width: 1050px) {
  .card-box {
    width: 350px;
    height: 160px;
    margin-right: 10px;
  }
}

@media (min-width: 1051px) and (max-width: 1100px) {
  .card-box {
    width: 370px;
    height: 160px;
    margin-right: 10px;
  }
}

@media (min-width: 1200px) and (max-width: 1299px) {
  .card-box {
    width: 290px;
    height: 160px;
  }

  .add-new-entry {
    margin-left: -30px !important;
  }
}

@media (min-width: 1300px) and (max-width: 1349px) {
  .card-box {
    width: 330px;
    height: 160px;
  }

  .add-new-entry {
    margin-left: -30px !important;
  }
}

@media (min-width: 1350px) and (max-width: 1399px) {
  .card-box {
    width: 340px;
    height: 160px;
  }

  .add-new-entry {
    margin-left: -30px !important;
  }
}

@media (min-width: 1400px) and (max-width: 1449px) {
  .card-box {
    width: 350px;
  }
}

@media (min-width: 1450px) and (max-width: 1499px) {
  .card-box {
    width: 370px;
  }
}

@media (min-width: 1500px) and (max-width: 1549px) {
  .card-box {
    width: 390px;
  }
}

@media (min-width: 1550px) and (max-width: 1599px) {
  .card-box {
    width: 400px;
  }
}

@media (min-width: 1600px) and (max-width: 1649px) {
  .card-box {
    width: 430px;
  }
}

@media (min-width: 1650px) and (max-width: 1700px) {
  .card-box {
    width: 440px;
  }
}

@media (min-width: 1701px) and (max-width: 1749px) {
  .card-box {
    width: 455px;
  }
}

@media (min-width: 1750px) and (max-width: 1799px) {
  .card-box {
    width: 470px;
  }
}

@media (min-width: 1800px) and (max-width: 1849px) {
  .card-box {
    width: 490px;
  }
}

@media (min-width: 1850px) and (max-width: 1899px) {
  .card-box {
    width: 500px;
  }
}

@media (min-width: 1900px) {
  .card-box {
    width: 520px !important;
  }
}