.pending-transport-padding {
  padding: 15px !important;
}

.filter-new-entry {
  color: #444cf7 !important;
  border: 1px solid #444cf7 !important;
  font-weight: bold !important;
  position: relative;
}

.right-align {
  text-align: right !important;
}

.table-row-cancelled {
  color: red !important;
}

.pagination-box {
  padding: 20px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 99999;
  background-color: #eaebf0 !important;
}


.right-align button.export-button {
  margin-right: 10px;
}

.pending-bill-table {
  padding: 15px !important;
}

.search-button-area {
  text-align: right;

  button {
    margin: 0 5px 0;
  }
}

.submit-button {
  background-color: #444CF7 !important;
}

.ant-table-content > table {
  border-color: #ccc !important;
}

.transport-agency-bill-number-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 0 10px;
}

.transport-agency-wrappper {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
}

.select-new-entry {
  width: 300px;
}

.transport-agency-bill-number {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;

  .hr-transport-box {
    padding: 8px 15px;
    background-color: #d2d4fd;
    border-radius: 5px;
  }
}

.middle-boundary-transport {
  background-color: #682a01;
  display: flex;
  justify-content: space-between;
  margin: 0 10px !important;
  padding: 0px 15px;
  border-radius: 5px;

  .text-boundary-tranport {
    letter-spacing: 1px;
  }

  .total-amount-transport {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
  }
}

.bottom-transport-counter {
  display: flex;
  gap: 1.5rem;
  justify-content: space-between;
  margin: 0 10px;

  h6 {
    text-align: center;
  }

  .bottom-transport-first-counter {
    width: 210px;
    height: 110px;
    padding: 22px 0px;
    background-color: #42536c;
    border-radius: 5px;
    text-align: center;
  }

  .bottom-transport-second-counter {
    width: 210px;
    height: 110px;
    padding: 22px 0px;
    background-color: #28bbc3;
    border-radius: 5px;
    text-align: center;
    text-align-last: center;
  }

  .bottom-transport-third-counter {
    width: 210px;
    height: 110px;
    padding: 22px 0px;
    background-color: #43377c;
    border-radius: 5px;
    text-align: center;
    text-align-last: center;
  }

  .bottom-transport-fourth-counter {
    width: 210px;
    height: 110px;
    padding: 22px 0px;
    background-color: #8578e3;
    border-radius: 5px;
    text-align: center;
    text-align-last: center;
  }

  .bottom-transport-fivth-counter {
    width: 210px;
    height: 110px;
    padding: 22px 0px;
    background-color: #e7655f;
    border-radius: 5px;
    text-align: center;
    text-align-last: center;
  }

  .bottom-transport-sixth-counter {
    width: 210px;
    height: 110px;
    padding: 22px 0px;
    background-color: #574c8f;
    border-radius: 5px;
    text-align: center;
    text-align-last: center;
  }

  .bottom-transport-seventh-counter {
    width: 210px;
    height: 110px;
    padding: 22px 0px;
    background-color: #2c7bff;
    border-radius: 5px;
    text-align: center;
    text-align-last: center;
  }
}

.submit-button-area {
  margin: 15px 0;
  text-align: center;

  .submit-outline-button {
    color: #444cf7;
    border-color: #444cf7;
    font-size: 16px !important;
    height: 36px !important;
    font-weight: bold !important;
  }

  .submit-button {
    background-color: #444cf7;
    font-size: 16px !important;
    height: 36px !important;
    font-weight: bold !important;
  }

  button {
    margin: 0 5px 0;
  }
}

@media (max-width: 575px) {
  .breadcrumb_buttons button {
    margin-bottom: 10px !important
  }

  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 250px !important;
      padding: 22px 10px !important;
    }
  }

  .transport-agency-bill-number-wrapper {
    .transport-agency-wrappper {
      gap: 1rem !important;
    }

    .select-new-entry {
      width: 200px !important;
    }

    .transport-agency-bill-number {
      margin-top: 10px !important;
    }
  }

  .middle-boundary-transport {
    width: 500px !important;
  }
}

@media (min-width: 576px) and (max-width: 650px) {
  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 300px !important;
      padding: 22px 10px !important;
    }
  }

  .transport-agency-bill-number-wrapper {
    .transport-agency-wrappper {
      gap: 1rem !important;
    }

    .select-new-entry {
      width: 200px !important;
    }

    .transport-agency-bill-number {
      margin-top: 10px !important;
    }
  }
}

@media (min-width: 650px) and (max-width: 699px) {
  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 175px !important;
      padding: 22px 10px !important;
    }
  }

  .transport-agency-bill-number-wrapper {
    .transport-agency-wrappper {
      gap: 1rem !important;
    }

    .select-new-entry {
      width: 200px !important;
    }

    .transport-agency-bill-number {
      margin-top: 10px !important;
    }
  }
}

@media (min-width: 700px) and (max-width: 750px) {
  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 200px !important;
      padding: 22px 10px !important;
    }
  }

  .transport-agency-bill-number-wrapper {
    .transport-agency-wrappper {
      gap: .5rem !important;
    }

    .select-new-entry {
      width: 100px !important;
    }

    .transport-agency-bill-number {
      margin-top: 10px !important;
    }
  }
}

@media (min-width: 750px) and (max-width: 799px) {
  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 225px !important;
      padding: 22px 10px !important;
    }
  }

  .transport-agency-bill-number-wrapper {
    .transport-agency-wrappper {
      gap: 1rem !important;
    }

    .select-new-entry {
      width: 130px !important;
    }

    .transport-agency-bill-number {
      margin-top: 10px !important;
    }
  }
}

@media (min-width: 800px) and (max-width: 850px) {
  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 160px !important;
      height: 125px !important;
      padding: 22px 5px !important;
    }
  }

  .select-new-entry {
    width: 220px !important;
    margin-top: 15px !important;
  }

  .filter-new-entry {
    margin-top: 10px !important;
  }

  .transport-agency-bill-number-wrapper {

    .transport-agency-bill-number {
      margin-top: 10px !important;
    }
  }
}

@media (min-width: 850px) and (max-width: 900px) {
  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 175px !important;
      height: 125px !important;
      padding: 22px 5px !important;
    }
  }

  .select-new-entry {
    width: 220px !important;
    margin-top: 15px !important;
  }

  .filter-new-entry {
    margin-top: 10px !important;
  }

  .transport-agency-bill-number-wrapper {

    .transport-agency-bill-number {
      margin-top: 10px !important;
    }
  }
}

@media (min-width: 900px) and (max-width: 950px) {
  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 138px !important;
      height: 125px !important;
      padding: 22px 10px !important;
    }
  }

  .select-new-entry {
    width: 145px !important;
  }

  .transport-agency-bill-number-wrapper {

    .transport-agency-wrappper {
      gap: .5rem !important;
    }
  }

  .bottom-transport-counter {
    justify-content: unset !important;
  }
}

@media (min-width: 950px) and (max-width: 1000px) {

  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 150px !important;
      padding: 22px 10px !important;
    }
  }

  .select-new-entry {
    width: 160px !important;
  }

  .transport-agency-bill-number-wrapper {

    .transport-agency-wrappper {
      gap: 1rem !important;
    }

  }

  .bottom-transport-counter {
    justify-content: unset !important;
  }
}

@media (min-width: 1000px) and (max-width: 1050px) {
  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 163px !important;
      padding: 22px 10px !important;
    }
  }

  .select-new-entry {
    width: 180px !important;
  }

  .bottom-transport-counter {
    justify-content: unset !important;
  }
}

@media (min-width: 1050px) and (max-width: 1100px) {
  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 174px !important;
      padding: 22px 10px !important;
    }
  }

  .select-new-entry {
    width: 180px !important;
  }

  .bottom-transport-counter {
    justify-content: unset !important;
  }
}

@media (min-width: 1100px) and (max-width: 1150px) {
  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 188px !important;
      padding: 22px 10px !important;
    }
  }
  .select-new-entry {
    width: 210px !important;
  }

  .bottom-transport-counter {
    justify-content: unset !important;
  }
}

@media (min-width: 1150px) and (max-width: 1200px) {
  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 200px !important;
      padding: 22px 10px !important;
    }
  }


  .select-new-entry {
    width: 200px !important;
  }
  .bottom-transport-counter {
    justify-content: unset !important;
  }
}

@media (min-width: 1200px) and (max-width: 1250px) {
  .select-new-entry {
    width: 170px !important;
  }
  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 135px !important;
      padding: 22px 10px !important;
    }
  }
}

@media (min-width: 1250px) and (max-width: 1300px) {

  .select-new-entry {
    width: 200px !important;
  }
  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 140px !important;
      padding: 22px 10px !important;
    }
  }
}

@media (min-width: 1300px) and (max-width: 1350px) {

  .select-new-entry {
    width: 220px !important;
  }

  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 150px !important;
      padding: 22px 10px !important;
    }
  }
}

@media (min-width: 1350px) and (max-width: 1400px) {

  .select-new-entry {
    width: 230px !important;
  }

  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 158px !important;
    }
  }
}

@media (min-width: 1400px) and (max-width: 1450px) {
  .select-new-entry {
    width: 250px !important;
  }


  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 167px !important;
    }
  }
}

@media (min-width: 1450px) and (max-width: 1500px) {
  .filter-new-entry {
    margin-left: -25px !important;
  }

  .select-new-entry {
    width: 250px !important;
  }

  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 175px !important;
    }
  }
}

@media (min-width: 1500px) and (max-width: 1550px) {
  .filter-new-entry {
    margin-left: -25px !important;
  }
  
  .select-new-entry {
    width: 270px !important;
  }

  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 154px !important;
    }
  }

  .bottom-transport {

    &-second-counter,
    &-third-counter {
      padding: 22px 14px !important;
    }
  }
}

@media (min-width: 1550px) and (max-width: 1599px) {
  .filter-new-entry {
    left: -20px !important;
  }
  .select-new-entry {
    width: 270px !important;
  }

  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 160px !important;
    }
  }

  .bottom-transport {

    &-second-counter,
    &-third-counter {
      padding: 22px 15px !important;
    }
  }
}

@media (min-width: 1600px) and (max-width: 1750px) {
  .filter-new-entry {
    margin-left: -15px !important;
  }

  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 166px !important;
    }
  }
}

@media (min-width: 1650px) and (max-width: 1700px) {
  .filter-new-entry {
    left: 0px !important;
  }

  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 175px !important;
    }
  }
}

@media (min-width: 1700px) and (max-width: 1750px) {
  .filter-new-entry {
    left: 15px !important;
  }

  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 180px !important;
    }
  }
}

@media (min-width: 1750px) and (max-width: 1800px) {

  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 190px !important;
    }
  }
}

@media (min-width: 1800px) and (max-width: 1850px) {

  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 195px !important;
    }
  }
}



@media (min-width: 1850px) and (max-width: 1900px) {

  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 200px !important;
    }
  }
}

