.generated-transport-bills-padding {
  padding: 15px !important;
}

.transport-agency-bill-number-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 10px;
}

.transport-agency-wrappper {
  display: flex;
  gap: 2rem;
}

.pagination-box {
  padding: 20px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 99999;
}

.select-new-entry {
  width: 300px;
}

.transport-agency-bill-number {
  text-align: right !important;
  .hr-transport-box {
    width: max-content;
    border-color: #444cf7;
    color: #444cf7;
  }
}

.search-button-area {
  margin: 15px 0;
  text-align: right;

  .submit-button {
    background-color: #444cf7;
  }

  button {
    margin: 0 5px 0;
  }
}

.middle-boundary-transport {
  background-color: #479036 !important;
  display: flex;
  justify-content: space-between;
  margin: 10px !important;
  padding: 0px 15px;
  border-radius: 5px;

  .text-boundary-tranport {
    letter-spacing: 1px;
  }

  .total-amount-transport {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
  }
}

.summary-wrapper {
  margin: 0 10px !important;
  display: contents !important;

  .summary-text {
    color: #353535;
    font-size: 1.5rem;
    margin-left: 10px;
  }

  .line1 {
    height: 2px;
    width: 83%;
    background: #d2d4fd;
    position: relative;
    margin-left: 20px;
  }

  .line2 {
    height: 2px;
    width: 95%;
    background: #d2d4fd;
    position: relative;
    margin-left: 20px;
  }

  .line1:before,
  .line2:before {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    background: #d2d4fd;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
  }

  .line1:after,
  .line2:after {
    right: 0;
  }
}

.transaction-detail-wrapper {
  margin: 0 10px;

  .transaction-detail-flex {
    display: flex;
    gap: 1.5rem;
    margin-top: 10px;

    .bottom-transport-first-counter {
      width: 299px;
      height: 70px;
      padding: 7px 0;
      background-color: #4e4c69;
      border-radius: 5px;
      text-align: center;
    }

    .bottom-transport-second-counter {
      width: 245px;
      height: 70px;
      padding: 7px 0;
      background-color: #235172;
      border-radius: 5px;
      text-align: center;
    }

    .bottom-transport-third-counter {
      width: 299px;
      height: 70px;
      padding: 7px 0;
      background-color: #474f41;
      border-radius: 5px;
      text-align: center;
    }
  }
}

.bill-number {
  padding: 15px 0px !important;
}

.bill-number-detail-background {
  background-color: #fafafa;
  border: 1px solid #adadad;
  padding: 15px 0px !important;
  border-radius: 5px;
  transition: all 0.2s ease-in;
  margin-bottom: 20px !important;

  .view-list-flex-link {
    display: contents !important;

    a {
      text-decoration: none;
      color: #555555 !important;
    }
  }

  &:hover {
    box-shadow: 1px 3px 3px #adadad;
  }
}

.bill-number-icons {
  background-color: #fafafa;
  border: 1px solid #adadad;
  padding: 19px;
  border-radius: 5px;
  transition: all 0.2s ease-in;

  &:hover {
    box-shadow: 1px 3px 3px #adadad;
  }
}

.generated-spin {
  margin: 0 auto;
}

.no-generated-bill-margin-auto {
  margin: 0 auto !important;
}

@media (max-width: 576px) {
  .line1 {
    margin-left: 0 !important;
  }

  .line2 {
    margin-left: 0 !important;
  }

  .middle-boundary-transport {
    width: 200px !important;
  }
}

@media (min-width: 577px) and (max-width: 650px) {
  .line1 {
    margin-left: 0 !important;
  }

  .line2 {
    margin-left: 0 !important;
  }
}

@media (min-width: 650px) and (max-width: 700px) {
  .line1 {
    width: 30% !important;
  }

  .line2 {
    width: 77% !important;
  }
}

@media (min-width: 700px) and (max-width: 750px) {
  .line1 {
    width: 37% !important;
  }

  .line2 {
    width: 81% !important;
  }
}

@media (min-width: 750px) and (max-width: 800px) {
  .line1 {
    width: 44% !important;
  }

  .line2 {
    width: 82% !important;
  }
}

@media (min-width: 800px) and (max-width: 850px) {
  .line1 {
    width: 49% !important;
  }

  .line2 {
    width: 84% !important;
  }
}

@media (min-width: 850px) and (max-width: 900px) {
  .line1 {
    width: 54% !important;
  }

  .line2 {
    width: 85% !important;
  }
}

@media (min-width: 900px) and (max-width: 950px) {
  .line1 {
    width: 57% !important;
  }

  .line2 {
    width: 86% !important;
  }

  .hr-transport-box {
    margin-top: 10px !important;
  }
}

@media (min-width: 950px) and (max-width: 1000px) {
  .line1 {
    width: 60% !important;
  }

  .line2 {
    width: 87% !important;
  }

  .hr-transport-box {
    margin-top: 10px !important;
  }
}

@media (min-width: 1000px) and (max-width: 1050px) {
  .line1 {
    width: 63% !important;
  }

  .line2 {
    width: 88% !important;
  }

  .hr-transport-box {
    margin-top: 10px !important;
  }
}

@media (min-width: 1050px) and (max-width: 1100px) {
  .line1 {
    width: 65% !important;
  }

  .line2 {
    width: 89% !important;
  }
}

@media (min-width: 1100px) and (max-width: 1150px) {
  .line1 {
    width: 67% !important;
  }

  .line2 {
    width: 90% !important;
  }
}

@media (min-width: 1150px) and (max-width: 1200px) {
  .line1 {
    width: 69% !important;
  }

  .line2 {
    width: 91% !important;
  }
}

@media (min-width: 1200px) and (max-width: 1250px) {
  .line1 {
    width: 71% !important;
  }

  .line2 {
    width: 91% !important;
  }
}

@media (min-width: 1250px) and (max-width: 1300px) {
  .line1 {
    width: 73% !important;
  }

  .line2 {
    width: 91% !important;
  }
}

@media (min-width: 1300px) and (max-width: 1350px) {
  .line1 {
    width: 73% !important;
  }

  .line2 {
    width: 91% !important;
  }
}

@media (min-width: 1350px) and (max-width: 1400px) {
  .line1 {
    width: 74% !important;
  }

  .line2 {
    width: 92% !important;
  }
}

@media (min-width: 1400px) and (max-width: 1450px) {
  .line1 {
    width: 75% !important;
  }

  .line2 {
    width: 92% !important;
  }
}

@media (min-width: 1450px) and (max-width: 1500px) {
  .line1 {
    width: 76% !important;
  }

  .line2 {
    width: 92% !important;
  }
}

@media (min-width: 1500px) and (max-width: 1550px) {
  .line1 {
    width: 77% !important;
  }

  .line2 {
    width: 92% !important;
  }

  .bottom-transport {

    &-first-counter,
    &-third-counter {
      width: 226px !important;
    }
  }

  .bottom-transport-second-counter {
    width: 184px !important;
  }
}

@media (min-width: 1550px) and (max-width: 1600px) {
  .line1 {
    width: 78% !important;
  }

  .line2 {
    width: 93% !important;
  }

  .bottom-transport {

    &-first-counter,
    &-third-counter {
      width: 236px !important;
    }
  }

  .bottom-transport-second-counter {
    width: 193px !important;
  }
}

@media (min-width: 1600px) and (max-width: 1650px) {
  .line1 {
    width: 78% !important;
  }

  .line2 {
    width: 93% !important;
  }

  .bottom-transport {

    &-first-counter,
    &-third-counter {
      width: 246px !important;
    }
  }

  .bottom-transport-second-counter {
    width: 201px !important;
  }
}

@media (min-width: 1650px) and (max-width: 1700px) {
  .line1 {
    width: 79% !important;
  }

  .line2 {
    width: 93% !important;
  }

  .bottom-transport {

    &-first-counter,
    &-third-counter {
      width: 256px !important;
    }
  }

  .bottom-transport-second-counter {
    width: 209px !important;
  }
}

@media (min-width: 1700px) and (max-width: 1750px) {
  .line1 {
    width: 80% !important;
  }

  .line2 {
    width: 93% !important;
  }

  .bottom-transport {

    &-first-counter,
    &-third-counter {
      width: 266px !important;
    }
  }

  .bottom-transport-second-counter {
    width: 218px !important;
  }
}

@media (min-width: 1750px) and (max-width: 1800px) {
  .line1 {
    width: 81% !important;
  }

  .line2 {
    width: 94% !important;
  }

  .bottom-transport {

    &-first-counter,
    &-third-counter {
      width: 276px !important;
    }
  }

  .bottom-transport-second-counter {
    width: 226px !important;
  }
}

@media (min-width: 1800px) and (max-width: 1850px) {
  .line1 {
    width: 82% !important;
  }

  .bottom-transport {

    &-first-counter,
    &-third-counter {
      width: 286px !important;
    }
  }

  .bottom-transport-second-counter {
    width: 234px !important;
  }
}

@media (min-width: 1850px) and (max-width: 1900px) {
  .bottom-transport {

    &-first-counter,
    &-third-counter {
      width: 295px !important;
    }
  }

  .bottom-transport-second-counter {
    width: 242px !important;
  }
}

@media (min-width: 1900px) and (max-width: 1950px) {}

@media (min-width: 1950px) and (max-width: 2000px) {}