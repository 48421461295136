.edit-excavator-padding {
    padding: 15px !important;
}

.add-new-entry {
    color: #fff;
    text-decoration: none;
    width: 100%;
    font-weight: bold !important;
    font-size: 16px !important;
    height: 36px !important;
    background-color: #444CF7 !important;
}

.submit-button {
    color: #fff;
    text-decoration: none;
    font-weight: bold !important;
    font-size: 16px !important;
    height: 36px !important;
    background-color: #444CF7 !important;
}

.action-button {
    justify-content: end;
}

.card-box {
    width: 400px;
    min-height: 150px;
    box-shadow: 0 2px 2px rgba(124, 103, 103, 0.34);
    margin: 0 0 20px 0;

    .card-content {
        display: flex;
    }

    .card-button {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        a {
            color: black;
        }

        .card-icons {
            border: 1px solid rgba(140, 136, 136, 0.456);
            width: max-content;
            padding: 10px;
            border-radius: 5px;
        }

        .card-icons-delete {
            color: #ff4d4f;
        }

        .list-item-icon {
            min-width: max-content !important;
            padding: 0;
        }

        .card-icons-images {
            border: 1px solid rgba(140, 136, 136, 0.456);
            width: 35px !important;
            padding: 4px;
            border-radius: 5px;
        }
    }
}

.agency-details-margin {
    padding: 0 15px !important;
}

.no-data-available {
    padding: 25px 0;
    font-size: 16px;
}

.sub-headings {
    font-size: 18px;
    padding: 10px 0;
    font-weight: bold;
    text-decoration: underline;
}

.space-between-rows {
    margin-bottom: 10px;
    background-color: #f1f1f1;
    padding: 25px;
    border-radius: 5px;
}


.ant-form-item .ant-form-item-row .ant-form-item-label {
    min-width: 100%;
    text-align: left;
}

.show-agency-values {
    width: max-content;
    min-width: 100%;
    background-color: #f1f1f1;
    color: black;
    border-radius: 5px !important;
    padding: 5px 10px;
    margin-bottom: 5px;
}

.form-input-box {
    width: 100%;
    color: black;
    border-radius: 5px !important;
    padding: 5px 10px;
    margin-bottom: 5px;
    height: 40px;
}

.form-select-box {
    color: black;
    border-radius: 5px !important;
    height: 40px;
}

.agency-project-rate-display {
    display: flex;
}

.sub-module-create-agency {
    border: 1px solid #d1d1d1;
    padding: 20px;
    border-radius: 5px;
}

.marginBottom15 {
    margin-bottom: 15px;
}

.add-more {
    margin: 15px 0 0;
    text-align: right;
}

.loading-rate-element {
    width: 100%;
    display: flex;
}

.delete-button-add-more {
    min-width: 32px !important;
}

.add-more-whole-grid {
    text-align: center;
}

.submit-button-area {
    margin-top: 15px;
    text-align: center;
}

.submit-button-area button {
    margin: 0 5px 0;
}

.remove-main-block-element {
    position: relative;
    text-align: right;
}

.remove-main-block-element button {
    position: absolute;
    right: -8px;
    bottom: -22px;
}

.input-error-message {
    color: #ff4d4f;
}

.input-error {
    border-color: #ff4d4f;
    box-shadow: 0 0px 2px #ff4d4f;
}

.pagination-box {
    padding: 50px 0;
    width: 100%;
    display: flex;
    justify-content: center;
}

.marginBottom20 {
    margin-bottom: 20px;
}

.disabled-input {
    color: #666 !important;

    span {
        color: #666 !important;
    }

    input {
        color: #666 !important;
    }
}

/* responsive css starts from here */
@media (max-width: 576px) {
    .card-box {
        width: 100% !important;
    }

    .add-new-entry {
        margin-top: 10px !important;
    }
}

@media (min-width: 900px) and (max-width: 1199px) {
    .card-box {
        width: 280px !important;
    }

    .add-new-entry {
        margin-top: 10px !important;
    }
}

@media (min-width: 1200px) and (max-width: 1450px) {
    .card-box {
        width: 280px !important;
    }

    .add-new-entry {
        margin-top: 10px !important;
    }
}

@media (min-width: 1451px) and (max-width: 1500px) {
    .card-box {
        width: 370px !important;
    }

    .add-new-entry {
        margin-top: 10px !important;
    }
}

@media (min-width: 1501px) and (max-width: 1576px) {
    .card-box {
        width: 400px !important;
    }

    .add-new-entry {
        margin-top: 10px !important;
    }
}

@media (min-width: 1577px) and (max-width: 1799px) {
    .card-box {
        width: 430px !important;
    }

    .add-new-entry {
        margin-top: 10px !important;
    }
}

@media (min-width: 1800px) {
    .card-box {
        width: 450px !important;
    }

    .add-new-entry {
        margin-top: 10px !important;
    }
}