.generate-transport-padding {
  padding: 15px !important;
}

.back-new-entry {
  width: max-content;
  color: #ffffff !important;
  background-color: #444cf7 !important;
  position: relative;
  font-weight: bold !important;
  font-size: 16px !important;
}

.table-row-cancelled {
  color: red !important;
}

button.submit-button-disabled {
  background-color: #c3c3c3 !important;
  color: #5b5b5b !important;
}

.total-bill-table {
  padding: 15px !important;
}

.pending-bill-table {
  padding: 15px !important;
}


.h6 {
  font-size: 30px !important;
  margin-bottom: 5px !important;
}

.para {
  font-size: 16px !important;
}

.middle-boundary-transport {
  background-color: #682a01;
  display: flex;
  justify-content: space-between;
  margin: 0 6px !important;
  padding: 0px 15px;
  border-radius: 5px;

  .text-boundary-tranport {
    letter-spacing: 1px;
  }

  .total-amount-transport {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
  }
}

/**
  * Agency selection area css
  */

.generate-transport-padding-wrapper {
  padding: 15px !important;
}


.loading_agency_selection_area {
  display: flex;
  justify-content: space-between;
}

.loading_agency_options {
  margin-bottom: 0 !important
}

.bill_number_heading {
  margin: 5px 10px 0 0;
  text-align: right !important;
}

.bill_no_input,
.bill_no_input:hover {
  background-color: #D2D4FD;
  border-radius: 0px;
  outline: none;
  border: 1px solid transparent !important;
}

.loading-pending-padding-loading-agency-dropdown {
  padding: 15px 15px 0px 15px !important;
}

.loading-pending-padding-agency-title {
  padding: 0px 15px 0 15px !important;
}

.loading_agency_title_row {
  background-color: #0080FF !important;
  color: #fff;
  padding: 3px 10px 3px 11px;
  border-radius: 5px;
}

.loading_agency_title,
.loading_agency_title_payable_amount {
  font-size: 18px;
}

.loading_agency_title_payable_amount {
  text-align: right !important;
}

.add-more-weight-rate-area {
  background-color: #F7F7F7 !important;
  border-top: 1px solid #b8b8b8;
  border-left: 1px solid #b8b8b8;
  border-right: 1px solid #b8b8b8;
  border-bottom: 1px solid #b8b8b8;
  border-radius: 5px 5px 0 0;
}

.add-more-weight-rate-area button:hover {
  background-color: transparent !important;
  opacity: 0.7;
}

.add-more-weight-rate-area label {
  color: #5b5b5b !important;
}

.add-more-weight-rate-area-grid {
  padding: 15px !important;
}

.add-more-weight-rate-area-grid p {
  font-size: 16px;
  color: #5b5b5b !important;
}


.border-right {
  border-right: 1px solid #b8b8b8;
}

.add-more-weight-rate-area-submit {
  background-color: #F7F7F7 !important;
  border-left: 1px solid #b8b8b8;
  border-right: 1px solid #b8b8b8;
  border-bottom: 1px solid #b8b8b8;
  padding: 10px 20px 10px 20px !important;
  border-radius: 0 0 5px 5px;
}

.add-new-entry {
  color: #fff;
  text-decoration: none;
  width: max-content;
  font-weight: bold !important;
  font-size: 16px !important;
  height: 36px !important;
  background-color: #444CF7 !important;
}

.right-align {
  text-align: right !important;
}

.right-align button.export-button {
  margin-right: 10px;
}

.add_more_rate_weight {
  color: #444cf7 !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
}

.weight_rate_add_more_area label {
  font-size: 12px !important;
}

/**
   * Agency selection area css ends
   */

.bottom-transport-counter {
  display: flex;
  gap: 1.5rem;
  justify-content: space-between;
  margin: 0 10px;

  h6 {
    text-align: center;
  }

  .bottom-transport-first-counter {
    width: 210px;
    height: 130px;
    padding: 22px 0px;
    background-color: #42536c;
    border-radius: 5px;
    text-align: center;
  }

  .bottom-transport-second-counter {
    width: 210px;
    height: 130px;
    padding: 22px 0px;
    background-color: #28bbc3;
    border-radius: 5px;
    text-align: center;
    text-align-last: center;
  }

  .bottom-transport-third-counter {
    width: 210px;
    height: 130px;
    padding: 22px 0px;
    background-color: #43377c;
    border-radius: 5px;
    text-align: center;
    text-align-last: center;
  }

  .bottom-transport-fourth-counter {
    width: 210px;
    height: 130px;
    padding: 22px 0px;
    background-color: #8578e3;
    border-radius: 5px;
    text-align: center;
    text-align-last: center;
  }

  .bottom-transport-fivth-counter {
    width: 210px;
    height: 130px;
    padding: 22px 0px;
    background-color: #e7655f;
    border-radius: 5px;
    text-align: center;
    text-align-last: center;
  }

  .bottom-transport-sixth-counter {
    width: 210px;
    height: 130px;
    padding: 22px 0px;
    background-color: #574c8f;
    border-radius: 5px;
    text-align: center;
    text-align-last: center;
  }

  .bottom-transport-seventh-counter {
    width: 210px;
    height: 130px;
    padding: 22px 0px;
    background-color: #2c7bff;
    border-radius: 5px;
    text-align: center;
    text-align-last: center;
  }
}

.submit-button-area {
  margin: 15px 0;
  text-align: center;

  button {
    margin: 0 5px 0;
  }

  .submit-button {
    color: #fff;
    text-decoration: none;
    width: max-content;
    font-weight: bold !important;
    font-size: 16px !important;
    height: 36px !important;
    background-color: #444CF7 !important;
  }
}



/**
 * CSS for validation error in popup
 */
.agency-errors-table {
  width: 100%;
  height: auto;
  max-height: 70%;
}

.error-agency-excel-display-data {
  height: auto;
  max-height: 500px;
  width: 100% !important;
  overflow-y: scroll;
}

.agency-errors-table th {
  padding: 10px;
  border: 1px solid #ccc;
  font-size: 16px;
  background-color: #444CF7;
  color: #fff;
}

.agency-errors-table td {
  padding: 10px;
  border: 1px solid #ccc;
  font-size: 15px;
  color: #ff4d4f;
  font-weight: bold;
}

.agency-errors-table td.agency-errors-table-first-td {
  text-align: center;
}

.matching-agencies-div-element .matching-agencies-area-heading {
  background-color: #444CF7 !important;
  padding: 10px;
  color: #fff;
}

.matching-agencies-div-element h3 {
  font-size: 15px;
  text-align: center;
}

/**
 * CSS for validation error in popup ends
 */


@media (max-width: 575px) {
  .round-off-wrapper {
    height: 400px !important;
  }

  .generate-transport-padding .right-align {
    text-align: left !important;
  }

  .round-off-weight-wrapper-MT {
    .round-ui-button {
      float: none !important;
      margin-top: 0px !important;
    }
  }

  .round-off-hsd-wrapper-LTR {
    .round-ui-button {
      float: none !important;
      margin-top: 0px !important;
    }
  }

  .quanity-rate-flex {
    display: block !important;
    gap: 0 !important;

    .input-field-first {
      width: 80px !important;
    }

    .input-field-second {
      width: 80px !important;
    }

    .delete-icon {
      margin-top: 0 !important;
    }
  }

  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 250px !important;
      padding: 22px 10px !important;
    }
  }

  .middle-boundary-transport {
    width: 500px !important;
  }
}

@media (min-width: 576px) and (max-width: 650px) {
  .round-off-wrapper {
    height: 400px !important;
  }

  .round-off-weight-wrapper-MT {
    .round-ui-button {
      float: none !important;
      margin-top: 0px !important;
    }
  }

  .generate-transport-padding .right-align {
    text-align: left !important;
  }

  .round-off-hsd-wrapper-LTR {
    .round-ui-button {
      float: none !important;
      margin-top: 0px !important;
    }
  }

  .quanity-rate-flex {
    gap: 0.5rem !important;
    display: block !important;

    .input-field-first {
      width: 120px !important;
    }

    .input-field-second {
      width: 120px !important;
    }

    .delete-icon {
      margin-top: 0 !important;
    }
  }

  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 300px !important;
      padding: 22px 10px !important;
    }
  }
}

@media (min-width: 650px) and (max-width: 699px) {
  .round-off-wrapper {
    height: 350px !important;
  }

  .round-off-weight-wrapper-MT {
    .round-ui-button {
      float: none !important;
      margin-top: 0px !important;
    }
  }

  .generate-transport-padding .right-align {
    text-align: left !important;
  }

  .round-off-hsd-wrapper-LTR {
    .round-ui-button {
      float: none !important;
      margin-top: 0px !important;
    }
  }

  .quanity-rate-flex {
    display: block !important;
    gap: 0.5rem;

    .input-field-first {
      width: 160px !important;
    }

    .input-field-second {
      width: 160px !important;
    }

    .delete-icon {
      margin-top: 0 !important;
    }
  }

  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 175px !important;
      padding: 22px 10px !important;
    }
  }
}

@media (min-width: 700px) and (max-width: 750px) {
  .round-off-weight-wrapper-MT {
    .round-ui-button {
      float: none !important;
      margin-top: 0px !important;
    }
  }

  .generate-transport-padding .right-align {
    text-align: left !important;
  }

  .round-off-hsd-wrapper-LTR {
    .round-ui-button {
      float: none !important;
      margin-top: 0px !important;
    }
  }

  .quanity-rate-flex {
    gap: 0.5rem !important;

    .input-field-first {
      width: 70px !important;
    }

    .input-field-second {
      width: 70px !important;
    }
  }

  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 200px !important;
      padding: 22px 10px !important;
    }
  }
}

@media (min-width: 750px) and (max-width: 799px) {
  .round-off-weight-wrapper-MT {
    .round-ui-button {
      float: none !important;
      margin-top: 0px !important;
    }
  }

  .generate-transport-padding .right-align {
    text-align: left !important;
  }

  .round-off-hsd-wrapper-LTR {
    .round-ui-button {
      float: none !important;
      margin-top: 0px !important;
    }
  }

  .quanity-rate-flex {
    .input-field-first {
      width: 75px !important;
    }

    .input-field-second {
      width: 75px !important;
    }
  }

  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 225px !important;
      padding: 22px 10px !important;
    }
  }
}

@media (min-width: 800px) and (max-width: 850px) {
  .round-off-weight-wrapper-MT {
    .round-ui-button {
      float: none !important;
      margin-top: 0px !important;
    }
  }

  .generate-transport-padding .right-align {
    text-align: left !important;
  }

  .round-off-hsd-wrapper-LTR {
    .round-ui-button {
      float: none !important;
      margin-top: 0px !important;
    }
  }

  .quanity-rate-flex {
    .input-field-first {
      width: 90px !important;
    }

    .input-field-second {
      width: 90px !important;
    }
  }

  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 160px !important;
      height: 125px !important;
      padding: 22px 5px !important;
    }
  }
}

@media (min-width: 850px) and (max-width: 900px) {
  .round-off-weight-wrapper-MT {
    .round-ui-button {
      float: none !important;
      margin-top: 0px !important;
    }
  }

  .generate-transport-padding .right-align {
    text-align: left !important;
  }

  .round-off-hsd-wrapper-LTR {
    .round-ui-button {
      float: none !important;
      margin-top: 0px !important;
    }
  }

  .quanity-rate-flex {
    .input-field-first {
      width: 100px !important;
    }

    .input-field-second {
      width: 100px !important;
    }
  }

  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 175px !important;
      height: 125px !important;
      padding: 22px 5px !important;
    }
  }
}

@media (min-width: 900px) and (max-width: 950px) {

  .quanity-rate-flex {
    .input-field-first {
      width: 115px !important;
    }

    .input-field-second {
      width: 115px !important;
    }
  }

  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 138px !important;
      height: 125px !important;
      padding: 22px 10px !important;
    }
  }

  .bottom-transport-counter {
    justify-content: unset !important;
  }
}

@media (min-width: 950px) and (max-width: 1000px) {
  .quanity-rate-flex {
    .input-field-first {
      width: 125px !important;
    }

    .input-field-second {
      width: 125px !important;
    }
  }

  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 150px !important;
      padding: 22px 10px !important;
    }
  }

  .bottom-transport-counter {
    justify-content: unset !important;
  }
}

@media (min-width: 1000px) and (max-width: 1050px) {
  .quanity-rate-flex {
    .input-field-first {
      width: 140px !important;
    }

    .input-field-second {
      width: 140px !important;
    }
  }

  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 163px !important;
      padding: 22px 10px !important;
    }
  }

  .bottom-transport-counter {
    justify-content: unset !important;
  }
}

@media (min-width: 1050px) and (max-width: 1100px) {
  .quanity-rate-flex {
    .input-field-first {
      width: 150px !important;
    }

    .input-field-second {
      width: 150px !important;
    }
  }

  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 174px !important;
      padding: 22px 10px !important;
    }
  }


  .bottom-transport-counter {
    justify-content: unset !important;
  }
}

@media (min-width: 1100px) and (max-width: 1150px) {
  .quanity-rate-flex {
    .input-field-first {
      width: 165px !important;
    }

    .input-field-second {
      width: 165px !important;
    }
  }

  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 188px !important;
      padding: 22px 10px !important;
    }
  }

  .bottom-transport-counter {
    justify-content: unset !important;
  }
}

@media (min-width: 1150px) and (max-width: 1199px) {

  .back-new-entry {
    left: -5px !important;
  }

  .quanity-rate-flex {
    .input-field-first {
      width: 175px !important;
    }

    .input-field-second {
      width: 175px !important;
    }
  }

  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 200px !important;
      padding: 22px 10px !important;
    }
  }

  .bottom-transport-counter {
    justify-content: unset !important;
  }
}

@media (min-width: 1200px) and (max-width: 1250px) {
  .back-new-entry {
    left: -40px !important;
  }

  .quanity-rate-flex {
    .input-field-first {
      width: 180px !important;
    }

    .input-field-second {
      width: 180px !important;
    }
  }

  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 135px !important;
      padding: 22px 10px !important;
    }
  }
}

@media (min-width: 1250px) and (max-width: 1300px) {
  .back-new-entry {
    left: -30px !important;
  }


  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 140px !important;
      padding: 22px 10px !important;
    }
  }
}

@media (min-width: 1300px) and (max-width: 1350px) {
  .back-new-entry {
    left: -25px !important;
  }

  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 150px !important;
      padding: 22px 10px !important;
    }
  }
}

@media (min-width: 1350px) and (max-width: 1400px) {
  .back-new-entry {
    left: -18px !important;
  }

  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 158px !important;
    }
  }
}

@media (min-width: 1400px) and (max-width: 1450px) {
  .back-new-entry {
    left: -10px !important;
  }

  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 167px !important;
    }
  }
}

@media (min-width: 1450px) and (max-width: 1499px) {
  .back-new-entry {
    left: -10px !important;
  }

  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 175px !important;
    }
  }

}

@media (min-width: 1500px) and (max-width: 1550px) {
  .back-new-entry {
    left: -10px !important;
  }

  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 154px !important;
    }
  }

  .bottom-transport {

    &-second-counter,
    &-third-counter {
      padding: 22px 14px !important;
    }
  }
}

@media (min-width: 1550px) and (max-width: 1599px) {
  .back-new-entry {
    left: -10px !important;
  }

  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 161px !important;
    }
  }
}

@media (min-width: 1600px) and (max-width: 1750px) {
  .back-new-entry {
    left: -10px !important;
  }

  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 166px !important;
    }
  }
}

@media (min-width: 1650px) and (max-width: 1700px) {

  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 175px !important;
    }
  }
}

@media (min-width: 1700px) and (max-width: 1750px) {
  
  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 180px !important;
    }
  }
}

@media (min-width: 1750px) and (max-width: 1800px) {

  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 190px !important;
    }
  }
}

@media (min-width: 1800px) and (max-width: 1850px) {

  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 195px !important;
    }
  }
}



@media (min-width: 1850px) and (max-width: 1900px) {

  .bottom-transport {

    &-first-counter,
    &-second-counter,
    &-third-counter,
    &-fourth-counter,
    &-fivth-counter,
    &-sixth-counter,
    &-seventh-counter {
      width: 200px !important;
    }
  }
}


@media (min-width: 1900px) and (max-width: 1950px) {
 
}

@media (min-width: 1950px) and (max-width: 2000px) {
 
}