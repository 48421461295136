.header-fixed-loading {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #fff;
  padding: 0px 20px;
  border-bottom: 1px solid #f1f1f1;
}

.ant-form-item-radio {
  margin-bottom: 10px !important;
}

.gridmargintop10 {
  margin-top: 25px !important;
}

.gridmargintop5 {
  margin-top: 5px !important;
}

.add-new-entry {
  color: #fff;
  text-decoration: none;
  width: max-content;
  font-weight: bold !important;
  font-size: 16px !important;
  height: 36px !important;
  background-color: #444CF7 !important;
}
.right-align {
  text-align: right !important;
}

.add-new-filter-entry {
  font-weight: bold !important;
  background-color: #444CF7 !important;
}

.cancelled-loading-challan-no {
  color: red;
}

.ant-upload,
.ant-upload-list-item-container {
  width: 100% !important;
}

.reset-filter-button {
  color: #fff;
  text-decoration: none;
  font-weight: bold !important;
  font-size: 16px !important;
  height: 36px !important;
  width: 100%;
  background-color: #444CF7 !important;
}

.filter-new-entry {
  color: #444CF7 !important;
  border: 1px solid #444CF7 !important;
  font-weight: bold !important;
}

.import-new-entry {
  color: #444CF7 !important;
  border: 1px solid #444CF7 !important;
  font-weight: bold !important;
}

.upload-file-entry {
  color: #444CF7 !important;
  border: 1px solid #444CF7 !important;
  width: max-content !important;
  margin-bottom: 30px !important;
}

.click-here-link {
  color: #444CF7 !important;
}

.model-buttons {
  margin: 0 10px !important;
}

.search-button-area {
  text-align: right;

  button {
    margin: 0 5px 0;
  }
}

.card-box {
  width: 400px;
  height: 160px;
  box-shadow: 0 0px 6px 2px rgba(124, 103, 103, 0.34);
  margin: 0 0 30px 0;
  min-height: 180px !important;

  .card-content {
    display: flex;
  }

  .AgencyDetails {
    width: 100%;
    padding: 5px;
  }

  .AgencyDetailsValue {
    margin-left: 5px;
    font-weight: bold;
  }

  .card-button {
    display: flex;

    ul.action-items {
      list-style-type: none;
      margin: 0 5px 0 0;

      li {
        list-style-type: none;
        margin: 0;
        padding: 0;
        height: 37px;
      }

      li:last-child {
        margin: 0;
      }
    }

    ul:last-child {
      margin: 0;
    }

    .card-icons {
      border: 1px solid rgba(140, 136, 136, 0.456);
      width: 30px;
      padding: 7px;
      border-radius: 5px;
      transition: all 0.2s ease-in-out;

      &:hover {
        box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
      }
    }

    .card-icons-images {
      border: 1px solid rgba(140, 136, 136, 0.456);
      width: 30px !important;
      height: 30px !important;
      padding: 4px;
      border-radius: 5px;

      &.create-unload-card-img {
        background-color: #458D34 !important;
      }

      &.create-hsd-card-img {
        background-color: #668F99 !important;
      }

      &.unload-disabled {
        opacity: 0.7;
        background: #f1f1f1;
      }
    }
  }
}

.marginBottom20 {
  margin-bottom: 20px;
}

.marginTop20 {
  padding: 20px !important;
  background-color: #a1ccf783 !important;
}

.test {
  position: fixed !important;
}

.pagination-box {
  padding: 50px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 99999;
}

.no-data-available {
  padding: 20px 20px;
  font-size: 18px;
}

/** filter box css starts from here **/
.filter-box:before {
  backdrop-filter: blur(5px);
  opacity: 0.6;
}

.filter-box {
  position: fixed;
  right: 0px;
  background: #fff;
  border: 1px solid #000;
  z-index: 99999;
  top: 64px;
  width: 500px;

  .filter-form {
    padding: 10px;
  }
}

/** excel import errors modal css */
.error-import-modal-heading {
  font-size: 26px;
  text-transform: capitalize;
  padding-bottom: 10px;
  text-align: center;
}

.error-import-modal {
  height: auto;
  max-height: 70%;
}

.error-import-excel-display-data {
  height: auto;
  max-height: 500px;
  width: 100% !important;
  overflow-y: scroll;
}

.error-import-excel-display-data>table {
  border: 1px solid #ccc;
}

.error-import-excel-display-data>table th {
  background-color: #444CF7;
  color: #fff;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
}

.error-import-excel-display-data>table td {
  border: 1px solid #ccc;
  color: red;
  font-weight: bold;
}


.error-import-excel-display-data>table td table td {
  border: 1px solid transparent;
}

.error-import-excel-display-data ul {
  list-style-type: none;
  margin: 0 25px 0;
}

.error-import-excel-display-data ul li {
  list-style-type: square;
  line-height: 30px;
  color: red;
}

.total-items {
  width: 400px !important;
  padding: 20px;
  border-radius: 5px !important;
  color: snow !important;
  margin: 10px 0 !important;
  text-align: right !important;
}

.header-grid-flex {
  display: flex;
  justify-content: space-between;
}

.header-text-fontSize {
  font-size: 2.5rem;
}

.total-item-first {
  background-color: #455785;
}

.total-item-second {
  background-color: #EA383A;
}

.total-item-third {
  background-color: #E8C511;
}

.total-item-fourth {
  background-color: #9F5542;
}

.disabled-icon {
  pointer-events: none;
  background-color: #ADADAD;
  opacity: 0.7;
  /* or any other style you prefer for a disabled icon */
}

.select-new-entry {
  width: 200px !important;
}

.search-new-entry {
  width: 200px !important;
}

.form-select-div-select {
  position: relative;
  left: -90px;
}

.form-select-div-search {
  position: relative;
  left: -110px !important;
}

// .spinning-loader {
//   margin: 0 auto !important;
// }

.content {
  padding: 50px;
}

.generated-bill-ribbon {
  cursor: default;
  p {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #e06b00;
    padding: 2px 10px;
    border-top-left-radius: 47px;
    border-bottom-left-radius: 47px;
    margin: 18px -1px;
    color: #fff;
    font-weight: bold;

    a {
      color: snow;
    }
  }
}

.alert-bottom-border {
  border-bottom: 1px solid #dcdbdb;

  .alert-text {
    text-align: center;
    color: #00072B;
    font-weight: bold;
  }
}

.alert-description {
  text-align: center;
  padding: 25px 0;
}

.generated-entries-modal-table {
  border: 1px solid #dcdbdb;
  border-radius: 5px;
  .generated-entries-model-heading {
    border-bottom: 1px solid #dcdbdb;
    background-color: #fafafa;
    display: flex;
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 10px 0;
  }

  .generated-entries-model-description {
    padding: 10px 0;
  }
}

@media (max-width: 275px) {
  .model-buttons {
    margin: 10px 0 !important;
  }
}

@media (min-width: 275px) and (max-width: 290px) {
  .model-buttons {
    margin: 0px 5px !important;
  }
}

/** responsive CSS starts here **/
@media (max-width: 576px) {

  .header-fixed-loading {
    width: 100vw !important;
    margin-top: 10px !important; 
  }

  .marginTop20 {
    width: 100vw !important;
  }

  .card-box {
    width: 300px !important;
    height: 210px !important;
  }

  .add-new-entry {
    margin-left: 110px !important;
  }

  .filter-new-entry {
    margin-left: 15px !important;
  }

  .import-new-entry {
    margin-left: -79px !important;
  }

  .select-new-entry {
    width: 130px !important;
  }

  .search-new-entry {
    width: 130px !important;
  }

  .form-select-div-select {
    left: 0px !important;
    margin-top: -30px !important;
  }

  .form-select-div-search {
    left: -80px !important;
    margin-top: 10px !important;
  }

  .headerMarginTop{ 
    overflow-x: scroll !important;
  }

  .total-items {
    width: 220px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 220px !important;
    }

    &.total-item-third {
      left: 440px !important;
    }

    &.total-item-fourth {
      left: 660px !important;
    }
  }
}

@media (min-width: 577px) and (max-width: 699px) {
  .card-box {
    width: 100%;
  }

  .marginTop20 {
    width: 100vw !important;
  }

  .header-fixed-loading {
    margin-top: 10px !important;
  }

  .add-new-entry {
    margin-left: 120px !important;
  }

  .filter-new-entry {
    margin-left: 21px !important;
  }

  .import-new-entry {
    margin-left: -76px !important;
  }

  .select-new-entry {
    width: 150px !important;
  }

  .search-new-entry {
    width: 150px !important;
  }

  .form-select-div-select {
    left: 10px !important;
  }

  .form-select-div-search {
    left: -76px !important;
  }

  .headerMarginTop{ 
    overflow-x: scroll !important;
  }

  .total-items {
    width: 230px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 220px !important;
    }

    &.total-item-third {
      left: 440px !important;
    }

    &.total-item-fourth {
      left: 660px !important;
    }
  }
}

@media (min-width: 700px) and (max-width: 750px) {
  .header-fixed-loading {
    margin-top: 10px !important;
  }

  .add-new-entry {
    margin-left: 60px !important;
  }

  .filter-new-entry {
    margin-left: -24px !important;
  }

  .import-new-entry {
    margin-left: -105px !important;
  }

  .form-select-div-select {
    left: -40px !important;
  }

  .form-select-div-search {
    left: -105px !important;
  }

  .select-new-entry {
    width: 150px !important;
  }

  .search-new-entry {
    width: 150px !important;
  }

  .headerMarginTop{ 
    overflow-x: scroll !important;
  }

  .total-items {
    width: 230px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 207px !important;
    }

    &.total-item-third {
      left: 415px !important;
    }

    &.total-item-fourth {
      left: 624px !important;
    }
  }
}

@media (min-width: 750px) and (max-width: 800px) {
  .header-fixed-loading {
    margin-top: 10px !important;
  }

  .add-new-entry {
    margin-left: 30px !important;
  }

  .filter-new-entry {
    margin-left: -46px !important;
  }

  .import-new-entry {
    margin-left: -120px !important;
  }

  .form-select-div-select {
    left: -35px !important;
  }

  .form-select-div-search {
    left: -120px !important;
  }

  .headerMarginTop{ 
    overflow-x: scroll !important;
  }

  .total-items {
    width: 230px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 207px !important;
    }

    &.total-item-third {
      left: 415px !important;
    }

    &.total-item-fourth {
      left: 624px !important;
    }
  }
}

@media (min-width: 800px) and (max-width: 850px) {
  .card-box {
    width: 100%;
  }

  .header-fixed-loading {
    margin-top: 10px !important;
  }

  .add-new-entry {
    margin-left: 0px !important;
  }

  .filter-new-entry {
    margin-left: -67px !important;
  }

  .import-new-entry {
    margin-left: -132px !important;
  }

  .form-select-div-select {
    left: -35px !important;
  }

  .form-select-div-search {
    left: -132px !important;
  }

  .headerMarginTop{ 
    overflow-x: scroll !important;
  }

  .total-items {
    width: 230px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 200px !important;
    }

    &.total-item-third {
      left: 400px !important;
    }

    &.total-item-fourth {
      left: 600px !important;
    }
  }
}

@media (min-width: 850px) and (max-width: 900px) {
  .card-box {
    width: 100%;
  }

  .header-fixed-loading {
    margin-top: 10px !important;
  }

  .add-new-entry {
    margin-left: 0px !important;
  }

  .filter-new-entry {
    margin-left: -74px !important;
  }

  .import-new-entry {
    margin-left: -146px !important;
  }

  .form-select-div-select {
    left: -80px !important;
  }

  .form-select-div-search {
    left: -146px !important;
  }

  .headerMarginTop{ 
    overflow-x: scroll !important;
  }

  .total-items {
    width: 230px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 200px !important;
    }

    &.total-item-third {
      left: 400px !important;
    }

    &.total-item-fourth {
      left: 600px !important;
    }
  }
}

@media (min-width: 900px) and (max-width: 950px) {
  .card-box {
    width: 300px;
    height: 210px;
  }

  .add-new-entry {
    margin-left: -30px !important;
  }

  .filter-new-entry {
    margin-left: -65px !important;
  }

  .import-new-entry {
    margin-left: -97px !important;
  }

  .select-new-entry {
    width: 50px !important;
  }

  .search-new-entry {
    width: 50px !important;
  }

  .form-select-div-select {
    left: -80px !important;
  }

  .form-select-div-search {
    left: -5px !important;
  }

  .headerMarginTop{ 
    overflow-x: scroll !important;
  }

  .total-items {
    width: 225px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 140px !important;
    }

    &.total-item-third {
      left: 280px !important;
    }

    &.total-item-fourth {
      left: 420px !important;
    }
  }
}

@media (min-width: 951px) and (max-width: 1000px) {
  .card-box {
    width: 330px;
    height: 210px;
  }

  .add-new-entry {
    margin-left: -20px !important;
  }

  .filter-new-entry {
    margin-left: -43px !important;
  }

  .import-new-entry {
    margin-left: -64px !important;
  }

  .select-new-entry {
    width: 80px !important;
  }

  .search-new-entry {
    width: 80px !important;
  }

  .form-select-div-select {
    left: -70px !important;
  }

  .form-select-div-search {
    left: -10px !important;
  }

  .headerMarginTop{ 
    overflow-x: scroll !important;
  }

  .total-items {
    width: 235px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 140px !important;
    }

    &.total-item-third {
      left: 280px !important;
    }

    &.total-item-fourth {
      left: 420px !important;
    }
  }
}

@media (min-width: 1001px) and (max-width: 1050px) {
  .card-box {
    width: 350px;
    height: 210px;
  }

  .add-new-entry {
    margin-left: -10px !important;
  }

  .filter-new-entry {
    margin-left: -27px !important;
  }

  .import-new-entry {
    margin-left: -40px !important;
  }

  .total-items {
    width: 355px !important;
  }

  .select-new-entry {
    width: 110px !important;
  }

  .search-new-entry {
    width: 110px !important;
  }

  .form-select-div-select {
    left: -63px !important;
  }

  .form-select-div-search {
    left: -25px !important;
  }

  .headerMarginTop{ 
    overflow-x: scroll !important;
  }

  .total-items {
    width: 240px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 140px !important;
    }

    &.total-item-third {
      left: 280px !important;
    }

    &.total-item-fourth {
      left: 420px !important;
    }
  }
}

@media (min-width: 1051px) and (max-width: 1100px) {
  .card-box {
    width: 370px;
    height: 210px;
  }

  .add-new-entry {
    margin-left: -10px !important;
  }

  .filter-new-entry {
    margin-left: -25px !important;
  }

  .import-new-entry {
    margin-left: -35px !important;
  }

  .total-items {
    width: 380px !important;
  }

  .select-new-entry {
    width: 110px !important;
  }

  .search-new-entry {
    width: 110px !important;
  }

  .form-select-div-select {
    left: -50px !important;
  }

  .form-select-div-search {
    left: 0px !important;
  }

  .headerMarginTop{ 
    overflow-x: scroll !important;
  }

  .total-items {
    width: 250px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 140px !important;
    }

    &.total-item-third {
      left: 280px !important;
    }

    &.total-item-fourth {
      left: 420px !important;
    }
  }
}

@media (min-width: 1100px) and (max-width: 1200px) {
  .card-box {
    width: 400px;
    height: 195px;
  }

  .filter-new-entry {
    margin-left: -10px !important;
  }

  .import-new-entry {
    margin-left: -20px !important;
  }

  .select-new-entry {
    width: 130px !important;
  }

  .search-new-entry {
    width: 130px !important;
  }

  .form-select-div-select {
    left: -50px !important;
  }

  .form-select-div-search {
    left: -15px !important;
  }

  .headerMarginTop{ 
    overflow-x: scroll !important;
  }

  .total-items {
    width: 250px !important;
    margin: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    position: relative;

    &.total-item-second {
      left: 130px !important;
    }

    &.total-item-third {
      left: 260px !important;
    }

    &.total-item-fourth {
      left: 390px !important;
    }
  }
}

@media (min-width: 1200px) and (max-width: 1299px) {
  .card-box {
    width: 290px;
    height: 210px;
  }

  .add-new-entry {
    margin-left: -50px !important;
  }

  .filter-new-entry {
    margin-left: -100px !important;
  }

  .import-new-entry {
    margin-left: -148px !important;
  }

  .select-new-entry {
    width: 150px !important;
  }

  .search-new-entry {
    width: 150px !important;
  }

  .form-select-div-select {
    left: -250px !important;
  }

  .form-select-div-search {
    left: -290px !important;
  }

  .total-items {
    width: 220px !important;
  }
}

@media (min-width: 1300px) and (max-width: 1349px) {
  .card-box {
    width: 330px;
    height: 210px;
  }

  .add-new-entry {
    margin-left: -30px !important;
  }

  .filter-new-entry {
    margin-left: -80px !important;
  }

  .import-new-entry {
    margin-left: -128px !important;
  }

  .select-new-entry {
    width: 150px !important;
  }

  .search-new-entry {
    width: 150px !important;
  }

  .form-select-div-select {
    left: -220px !important;
  }

  .form-select-div-search {
    left: -250px !important;
  }

  .total-items {
    width: 240px !important;
  }
}

@media (min-width: 1350px) and (max-width: 1399px) {
  .card-box {
    width: 340px;
    height: 210px;
  }

  .add-new-entry {
    margin-left: -30px !important;
  }

  .filter-new-entry {
    margin-left: -80px !important;
  }

  .import-new-entry {
    margin-left: -128px !important;
  }

  .select-new-entry {
    width: 150px !important;
  }

  .search-new-entry {
    width: 150px !important;
  }

  .form-select-div-select {
    left: -220px !important;
  }

  .form-select-div-search {
    left: -250px !important;
  }

  .total-items {
    width: 255px !important;
  }
}

@media (min-width: 1400px) and (max-width: 1449px) {
  .card-box {
    width: 350px;
    height: 210px;
  }

  .add-new-entry {
    margin-left: -30px !important;
  }

  .filter-new-entry {
    margin-left: -70px !important;
  }

  .import-new-entry {
    margin-left: -108px !important;
  }

  .form-select-div-select {
    left: -240px !important;
  }

  .form-select-div-search {
    left: -300px !important;
  }

  .total-items {
    width: 265px !important;
  }
}

@media (min-width: 1450px) and (max-width: 1499px) {
  .card-box {
    width: 370px;
    height: 210px;
  }

  .add-new-entry {
    margin-left: -30px !important;
  }

  .filter-new-entry {
    margin-left: -60px !important;
  }

  .import-new-entry {
    margin-left: -88px !important;
  }

  .form-select-div-select {
    left: -220px !important;
  }

  .form-select-div-search {
    left: -280px !important;
  }

  .total-items {
    width: 280px !important;
  }
}

@media (min-width: 1500px) and (max-width: 1549px) {
  .card-box {
    width: 390px;
  }

  .add-new-entry {
    margin-left: -30px !important;
  }

  .filter-new-entry {
    margin-left: -60px !important;
  }

  .import-new-entry {
    margin-left: -88px !important;
  }

  .form-select-div-select {
    left: -210px !important;
  }

  .form-select-div-search {
    left: -260px !important;
  }

  .total-items {
    width: 290px !important;
  }
}

@media (min-width: 1550px) and (max-width: 1599px) {
  .card-box {
    width: 400px;
  }

  .add-new-entry {
    margin-left: -30px !important;
  }

  .filter-new-entry {
    margin-left: -60px !important;
  }

  .import-new-entry {
    margin-left: -88px !important;
  }

  .form-select-div-select {
    left: -200px !important;
  }

  .form-select-div-search {
    left: -250px !important;
  }

  .total-items {
    width: 300px !important;
  }
}

@media (min-width: 1600px) and (max-width: 1649px) {
  .card-box {
    width: 430px;
  }

  .filter-new-entry {
    margin-left: -30px !important;
  }

  .import-new-entry {
    margin-left: -60px !important;
  }

  .total-items {
    width: 320px !important;
  }

  .form-select-div-select {
    left: -170px !important;
  }

  .form-select-div-search {
    left: -210px !important;
  }
}

@media (min-width: 1650px) and (max-width: 1700px) {
  .card-box {
    width: 440px;
  }

  .filter-new-entry {
    margin-left: -20px !important;
  }

  .import-new-entry {
    margin-left: -40px !important;
  }

  .total-items {
    width: 330px !important;
  }

  .form-select-div-select {
    left: -145px !important;
  }

  .form-select-div-search {
    left: -180px !important;
  }
}

@media (min-width: 1701px) and (max-width: 1749px) {
  .card-box {
    width: 455px;
  }

  .total-items {
    width: 345px !important;
  }

  .form-select-div-search {
    left: -120px !important;
  }
}

@media (min-width: 1750px) and (max-width: 1799px) {
  .card-box {
    width: 470px;
  }

  .total-items {
    width: 355px !important;
  }

  .form-select-div-search {
    left: -110px !important;
  }
}

@media (min-width: 1800px) and (max-width: 1849px) {
  .card-box {
    width: 490px;
  }

  .total-items {
    width: 370px !important;
  }

  .form-select-div-search {
    left: -100px !important;
  }
}

@media (min-width: 1850px) and (max-width: 1899px) {
  .card-box {
    width: 500px;
  }

  .total-items {
    width: 380px !important;
  }
}

@media (min-width: 1900px) {
  .card-box {
    width: 520px !important;
  }
}