.transaction-summary-padding {
  padding: 15px !important;
}

.generate-new-entry {
  background-color: #444cf7 !important;
  color: #fff !important;
  width: max-content !important;
}

.transaction-summary-border {
  height: 700px;
  width: 100%;
  border: 1px solid #adadad;
  border-radius: 8px;
  position: relative;

  .transaction-transport-img {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .first-boundary-transaction {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background-color: #485196;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .tab-transaction {
    .summary-list-padding {
      padding: 20px 30px;

      .work-type-object-first {
        background-color: #fdfdfd;
        border: 1px solid #adadad;
        padding: 10px 0;

        .normal-para {
          color: #555555;
          padding: 0 10px;
        }

        .bold-para {
          color: #555555;
          font-weight: bold;
          text-align: center;
        }
      }

      .work-type-object-second {
        background-color: #fdfdfd;
        border: 1px solid #adadad;
        padding: 10px 0;
        margin-top: 20px;

        .normal-para {
          color: #555555;
          padding: 0 10px;
        }

        .bold-para {
          color: #555555;
          font-weight: bold;
          text-align: center;
        }
      }

      .work-type-object-third {
        background-color: #272b8d;
        padding: 10px 20px;
        border-radius: 5px;

        .normal-para {
          color: #fff;
          padding: 5px 10px;
        }

        .bold-para {
          color: #fff;
          font-weight: bold;
          text-align: center;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .transaction-summary-border {
    height: 850px !important;
  }
  .transaction-transport-img {
    display: none !important;
  }

  .work-type-object-third {
    .normal-para {
      padding: 5px 0 !important;
      font-size: 1rem !important;
    }

    .bold-para {
      font-size: 1.5rem !important;
    }
  }

  .work-type-object-first {
    .transport-para {
      margin-left: -30px !important;
    }
  }
}
