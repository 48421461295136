ul.breadcrumb_buttons {
  list-style-type: none;
}

.breadcrumb_buttons li {
  list-style-type: none;
  float: left;
  margin-right: 5px;
}

.breadcrumb_buttons button {
  position: relative;
  text-transform: capitalize !important;
  background-color: #444cf7 !important;
  color: #ffffff !important;
  min-width: 130px;
  font-size: 16px !important;
}

.breadcrumb_buttons button.button_active {
  color: #fff;
  background-color: #444cf7 !important;
}

.breadcrumb_buttons button.button_inactive {
  color: #a4a4a4 !important;
  background-color: #eaebf0 !important;
}

.breadcrumb_buttons button img {
  margin-right: 10px;
}

.breadcrumb_buttons button::after {
  content: "";
  position: absolute;
  border-left: 19px solid #444cf7;
  border-bottom: 20px solid transparent;
  border-top: 19px solid transparent;
  height: 0px;
  width: 0px;
  margin-right: -17px;
  right: 0;
  z-index: 2;
}

.breadcrumb_buttons button.button_inactive::after {
  border-left: 19px solid #ecedfe;
}

.breadcrumb_buttons button.step_button::before {
  content: "";
  position: absolute;
  border-left: 19px solid #fff;
  border-bottom: 20px solid transparent;
  border-top: 19px solid transparent;
  height: 0px;
  width: 0px;
  left: 0;
}

.breadcrumb_buttons button.generate_bills_icon {
  width: 150px;
}

.breadcrumb_buttons button.final_calculation_bills_icon {
  width: 200px;
}

@media (max-width: 575px) {
  .breadcrumb_buttons button {
    margin-bottom: 10px !important;
  }
}

@media (min-width: 576px) and (max-width: 650px) {
  .breadcrumb_buttons button {
    margin-bottom: 10px !important;
  }
}

@media (min-width: 650px) and (max-width: 699px) {
  .breadcrumb_buttons button {
    margin-bottom: 10px !important;
  }
}

@media (min-width: 700px) and (max-width: 750px) {
  .breadcrumb_buttons button {
    margin-bottom: 10px !important;
  }
}

@media (min-width: 750px) and (max-width: 800px) {
  .breadcrumb_buttons button {
    margin-bottom: 10px !important;
  }
}
