.edit-loading-padding {
    padding: 15px !important;
}

.grid-space {
    margin-bottom: 15px !important;
}

.width88 {width: 78% !important;}

.remark-input {
    border: none !important;
}

.grid-input-space {
    margin-top: 8px !important;
}

.vehicle-info-row {
    font-size: 16px;
    float: left;
    padding: 12px 0px;
    border-bottom: 1px solid #ccc;
}

.vehicle-info-row .label {
    font-weight: bold;
    color: #444CF7 !important;
    padding: 8px !important;
    margin-right: 10px !important;
}

.clear-both {
    clear: both;
}

.add-new-entry {
    color: #fff;
    text-decoration: none;
    width: 100%;
    font-weight: bold !important;
    font-size: 16px !important;
    height: 36px !important;
    background-color: #444CF7 !important;
}

.add-new-vehicle-dropdown {
    color: #fff !important;
    text-decoration: none;
    font-weight: bold !important;
    font-size: 14px !important;
    height: 36px !important;
    background-color: #444CF7 !important;
}

.agency-details-margin {
    padding: 0 15px !important;
}

.action-button {
    justify-content: end;
}

.submit-button {
    color: #fff;
    text-decoration: none;
    font-weight: bold !important;
    font-size: 16px !important;
    height: 36px !important;
    background-color: #444CF7 !important;
}

.form-input-box {
    width: 100%;
    color: black;
    border-radius: 5px !important;
    padding: 5px 10px;
    margin-bottom: 5px;
    height: 40px;
}

.form-select-box {
    color: black;
    border-radius: 5px !important;
    height: 40px;
}

.submit-button-area {
    margin-top: 15px;
    text-align: center;

    button {
        margin: 0 5px 0;
    }
}

.cancelled-loading-challan-no {
    color: red;
}


.disabled-field {
    background-color: #f0f0f0;
    color: #a9a9a9;
    cursor: not-allowed;
}

.disabled-input {
    color: #666 !important;

    span {
        color: #666 !important;
    }

    input {
        color: #666 !important;
      }
}

/** excel import errors modal css */
.error-import-modal-heading {
    font-size: 26px;
    text-transform: capitalize;
    padding-bottom: 10px;
    text-align: center;
}

.error-import-modal {
    height: auto;
    max-height: 70%;
}

.error-import-excel-display-data {
    height: auto;
    max-height: 500px;
    width: 100% !important;
    overflow-y: scroll;
}

.error-import-excel-display-data>table {
    border: 1px solid #ccc;
}

.error-import-excel-display-data>table th {
    background-color: #444CF7;
    color: #fff;
    padding: 10px;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
}

.error-import-excel-display-data>table td {
    border: 1px solid #ccc;
    color: red;
    font-weight: bold;
}

.error-import-excel-display-data>table td table td {
    border: 1px solid transparent;
}

.error-import-excel-display-data ul {
    list-style-type: none;
    margin: 0 25px 0;
}

.error-import-excel-display-data ul li {
    list-style-type: square;
    line-height: 30px;
    color: red;
}

.action-button {
    justify-content: end;
}

.input-field-challan-button-validate {
    display: flex;
    gap: 8px !important;
    position: absolute;
    top: 40px !important;
    right: 0 !important;

    .stop-missing-icon {
        background-color: #444CF7 !important;
        border: 1px solid #444CF7;
        padding: 3px 14px;
        border-radius: 5px;
        cursor: pointer;
        color: #fff !important;
        transition: 0.3s;
    }

    .stop-missing-icon:hover {
        opacity: 0.8;
    }
}

.grid-space {}

/* responsive css starts from here */
@media (max-width: 576px) {
    .card-box {
        width: 100% !important;
    }

    .add-new-entry {
        margin-top: 10px !important;
    }
}

@media (min-width: 900px) and (max-width: 1199px) {
    .card-box {
        width: 280px !important;
    }

    .add-new-entry {
        margin-top: 10px !important;
    }
}

@media (min-width: 1200px) and (max-width: 1450px) {
    .card-box {
        width: 280px !important;
    }

    .add-new-entry {
        margin-top: 10px !important;
    }
}

@media (min-width: 1451px) and (max-width: 1500px) {
    .card-box {
        width: 370px !important;
    }

    .add-new-entry {
        margin-top: 10px !important;
    }
}

@media (min-width: 1501px) and (max-width: 1576px) {
    .card-box {
        width: 400px !important;
    }

    .add-new-entry {
        margin-top: 10px !important;
    }
}

@media (min-width: 1577px) and (max-width: 1799px) {
    .card-box {
        width: 430px !important;
    }

    .add-new-entry {
        margin-top: 10px !important;
    }
}

@media (min-width: 1800px) {
    .card-box {
        width: 450px !important;
    }

    .add-new-entry {
        margin-top: 10px !important;
    }
}