.pending-petrol-pump-bill-padding {
  padding: 20px !important;
}

.filter-new-entry {
  border-color: #444cf4 !important;
  color: #444cf4 !important;
}

.search-button-area {
  text-align: right;

  button {
    margin: 0 5px 0;
  }

  .submit-button {
    background-color: #444cf4 !important;
  }
}

.transport-agency-bill-number-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 0 10px;

  .transport-agency-wrappper {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;

    .select-new-entry {
      width: 350px;
    }
  }

  .transport-agency-bill-number {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;

    .hr-transport-box {
      padding: 8px 15px;
      background-color: #d2d4fd;
      border-radius: 5px;
    }
  }
}

.middle-boundary-transport {
  background-color: #746446;
  display: flex;
  justify-content: space-between;
  margin: 0 10px !important;
  padding: 0px 15px;
  border-radius: 5px;

  .text-boundary-tranport {
    letter-spacing: 1px;
  }

  .total-amount-transport {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
  }
}

.petrol-pump-counter {
  padding: 25px 2px;
  text-align: center;
  border-radius: 5px;
  height: 120px;

  h5 {
    font-size: 1.8rem;
  }

  &.petrol-pump-first-counter {
    background-color: #e39506;
  }

  &.petrol-pump-second-counter {
    background-color: #f28705;
  }

  &.petrol-pump-third-counter {
    background-color: #bda523;
  }

  &.petrol-pump-fourth-counter {
    background-color: #7b6823;
  }

  &.petrol-pump-fivth-counter {
    background-color: #526126;
  }

  &.petrol-pump-sixth-counter {
    background-color: #2d5731;
  }

  &.petrol-pump-seventh-counter {
    background-color: #17514a;
  }
}

.pagination-box {
  padding: 20px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #eaebf0 !important;
}

.export-submit-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: 10px 0;

  .export-button {
    border-color: #444cf7 !important;
    color: #444cf7 !important;
  }

  .submit-button {
    background-color: #444cf7 !important;
  }
}

@media (max-width: 576px) {
  .search-new-entry {
    margin-top: 13px;
  }

  .select-new-entry {
    margin-top: -27px;
  }

  .transport-agency-wrappper {
    .select-new-entry {
      width: 120px;
    }
  }
}

@media (min-width: 576px) and (max-width: 750px) {
  .transport-agency-wrappper {
    .select-new-entry {
      width: 180px;
    }
  }
}

@media (max-width: 900px) {
  .xs-filter-position {
    text-align: left !important;
  }
}
